cockpitApp.directive('flightBodySummary', function () {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: '/element/flight/body/summary',
    controllerAs: 'flightBodySummaryCtrl',
    controller: 'flightBodySummaryController'
  };
});

