/**
 * Cockpit Application Module
 */
// region Application
var cockpitApp = angular.module(
  'cockpitApp',
  [
    'ngRoute',
    'ngResource',
    'ngCookies',
    'crumble',
    'ngStorage',
    'ngProgress',
    'ngMaterial',
    'vAccordion',
    'rzSlider',
    'gettext',
    'ngMessages',
    'angular-oauth2',
    'jkAngularRatingStars',
    'angular-jwt',
    'ngFileUpload',
    'angularLoadingDots',
    'angular.filter'
  ]
);
// endregion
