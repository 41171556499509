cockpitApp.filter('translateBaggageLabel', ['gettextCatalog', function(gettextCatalog) {
  return function (value) {
    switch (value) {
      case 'Additional Baggage':
        return gettextCatalog.getString('Additional Baggage');
      case 'Prepaid Baggage':
        return gettextCatalog.getString('Prepaid Baggage');
      default:
        return gettextCatalog.getString('Baggage');
    }
  }
}]);
