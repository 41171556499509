cockpitApp.filter('dateDisplay', [
  '$filter',
  function($filter) {
    return function(dateTimeOne, dateTimeTwo) {
      if (!moment.isMoment(dateTimeOne)) {
        dateTimeOne = dateTimeOne ? moment(dateTimeOne) : moment();
      }

      if (!moment.isMoment(dateTimeTwo)) {
        dateTimeTwo = dateTimeTwo ? moment(dateTimeTwo) : moment();
      }

      var dateDisplay = '';

      var isSameDay = $filter('isSameDate')(dateTimeOne, dateTimeTwo, 'day');
      var isSameMonth = $filter('isSameDate')(dateTimeOne, dateTimeTwo, 'month');
      var isSameYear = $filter('isSameDate')(dateTimeOne, dateTimeTwo, 'year');

      if (isSameDay) {
        dateDisplay = dateTimeTwo.format('LL');
      } else {
        var longDateFormat = moment.localeData().longDateFormat('LL');
        var longDateFormatArray = longDateFormat.split(' ');

        if (isSameMonth) {
          var dateRangeFormat = longDateFormatArray.map(function (date) {
            if (date.startsWith('D')) {
              date = dateTimeOne.format(date) + ' - ' + date;
            }

            return date;
          });

          dateDisplay = dateTimeTwo.format(dateRangeFormat.join(' '));
        } else if (isSameYear) {
          var formatWithoutYear = longDateFormatArray.filter(function (date) {
            return date !== 'YYYY';
          });

          formatWithoutYear = formatWithoutYear.join(' ');
          dateDisplay = dateTimeOne.format(formatWithoutYear) + ' - ' + dateTimeTwo.format(longDateFormat);
        } else {
          dateDisplay = dateTimeOne.format(longDateFormat) + ' - ' + dateTimeTwo.format(longDateFormat);
        }
      }

      return dateDisplay.toUpperCase();
    }
  }
]);
