cockpitApp.controller('frequentFlyerDialogController', [
  '$scope', '$rootScope', '$mdDialog', '$routeParams', '$localStorage', 'gettextCatalog', 'frequentFlyer', 'bookingProvider', 'functionalities',
  function ($scope, $rootScope, $mdDialog, $routeParams, $localStorage, gettextCatalog, frequentFlyer, bookingProvider, functionalities)
  {
    $scope.status = 'openingDialog';
    $scope.disableAirlineSelection = false;

    $scope.errorStatusList = [
      'agreementAirlinesFetchFailed',
      'frequentFlyerAddFailed',
      'frequentFlyerDeleteFailed',
      'bookingUpdateFailed',
      'functionalityDisabled'
    ];

    $scope.successStatusList = [
      'frequentFlyerNumberAdded',
      'frequentFlyerNumberDeleted'
    ];

    $scope.processingTextList = [
      'addingFrequentFlyerNumber',
      'deletingFrequentFlyerNumber',
      'openingDialog',
      'updatingBooking'
    ];

    var frequentFlyerDialogOpeningListener = $rootScope.$on('frequentFlyerDialogOpened', function(event, data) {
      functionalities.getFunctionalities(['frequentFlyerNumberEnabled']).then(
        function successCallback(response) {
          if (response.meta && response.meta.success && response.data) {
            if ($rootScope.functionalities[$rootScope.activeFilekey].frequentFlyerNumberEnabled) {
              $scope.getAirlines(data);
            } else {
              $scope.status = 'functionalityDisabled';
            }
          } else {
            $scope.status = 'functionalityDisabled';
          }
        },
        function errorCallback() {
          $scope.status = 'functionalityDisabled';
        }
      );
    });

    $scope.$on('$destroy', frequentFlyerDialogOpeningListener);

    $scope.getAirlines = function(data) {
      $scope.existingFFN = data.exisitingFFN;
      $scope.airlines = [];
      $scope.passenger = data.passenger;

      if (data.changeFrequentFlyer) {
        switch (data.changeFrequentFlyer) {
          case 'ADD':
            $scope.dialogTitle = gettextCatalog.getString('Add frequent flyer card');

            frequentFlyer.fetchAirlines().then(
              function successCallback (response) {
                if (response.meta && response.meta.success) {
                  $scope.airlines = response.data;
                  $scope.status = 'addFrequentFlyerNumber';
                } else {
                  $scope.status = 'agreementAirlinesFetchFailed';
                }
              }, function errorCallback (response) {
                $scope.status = 'agreementAirlinesFetchFailed';
              }
            );
            break;
          case 'DELETE':
            $scope.dialogTitle = gettextCatalog.getString('Delete frequent flyer card');

            $scope.selectedItem = data.exisitingFFN;
            $scope.number = data.exisitingFFN.number;

            $scope.status = 'deleteFrequentFlyerNumber';
            break;
        }
      }
    };

    $scope.hide = function() {
      $mdDialog.hide();
    };

    $scope.close = function() {
      $mdDialog.cancel();
    };

    $scope.clearValue = function() {
      $scope.name = undefined;
      $scope.airlines = undefined;
      $scope.FF.$setPristine();
    };

    $scope.disableDropDown = function () {
      return $scope.disableAirlineSelection;
    };

    $scope.modifyFrequentflyerProgram = function(changeFFN) {
      if ($scope.form.$valid || $scope.status === 'deleteFrequentFlyerNumber') {
        $scope.form.$setSubmitted();

        switch ($scope.status) {
          case 'addFrequentFlyerNumber':
            $scope.status = 'addingFrequentFlyerNumber';
            break;
          case 'deleteFrequentFlyerNumber':
            $scope.status = 'deletingFrequentFlyerNumber';

            $scope.selectedItem['name'] = $scope.selectedItem.airline;
            $scope.selectedItem['code'] = $scope.selectedItem.airlineCode;
            break;
        }

        var requestParams = {
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          airline: $scope.selectedItem,
          number: $scope.number,
          pnrPosition: $scope.passenger.pnrPosition,
          changeFFN: changeFFN,
          existingFFN: $scope.existingFFN == null ? null : $scope.existingFFN.number
        };

        frequentFlyer.modifyFrequentflyerProgram(requestParams).then(
          function successCallback (response) {
            if (response.meta && response.meta.success) {
              $scope.status = 'updatingBooking';
              $scope.updateBooking(changeFFN);
            } else {
              switch (changeFFN) {
                case 'ADD':
                  $scope.status = 'frequentFlyerAddFailed';
                  break;
                case 'DELETE':
                  $scope.status = 'frequentFlyerDeleteFailed';
                  break;
              }
            }
          }
        )
      }
    };

    $scope.updateBooking = function (changeFFN) {
      bookingProvider.updateBooking($rootScope.activeFilekey, $rootScope.activeLastname).then(
        function successCallback(responseMeta) {
          if (responseMeta && responseMeta.success) {
            switch(changeFFN) {
              case 'ADD':
                $scope.status = 'frequentFlyerNumberAdded';
                break;
              case 'DELETE':
                $scope.status = 'frequentFlyerNumberDeleted';
                break;
            }
          } else {
            $scope.status = 'bookingUpdateFailed';
          }
        },
        function errorCallback() {
          $scope.status = 'bookingUpdateFailed';
        }
      );
    };

    $scope.displayErrorMessages = function () {
      return $.inArray($scope.status, $scope.errorStatusList) !== -1;
    };

    $scope.displaySuccessMessages = function () {
      return $.inArray($scope.status, $scope.successStatusList) !== -1;
    };

    $scope.displayProcessingText = function () {
      return $.inArray($scope.status, $scope.processingTextList) !== -1;
    };
  }
]);
