cockpitApp.filter('countdownStatusBooking', [
  'gettextCatalog', '$filter',
  function(gettextCatalog, $filter) {
    return function(booking) {
      if (booking && booking.arrivalDateTime && booking.departureDateTime) {
        var departureDateTime = moment.tz(booking.departureDateTime, booking.departureTimezone);
        var arrivalDateTime = moment.tz(booking.arrivalDateTime, booking.arrivalTimezone);
        var browserDateTime = moment.tz(moment(), moment.tz.guess());

        // future, returns html tag in string, used as reference to color and resize the number of days in css
        if (browserDateTime.isBefore(departureDateTime)) {
          var daysDiffDeparture = $filter('daysDiffHumanize')(browserDateTime, departureDateTime);

          if (daysDiffDeparture === 0) {
            return gettextCatalog.getString('today');
          } else {
            return gettextCatalog.getPlural(daysDiffDeparture, 'tomorrow', 'in {{days}} days', {days: "<span>" + daysDiffDeparture + "</span>"});
          }
        }

        // between departure and arrival datetime
        if (browserDateTime.isBetween(departureDateTime, arrivalDateTime)) {
          return gettextCatalog.getString('active');
        }

        // past, returns html tag in string, used as reference to color and resize the number of days in css
        if (browserDateTime.isAfter(arrivalDateTime)) {
          var daysDiffArrival = $filter('daysDiffHumanize')(browserDateTime, arrivalDateTime);

          if (daysDiffArrival === 0) {
            return gettextCatalog.getString('today');
          } else {
            return gettextCatalog.getPlural(daysDiffArrival, 'yesterday', '{{days}} days ago', {days: "<span>" + daysDiffArrival + "</span>"});
          }

        }
      }
    };
  }
]);
