cockpitApp.filter('split', function() {
  return function(input, splitChar, index) {
    var airlineCode = input.split(splitChar)[index];
    if (typeof airlineCode != "undefined") {
      return airlineCode;
    } else {
      return input;
    }
  }
});
