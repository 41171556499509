cockpitApp.service('dataHelper', function() {
  var value = [];
  
  var setValue = function(data, name) {
    value[name] = data;
  };
  
  var getValue = function(name) {
    return value[name];
  };
  
  return {
    setValue: setValue,
    getValue: getValue
  };
});
