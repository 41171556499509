cockpitApp.controller('refreshBarController', [
  '$rootScope', '$scope', 'bookingHelper',
  function($rootScope, $scope, bookingHelper)
  {
    $scope.bookingHelper = bookingHelper;

    $scope.isPdfTravelPlanCreateEnabled = function() {
      return $rootScope.isFunctionalityEnabled('pdfTravelplanCreateEnabled') && $rootScope.booking && $rootScope.booking.travelAgencyNumber != null;
    };
  }
]);
