cockpitApp.factory('seatMapHelper', [
  '$rootScope', '$q', '$mdSidenav', '$filter', 'segmentHelper', 'bookingHelper',
  function ($rootScope, $q, $mdSidenav, $filter, segmentHelper, bookingHelper)
  {
    var factory = {};

    factory.openSeatMap = function(segment, seatMapSegments) {
      var self = this;

      $mdSidenav('seatMapMain').open();
      seatMapSegments = seatMapSegments ? seatMapSegments : self.getSeatMapSegments(segment['segmentExtendedNumber']);
      $rootScope.$emit('openSeatMap', seatMapSegments);
    };

    factory.isSeatMapAvailable = function(segment, functionality, ancillaryType) {
      return !$filter('isPast')(segment.departureDateTime, segment.departureTimezone) && segmentHelper.validAirlineCode(segment) && !bookingHelper.isBookingCancelled() && segmentHelper.isAircraft(segment.equipmentType)
        && !(!$rootScope.isFunctionalityEnabled(functionality, segment) && (segmentHelper.getNumberOfAncillaryPerSegment(segment, ancillaryType) === 0));
    };

    factory.openSeatMapContainer = function(segmentExtendedNumber) {
      var self = this;

      if (segmentExtendedNumber) {
        var seatMapSegments = this.getSeatMapSegments(segmentExtendedNumber);
        self.openSeatMap(seatMapSegments['currentSegment'], seatMapSegments);
      }
    };

    factory.getSeatMapSegments = function(segmentExtendedNumber) {
      // Builds the object used to loop through the available seatmaps, reordering the enabledSegments according to the first clicked
      var self = this;
      var seatMapSegments = {
        'errorSegments': [],
        'currentSegment': segmentHelper.getSegmentByExtenderNumber(parseInt(segmentExtendedNumber)),
        'enabledSegments': $rootScope.booking.segments.filter(function(segmentToFilter) {
          return self.isSeatMapAvailable(segmentToFilter, 'seatDisplayEnabled', 'seat') && !segmentHelper.isActionDisabled('seatDisplayEnabled', 'seat', segmentToFilter);
        })
      };
      seatMapSegments['remainingSegments'] = seatMapSegments['enabledSegments'].filter(function(segmentToFilter) {
        return segmentToFilter.id !== seatMapSegments['currentSegment'].id;
      });
      var indexOfCurrentEnabledSegment = seatMapSegments['enabledSegments'].indexOf(seatMapSegments['currentSegment']);
      seatMapSegments['enabledSegments'] = seatMapSegments['enabledSegments'].slice(indexOfCurrentEnabledSegment).concat(seatMapSegments['enabledSegments'].slice(0, indexOfCurrentEnabledSegment));

      return seatMapSegments;
    };

    return factory;
  }
]);
