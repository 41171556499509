cockpitApp.controller('passwordResetController', [
  '$rootScope', '$scope', '$routeParams', '$timeout', 'profile', 'gettextCatalog', 'componentHelper', 'logsProvider',
  function($rootScope, $scope, $routeParams, $timeout, profile, gettextCatalog, componentHelper, logsProvider)
  {
    $rootScope.appTitle = $rootScope.appName + ' - ' + gettextCatalog.getString('Reset password');

    $scope.token = $routeParams.token;
    $scope.decryptedEmail = $routeParams.email ? atob($routeParams.email) : null;

    $scope.errorStatus = [
      'passwordResetFailed',
      'passwordResetInvalidToken'
    ];

    $scope.successStatus = [
      'passwordResetSuccess'
    ];

    $timeout(function() {
      angular.element('form[name="passwordReset"] input[name="email"]').focus();
    }, 1000);

    $scope.resetPassword = function() {
      $scope.status = 'loading';

      if ($scope.passwordResetForm.$valid) {
        $scope.passwordResetForm.$setSubmitted();

        var requestParams = {
          email: $scope.passwordResetForm.email.$viewValue,
          password: $scope.passwordResetForm.password.$viewValue,
          password_confirmation: $scope.passwordResetForm.confirmPassword.$viewValue,
          token: $scope.passwordResetForm.token.$viewValue
        };

        profile.resetPassword(requestParams).then(
          function successCallback(response) {
            if (response !== undefined && response.status !== 500) {
              if (response.meta !== undefined && response.meta.success) {
                $scope.status = 'passwordResetSuccess';

                var logRequestParams =  {
                  interactionName: 'profilePasswordReset',
                  language: gettextCatalog.getCurrentLanguage(),
                  details: {
                    success: response.meta.success,
                    email: $scope.passwordResetForm.email.$viewValue
                  }
                };

                logsProvider.logInteraction(logRequestParams);
              } else {
                $scope.status = 'passwordResetFailed';
              }
            } else {
              if (response.status === 500 && response.data.exception_code === 713) {
                $scope.status = 'passwordResetInvalidToken';
              } else {
                $scope.status = 'passwordResetFailed';
              }
            }
          }, function errorCallback(response) {
            $scope.status = 'passwordResetFailed';
          }
        );
      }
    };

    $scope.openLoginDialog = function(event, email) {
      var dialogData = {
        data: email,
        onShowing: 'loginFromResetPassword'
      };

      componentHelper.openDialog('/element/dialog/profile/main', event, dialogData);
    };

    $scope.displayErrorMessages = function() {
      return $scope.errorStatus.indexOf($scope.status) > -1;
    };

    $scope.displaySuccessMessages = function() {
      return $scope.successStatus.indexOf($scope.status) > -1;
    };
  }
]);
