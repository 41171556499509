cockpitApp.provider('functionalities', [
  function() {
    this.apiFunctionalitiesEndpoint = null;

    this.setApiFunctionalitiesEndpoint = function(newApiFunctionalitiesEndpoint) {
      this.apiFunctionalitiesEndpoint = newApiFunctionalitiesEndpoint;
    };

    this.$get = ['$localStorage', '$rootScope', '$filter', 'cockpitClient', 'gettextCatalog', 'componentHelper', 'basketHelper',
      function($localStorage, $rootScope, $filter, cockpitClient, gettextCatalog, componentHelper, basketHelper) {
        var self = this;

        return {
          getFunctionalities: getFunctionalities,
          pnrNoTravelAgency: pnrNoTravelAgency
        };

        function getFunctionalities(functionalityList) {
          var requestParams = {
            filekey: $rootScope.activeFilekey,
            lastname: $rootScope.activeLastname,
            locale: $rootScope.activeLocale,
            functionalityList: functionalityList
          };

          var response = cockpitClient.remoteCall(self.apiFunctionalitiesEndpoint, requestParams, 'POST', null, null);
          var resolvedResponse = cockpitClient.resolveRequest(response);

          resolvedResponse.then(
            function successCallback(response) {
              if (response.meta && response.meta.success && response.data) {
                $rootScope.functionalities[$rootScope.activeFilekey] ? angular.merge($rootScope.functionalities[$rootScope.activeFilekey], response.data) : $rootScope.functionalities[$rootScope.activeFilekey] = response.data;
                setReasonsFunctionalities(response);

                if ($rootScope.travelAgency && $rootScope.travelAgency.data) {
                    setTravelAgencyFunctionalities(response);
                } else {
                  $rootScope.$on('travelAgencyFetched', function() {
                    setTravelAgencyFunctionalities(response);
                  });
                }

                $rootScope.$broadcast('functionalitiesFetched', functionalityList);
              }

              if ($rootScope.functionalities[$rootScope.activeFilekey] && $localStorage.cockpit.baskets[$rootScope.activeFilekey]) {
                if (isItemTypeInBasketDeprecated('seatReservationChargeableEnabled', 'seats')) {
                  basketHelper.removeSeatFromBasketByTypePerSegment('seatReservationChargeableEnabled');
                }

                if (isItemTypeInBasketDeprecated('seatReservationNonChargeableEnabled', 'seats')) {
                  basketHelper.removeSeatFromBasketByTypePerSegment('seatReservationNonChargeableEnabled');
                }

                if (isItemTypeInBasketDeprecated('extrasEnabled', 'extras')) {
                  basketHelper.removeBasket('extras', true);
                }

                if (isItemTypeInBasketDeprecated('cashUpgradeEnabled', 'cashUpgrade')) {
                  basketHelper.removeBasket('cashUpgrade', true);
                }

                if (isItemTypeInBasketDeprecated('baggageEnabled', 'baggage')) {
                  basketHelper.removeBasket('baggage', true);
                }

                if (isItemTypeInBasketDeprecated('automaticCheckinEnabled', 'automaticCheckins')) {
                  basketHelper.removeBasket('automaticCheckins', true);
                }

                if (isItemTypeInBasketDeprecated('insuranceBlueribbonbagsEnabled', 'insuranceBaggage')) {
                  basketHelper.removeBasket('insuranceBaggage', true);
                }

                if (isItemTypeInBasketDeprecated('transferEnabled', 'transfers')) {
                  basketHelper.removeBasket('transfers', true);
                }
              }

              componentHelper.slideUpSplashScreen(500);
            }
          );

          return resolvedResponse;
        }

        function pnrNoTravelAgency(functionality) {
          functionality = functionality || 'shoppingEnabled';

          return functionality !== 'seatDisplayEnabled' && (
            $rootScope.functionalities &&
            $rootScope.activeFilekey &&
            $rootScope.functionalities[$rootScope.activeFilekey] &&
            !$rootScope.functionalities[$rootScope.activeFilekey]['shoppingEnabled'] &&
            $rootScope.functionalities[$rootScope.activeFilekey]['reasons'] &&
            $rootScope.functionalities[$rootScope.activeFilekey]['reasons']['shoppingEnabled'] === 'pnr_no_travel_agency_number'
          );
        }

        function isItemTypeInBasketDeprecated(functionality, itemType) {
          return !$rootScope.isFunctionalityEnabled(functionality) && $localStorage.cockpit.baskets[$rootScope.activeFilekey] && $localStorage.cockpit.baskets[$rootScope.activeFilekey][itemType];
        }

        function setTravelAgencyFunctionalities(response) {
          $rootScope.travelAgency.data.functionalities = $rootScope.travelAgency.data.functionalities || {};
          $rootScope.travelAgency.data.functionalities.profileEnabled = response.data.profileEnabled !== undefined ? response.data.profileEnabled : $rootScope.travelAgency.data.functionalities.profileEnabled;
          $rootScope.travelAgency.data.functionalities.useDataFromKim = response.data.useDataFromKim !== undefined ? response.data.useDataFromKim : $rootScope.travelAgency.data.functionalities.useDataFromKim;
        }

        function setReasonsFunctionalities(response) {
          if ($rootScope.functionalities[$rootScope.activeFilekey].reasons) {
            angular.forEach($rootScope.functionalities[$rootScope.activeFilekey].reasons, function(functionalityReasonValue, functionalityReasonName) {
              if (response.data.reasons && response.data.reasons[functionalityReasonName]) {
                $rootScope.functionalities[$rootScope.activeFilekey].reasons[functionalityReasonName] = response.data.reasons[functionalityReasonName];
              } else {
                // Important to remove if user opens switches between seat-maps
                delete $rootScope.functionalities[$rootScope.activeFilekey].reasons[functionalityReasonName];
              }
            });
          }
        }
      }
    ];
  }
]);
