cockpitApp.controller('cancelBookingDialogController', [
  '$rootScope', '$scope', '$mdDialog', '$timeout', '$location', '$localStorage', '$routeParams', 'bookingProvider', 'navigationHelper',
  function($rootScope, $scope, $mdDialog, $timeout, $location, $localStorage, $routeParams, bookingProvider, navigationHelper)
  {
    $scope.status = 'dialogOpen';

    $scope.cancelBooking = function() {
      $scope.status = 'processing';

      bookingProvider.cancelBooking($scope.booking.filekey, $scope.booking.searchName).then(
        function successCallback(response) {
          if (response.meta && response.meta.success) {
            bookingProvider.removeBooking($rootScope.activeFilekey, $rootScope.activeLastname);
            $scope.status = 'success';
          } else {
            $scope.status = 'error';
          }
        },
        function errorCallback() {
          $scope.status = 'error';
        }
      );
    };

    $scope.closeDialog = function() {
      $mdDialog.cancel();
    };

    $scope.redirectPage = function() {
      if ($scope.status === 'success') {
        navigationHelper.redirectWithLocale('/overview/active');
      } else {
        $mdDialog.cancel();
      }
    };
  }
]);
