cockpitApp.factory('sessionHelper', [
  function ()
  {
    var sessionService = {};
  
    sessionService.setSession = function (sessionId) {
      sessionStorage.setItem('session_cockpit', JSON.stringify({
        browserSessionStartedDateTime: moment().valueOf(),
        browserSessionId: sessionId
      }));
    };
  
    sessionService.getSession = function () {
      return JSON.parse(sessionStorage.getItem('session_cockpit'));
    };
  
    sessionService.getBrowserSessionId = function () {
      return this.getSession() && this.getSession().browserSessionId;
    };
  
    sessionService.getBrowserSessionStartedDateTime = function () {
      return this.getSession() && this.getSession().browserSessionStartedDateTime;
    };
  
    return sessionService;
  }
]);
