cockpitApp.controller('confirmCheckoutDialogController', [
  '$rootScope', '$scope', '$mdDialog', '$filter', 'navigationHelper', 'functionalities', 'basketHelper', 'dataHelper',
  function($rootScope, $scope, $mdDialog, $filter, navigationHelper, functionalities, basketHelper, dataHelper)
  {
    var basketBeforeRemoval = angular.copy($rootScope.basket);

    $scope.closeDialog = function() {
      $mdDialog.cancel();
    };

    $scope.goToCheckout = function() {
      $mdDialog.cancel();
      navigationHelper.redirectWithLocale('/checkout/:' + $rootScope.activeFilekey + '/:' + $rootScope.activeLastname);
    };

    $scope.getFunctionalities = function() {
      $rootScope.gaTracking('Checkout', 'click', dataHelper.getValue('gaToCheckout') + 'Try');

      $scope.status = 'loading';

      var requestParams = basketHelper.getFunctionalitiesToRequestFromBasket();

      functionalities.getFunctionalities(requestParams).then(
        function successCallback(response) {
          if (response.meta && response.meta.success && response.data) {
            var displaySeatWarning = $scope.displaySeatWarning();
            var displayExtrasWarning = $scope.displayExtrasWarning('extras');
            var displayCashUpgradeWarning = $scope.displayExtrasWarning('cashUpgrade');
            var displayBaggageWarning = $scope.displayBaggageWarning(response.data);
            var displayAutomaticCheckinWarning = $scope.displayAutomaticCheckinWarning(response.data);
            var displayInsuranceBaggageWarning = $scope.displayInsuranceBaggageWarning(response.data);
            var displayTransferWarning = $scope.displayTransferWarning(response.data);

            if ($filter('isEmpty')($rootScope.basket) ||
              (displaySeatWarning && displayExtrasWarning && displayCashUpgradeWarning && displayBaggageWarning && displayAutomaticCheckinWarning && displayInsuranceBaggageWarning && displayTransferWarning)) {
              $scope.status = 'error';
            } else if (displaySeatWarning) {
              $scope.status = 'seatWarning';
            } else if (displayBaggageWarning) {
              $scope.status = 'baggageWarning';
            } else if (displayExtrasWarning) {
              $scope.status = 'extrasWarning';
            } else if (displayCashUpgradeWarning) {
              $scope.status = 'cashUpgradeWarning';
            } else if (displayAutomaticCheckinWarning) {
              $scope.status = 'automaticCheckinWarning';
            } else if (displayInsuranceBaggageWarning) {
              $scope.status = 'insuranceBaggageWarning';
            } else if (displayTransferWarning) {
              $scope.status = 'transferWarning';
            } else {
              $scope.goToCheckout();
            }
          } else {
            $scope.status = 'error';
          }
        },
        function errorCallback() {
          $scope.status = 'error';
        }
      );
    };

    $scope.displaySeatWarning = function() {
      return basketHelper.hasBasketSeatsByTypePerSegment(basketBeforeRemoval, 'seatReservationChargeableEnabled') ||
        basketHelper.hasBasketSeatsByTypePerSegment(basketBeforeRemoval, 'seatReservationNonChargeableEnabled')
    };

    $scope.displayExtrasWarning = function(serviceType) {
      return basketHelper.hasBasketExtrasByServiceTypePerSegment(basketBeforeRemoval, serviceType + 'Enabled', serviceType);
    };

    $scope.displayBaggageWarning = function(shoppingFunctionalities) {
      return basketBeforeRemoval.baggage && !shoppingFunctionalities.baggageEnabled;
    };

    $scope.displayAutomaticCheckinWarning = function(shoppingFunctionalities) {
      return basketBeforeRemoval.automaticCheckins && !shoppingFunctionalities.automaticCheckinEnabled;
    };

    $scope.displayInsuranceBaggageWarning = function(shoppingFunctionalities) {
      return basketBeforeRemoval.insuranceBaggage && !shoppingFunctionalities.insuranceBlueribbonbagsEnabled;
    };

    $scope.displayTransferWarning = function(shoppingFunctionalities) {
      return basketBeforeRemoval.transfers && !shoppingFunctionalities.transferEnabled;
    };
  }
]);
