cockpitApp.controller('insuranceController', [
  '$rootScope', '$scope', 'logsProvider', '$window', '$sce',
  function($rootScope, $scope, logsProvider, $window, $sce)
  {
    $scope.openLink = function() {
      var logRequestParams = {
        interactionName: 'setooInsuranceLinkout',
        filekey: $rootScope.activeFilekey,
        lastname: $rootScope.activeLastname,
        language: $rootScope.activeLocale,
        details: {
          link: $rootScope.setoo[$rootScope.activeFilekey].details.url
        }
      };

      logsProvider.logInteraction(logRequestParams);

      $window.open($rootScope.setoo[$rootScope.activeFilekey].details.url, '_blank');
    };

    $scope.setSetooHtml = function() {
      $scope.setooHtml = $sce.trustAsHtml($rootScope.setoo[$rootScope.activeFilekey].details.proposal.iframeHtml);
    }
  }
]);
