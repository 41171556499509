cockpitApp.filter('currencyFormatter', [
  '$rootScope',
  function ($rootScope) {
    return function (number, omitDecimals) {
      number = parseFloat(number.toString().replace(',', ''));
      omitDecimals = omitDecimals || false;

      if ($rootScope.booking && $rootScope.booking.currencyCode) {
        var locale = $rootScope.activeLocale.replace('_', '-');

        var formatterOptions = {
          style: 'currency',
          currency: $rootScope.booking.currencyCode,
        };

        if (omitDecimals) {
          formatterOptions.minimumFractionDigits = 0;
          formatterOptions.maximumFractionDigits = 0;
        }

        return new Intl.NumberFormat(locale, formatterOptions).format(number);
      }

      return number;
    };
  },
]);
