cockpitApp.directive('locationName', function () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      'isDepartureSegment': '=',
      'segment': '=',
      'transferPosition': '=',
      'isDot': '='
    },
    templateUrl: '/element/detail/journey/location-name',
    controller: 'locationNameController',
    controllerAs: 'locationNameCtrl'
  };
});
