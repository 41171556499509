cockpitApp.provider('logsProvider', [
  function () {
    this.$get = ['cockpitClient',
      function (cockpitClient) {
        return {
          logInteraction: logInteraction,
          logPciIframeError: logPciIframeError
        };

        function logInteraction(requestParameters) {
          return cockpitClient.logInteraction(requestParameters);
        }

        function logPciIframeError(requestParameters) {
          return cockpitClient.logPciIframeError(requestParameters);
        }
      }
    ];
  }
]);
