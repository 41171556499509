cockpitApp.controller('confirmationController', [
  '$rootScope', '$scope', '$localStorage', 'gettextCatalog', 'bookingHelper', 'bookingProvider',
  function($rootScope, $scope, $localStorage, gettextCatalog, bookingHelper, bookingProvider)
  {
    $rootScope.appTitle = $rootScope.appName + ' - ' + gettextCatalog.getString('Order confirmation');

    $scope.order = $localStorage.cockpit.baskets[$scope.activeFilekey];
    $scope.passengerList = bookingHelper.getPassengersToDisplay();
    $localStorage.cockpit.baskets[$scope.activeFilekey] = undefined;
    $rootScope.basket = undefined;

    bookingProvider.updateBooking($rootScope.activeFilekey, $rootScope.activeLastname);
  }
]);
