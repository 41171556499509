cockpitApp.filter('countdownStatusSegment', [
  'gettextCatalog', '$filter', 'segmentHelper',
  function(gettextCatalog, $filter, segmentHelper) {
    return function(segment) {
      if (segment && segment.arrivalDateTime && segment.departureDateTime) {
        var departureDateTime = moment.tz(segment.departureDateTime, segment.departureTimezone);
        var arrivalDateTime = moment.tz(segment.arrivalDateTime, segment.arrivalTimezone);
        var browserDateTime = moment.tz(moment(), moment.tz.guess());

        // future
        if (browserDateTime.isBefore(departureDateTime)) {
          if ($filter('isSameDate')(departureDateTime, null, 'day')) {
            return gettextCatalog.getString('Today');
          } else {
            return gettextCatalog.getString('Confirmed');
          }
        }

        // between departure and arrival datetime
        if (browserDateTime.isBetween(departureDateTime, arrivalDateTime)) {
          return gettextCatalog.getString('Active');
        }

        // past
        if (browserDateTime.isAfter(arrivalDateTime)) {
          if (segment.flown) {
            if (segmentHelper.isAircraft(segment.equipmentType)) {
              return gettextCatalog.getString('Flown', null, 'air');
            } else {
              return gettextCatalog.getString('Flown', null, 'ground');
            }
          }

          var daysDiffArrival = $filter('daysDiffHumanize')(browserDateTime, arrivalDateTime);

          if (daysDiffArrival === 0) {
            return gettextCatalog.getString('Past');
          } else {
            return gettextCatalog.getPlural(daysDiffArrival, 'Yesterday', '{{days}} days ago', {days: daysDiffArrival});
          }
        }
      }
    };
  }
]);
