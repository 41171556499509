cockpitApp.provider('travelAgency', [
  function () {
    this.travelAgency = null;

    this.$get = ['$http', '$q', '$localStorage', '$rootScope', 'cockpitClient', '$window',
      function ($http, $q, $localStorage, $rootScope, cockpitClient, $window) {
        var self = this;

        return {
          getTravelAgency: getTravelAgency
        };

        function getTravelAgency() {
          if (!$rootScope.travelAgency.data) {
            var travelAgencyResponse = cockpitClient.getTravelAgency();

            travelAgencyResponse.then(
              function successCallback(response) {
                if (response.meta && response.meta.success && response.data) {
                  var travelAgency = parseTravelAgency(response);

                  if (travelAgency.isActive) {
                    self.travelAgency = travelAgency;

                    $rootScope.travelAgency.data = travelAgency.serialize().data.attributes;
                    $rootScope.$broadcast('travelAgencyFetched', $rootScope.travelAgency.data);
                  } else {
                    $window.location.href = $window.location.href.replace(travelAgency.subDomain + '.', '');
                  }
                }
              },
              function errorCallback(response) {
                console.error("Cannot load travel agency: " + response);
              }
            );
          }
        }

        function parseTravelAgency(responseData) {
          var travelAgencyResponse = new JsonApiDataStore();

          travelAgencyResponse.sync(responseData);

          var travelAgency = travelAgencyResponse.findAll("travelAgency");

          return travelAgency[0];
        }
      }
    ];
  }
]);
