cockpitApp.controller('flightOverviewTabsController', [
  '$rootScope', '$scope', '$location', '$filter', '$localStorage', '$timeout', 'navigationHelper',
  function($rootScope, $scope, $location, $filter, $localStorage, $timeout, navigationHelper)
  {
    $scope.setTab = function(tab) {
      switch (tab) {
        case 'active':
          $scope.selectedIndex = 0;
          break;
        case 'past':
          $scope.selectedIndex = $filter('count')($filter('getBookings')(null, 'active')) > 0 ? 1 : 0;
          break;
        default:
          $scope.selectedIndex = 2;
          $timeout(function() {
            angular.element('#md-tabs md-tab-item').last().click();
          }, 50);
      }
    };

    $scope.$on('$routeChangeSuccess', function() {
      $scope.setTab($rootScope.tab);
      $scope.updateInkBarDisplay();
    });

    var travelAgencyFetchedListener = $scope.$on('travelAgencyFetched', function() {
      $scope.redirectIfRequired();
      $scope.bookings = $filter('getBookings')(null, $rootScope.tab);
      $scope.travelAgency = $rootScope.travelAgency.data;
    });

    var loginSuccessListener = $scope.$on('loginSuccess', function() {
      $scope.setTab($rootScope.tab);
      $scope.updateInkBarDisplay();
      $scope.bookings = $filter('getBookings')(null, $rootScope.tab);
    });

    var bookingDeletedListener = $scope.$on('bookingDeleted', function() {
      $scope.bookings = $filter('getBookings')(null, $rootScope.tab);
    });

    $scope.$on('$destroy', travelAgencyFetchedListener, loginSuccessListener, bookingDeletedListener);

    $scope.showTab = function(tab) {
      $rootScope.tab = tab;
      $scope.bookings = $filter('getBookings')(null, tab);
      $scope.updateInkBarDisplay();

      switch (tab) {
        case 'active':
          navigationHelper.redirectWithLocale('/overview/active');
          break;
        case 'past':
          navigationHelper.redirectWithLocale('/overview/past');
          break;
        default:
          navigationHelper.redirectWithLocale('/');
      }
    };

    $scope.updateInkBarDisplay = function() {
      var tabs = ['active', 'past'];
      var displayBar = tabs.indexOf($rootScope.tab) > -1;

      if (displayBar) {
        angular.element('#menu-root').find('#md-tabs md-ink-bar').css('display', 'block');
      } else {
        angular.element('#menu-root').find('#md-tabs md-ink-bar').css('display', 'none');
      }
    };

    $scope.isTab = function(tab) {
      return $rootScope.tab === tab;
    };

    $scope.displayPastTab = function() {
      return $filter('count')($filter('getBookings')(null, 'past')) > 0;
    };

    $scope.displayActiveTab = function() {
      return $filter('count')($filter('getBookings')(null, 'active')) > 0 || $rootScope.myCockpitIframe;
    };

    $scope.redirectIfRequired = function() {
      if ($location.path().indexOf('overview') > -1) {
        navigationHelper.bookingOverviewPreCheck($rootScope.tab);
      }
    };

    $scope.displayTabs = function() {
      return !$scope.isPage('details');
    };

    $scope.displayInviaIframeMessage = function() {
      return $rootScope.myCockpitIframe && $filter('count')($filter('getBookings')(null, 'active')) === 0
    };
  }
]);
