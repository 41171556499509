cockpitApp.factory('navigationHelper', [
  '$rootScope', '$location', '$localStorage', '$filter', '$route', '$anchorScroll', 'gettextCatalog', 'componentHelper', 'profileHelper', 'authManager', 'bookingHelper', 'logsProvider', 'localeHelper', '$window',
  function($rootScope, $location, $localStorage, $filter, $route, $anchorScroll, gettextCatalog, componentHelper, profileHelper, authManager, bookingHelper, logsProvider, localeHelper, $window)
  {
    var factory = {};

    factory.bookingOverviewPreCheck = function(tab, tabFallback) {
      tabFallback = tabFallback ? tabFallback : tab === 'active' ? 'past' : 'active';

      if ($filter('count')($filter('getBookings')(null, tab)) > 0 || $rootScope.myCockpitIframe) {
        $rootScope.tab = tab;
        this.redirectWithLocale('/overview/' + tab);
      } else if ($filter('count')($filter('getBookings')(null, tabFallback)) > 0) {
        $rootScope.tab = tabFallback;
        this.redirectWithLocale('/overview/' + tabFallback);
      } else {
        this.redirectWithLocale('/');
        componentHelper.showNotification('error', gettextCatalog.getString('No bookings available.'));
      }
    };

    factory.show = function(menu) {
      var currentPage = $location.path();
      var currentMenu = 'booking';

      if (currentPage.indexOf('details') > -1) {
        currentMenu = 'details';
        $rootScope.tab = 'details';
      } else if ($rootScope.isWelcomePage()) {
        currentMenu = 'booking';
        $rootScope.tab = 'welcome';
      } else if (currentPage.indexOf('overview/past') > -1) {
        currentMenu = 'booking';
        $rootScope.tab = 'past';
      } else if (currentPage.indexOf('overview/active') > -1) {
        currentMenu = 'booking';
        $rootScope.tab = 'active';
      } else if (currentPage.indexOf('register') > -1) {
        currentMenu = 'register';
        $rootScope.tab = 'register';
      } else {
        $rootScope.tab = 'none';
      }

      return menu === currentMenu;
    };

    factory.profileLoggedInPostAction = function(profileData, platform) {
      if (profileData) {
        // On login, remove all bookings from default localStorage and move them to profile sessionStorage
        bookingHelper.setBookingsNames(profileData.profileBookings);
        var activeBooking = $rootScope.activeFilekey && $rootScope.profile && $rootScope.profile.profileBookings && $rootScope.profile.profileBookings[$rootScope.activeFilekey];

        if (activeBooking && !$localStorage.cockpit.bookings[$rootScope.activeFilekey]) {
          $localStorage.cockpit.bookings[$rootScope.activeFilekey] = activeBooking;
        }

        profileData.profileBookings = angular.merge(profileData.profileBookings, $localStorage.cockpit.bookings);
        $rootScope.profile = profileData;
        profileHelper.updateSessionProfile();
        $localStorage.cockpit.bookings = {};

        componentHelper.showNotification('success', gettextCatalog.getString("You are logged in."));
        authManager.authenticate();
        this.redirectIfRequired();
        $rootScope.$broadcast('loginSuccess');

        platform = platform || '';

        var logRequestParams = {
          interactionName: 'profileLogin' + $filter('capitalize')(platform),
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          language: gettextCatalog.getCurrentLanguage()
        };

        logsProvider.logInteraction(logRequestParams);
      }
    };

    factory.profileLoggedOutPostAction = function(redirect) {
      var dialogData = {
        data: $rootScope.profile && $rootScope.profile.data && $rootScope.profile.data.email,
        onShowing: 'profileLoggedOut'
      };

      if (redirect) {
        this.bookingOverviewPreCheck('active', 'past');
      }

      componentHelper.openDialog('/element/dialog/profile/main', event, dialogData);
    };

    factory.redirectIfNotLoggedIn = function() {
      $rootScope.$on('travelAgencyFetched', function() {
        if (!profileHelper.isLoggedIn()) {
          $rootScope.$emit('unauthenticated');
        }
      });
    };

    factory.redirectIfRequired = function() {
      if ($location.url().indexOf('logout') !== -1 || $location.url().indexOf('password/reset') !== -1 || $location.url().indexOf('profile/verify') !== -1) {
        this.bookingOverviewPreCheck('active', 'past');
      }
    };

    factory.redirectIfNotSupportedFeature = function() {
      if (!$rootScope.supportedFeatures.includes($route.current.params.ancillary)) {
        this.redirectWithLocale('/');
      }
    };

    factory.redirectWithLocale = function(path) {
      path = path || $location.url();
      var currentLanguage = gettextCatalog.getCurrentLanguage();
      var pathAsArray = path.split('/');

      if (pathAsArray.length > 0) {
        if (localeHelper.getLanguageFromUrl(pathAsArray[1])) {
          pathAsArray[1] = currentLanguage.split('_')[1].toLowerCase();
        } else {
          pathAsArray.splice(1, 0, currentLanguage.split('_')[1].toLowerCase());
        }

        $location.url(pathAsArray.join('/'));
      }
    };

    factory.isBrowserUnsupported = function() {
      return window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    };

    factory.urlEncode = function(link) {
      return window.btoa(link).replace('/', '_');
    };

    factory.urlDecode = function(link) {
      return window.atob(link.replace('_', '/'));
    };

    factory.prepareLinkout = function(link) {
      $window.open($window.location.origin + '?referer=' + this.urlEncode(link), '_blank');
    };

    factory.linkout = function(link) {
      $window.location.href = this.urlDecode(link);
    };

    factory.normalizeLastname = function(searchName) {
      return $filter('sanitizeRouteParam')(searchName).replace(/[^A-Z]/g, '');
    };

    factory.normalizeFilekey = function(filekey) {
      return $filter('sanitizeRouteParam')(filekey);
    };

    factory.openBoardingPass = function(link, interactionName) {
      var logRequestParams = {
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          locale: $rootScope.activeLocale
      };

      logRequestParams.interactionName = interactionName;
      logRequestParams.details = {link: link};

      $window.open(link, '_blank');
      logsProvider.logInteraction(logRequestParams);
    };

    factory.preExpandAccordionSection = function(accordion, location) {
      if (accordion) {
        if (location) {
          $anchorScroll(location);
          accordion.toggle(location);
        } else {
          accordion.toggle(0)
        }
      }
    };

    factory.redirectToSeatmap = function(segment) {
      if (segment) {
        var segmentRoute = '/:' + segment.departureAirportCode + '-' + segment.arrivalAirportCode;
        this.redirectWithLocale('/details/:' + $rootScope.activeFilekey + '/:' + $rootScope.activeLastname + '/seatmap' + segmentRoute);
      }
    };

    return factory;
  }
]);
