cockpitApp.controller('sidenavAutomaticCheckinController', [
  '$rootScope', '$scope', '$mdSidenav', '$filter', '$localStorage', 'automaticCheckin', 'logsProvider', '$mdMedia', 'countries', 'markups', 'gettextCatalog', 'basketHelper', 'profileHelper', 'segmentHelper',
  function($rootScope, $scope, $mdSidenav, $filter, $localStorage, automaticCheckin, logsProvider, $mdMedia, countries, markups, gettextCatalog, basketHelper, profileHelper, segmentHelper)
  {
    $scope.keyFeatures = {
      benefits: [
        gettextCatalog.getString('Receive your boarding passes right in time by email before departure'),
        gettextCatalog.getString('Save time and avoid long queues at the airport'),
        gettextCatalog.getString('Convenient, practical and uncomplicated')
      ]
    };

    $scope.marketingFlag = true;

    $scope.resetCheckinSidenav = function() {
      $scope.segment = null;
      $scope.status = 'error';
      $scope.selectedPassengerPnrPosition = null;
      $scope.isSeatPreferenceOpen = false;
      $scope.isPassengerDetailsOpen = false;
      $scope.isDocumentExpirationDateValid = {};
      $scope.passengersFrequentFlyerNumber = [];
      $scope.checkinPassengerList = [];
      $scope.checkinData = {};
      $scope.futureFlightSegments = segmentHelper.getFutureFlightSegments();
      $scope.setSegmentSeats();
      $scope.requirements = {};
      $scope.dates = {};
      $scope.email = {
        value: ''
      };

      $scope.documentTypes = [{
        name: gettextCatalog.getString('Passport'),
        value: 'passport',
        code: 'P'
      }, {
        name: gettextCatalog.getString('National Identification'),
        value: 'nationalId',
        code: 'I'
      }];

      $scope.genders = [{
        name: gettextCatalog.getString('Male'),
        value: 'M'
      }, {
        name: gettextCatalog.getString('Female'),
        value: 'F'
      }];

      $scope.passportTypes = [{
        name: gettextCatalog.getString('Biometric'),
        value: 'biometric'
      }, {
        name: gettextCatalog.getString('Machine readable'),
        value: 'machine-readable'
      }, {
        name: gettextCatalog.getString('Other'),
        value: 'other'
      }];

      $scope.seatPositions = [{
        label: gettextCatalog.getString('Window'),
        value: 'window'
      }, {
        label: gettextCatalog.getString('Middle'),
        value: 'middle'
      }, {
        label: gettextCatalog.getString('Aisle'),
        value: 'aisle'
      }];

      $scope.planeSections = [{
        label: gettextCatalog.getString('Front'),
        value: 'front'
      }, {
        label: gettextCatalog.getString('Middle'),
        value: 'middle'
      }, {
        label: gettextCatalog.getString('Rear'),
        value: 'back'
      }];

      $scope.resetSeatPreferences();
    };

    $scope.setSegmentSeats = function() {
      $scope.segmentsWithSeats = [];
      $scope.segmentWithoutSeats = [];

      $scope.futureFlightSegments && $scope.futureFlightSegments.map(function(futureFlightSegment) {
        var segmentHasSeats = futureFlightSegment.passengers.some(function(passenger) {
          return passenger.seatRequest;
        });

        if (segmentHasSeats) {
          $scope.segmentsWithSeats.push(futureFlightSegment);
        } else {
          $scope.segmentWithoutSeats.push(futureFlightSegment);
        }
      });
    };

    $scope.resetSeatPreferences = function() {
      var seatPreferences = null;
      $scope.displaySeatSelection = false;

      if ($localStorage.cockpit.baskets[$rootScope.activeFilekey] && $localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins && $localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins.preferences) {
        seatPreferences = $localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins.preferences;
        $scope.displaySeatSelection = true;
      }

      $scope.seatPreferences = {
        seating: seatPreferences ? angular.copy(seatPreferences.seating) : null,
        locationInPlane: seatPreferences ? angular.copy(seatPreferences.locationInPlane) : null
      };
    };

    $scope.$watch(function() {
      return $mdSidenav('sidenavAutomaticCheckin').isOpen();
    }, function(newValue) {
      $rootScope.isSideNavOpen = newValue;

      if (!$rootScope.isSideNavOpen) {
        $scope.resetCheckinSidenav();
      }
    });

    $scope.closeAciUpsellMarketing = function () {
      $scope.marketingFlag = false;
      $localStorage.cockpit.marketing = { aci : false };
    };

    $scope.loadLocalStorageMarketingFlag = function () {
      if ($localStorage.cockpit.marketing && 'aci' in $localStorage.cockpit.marketing) {
        $scope.marketingFlag =  $localStorage.cockpit.marketing.aci;
      } else {
        $localStorage.cockpit.marketing = { aci : true };
      }
    };

    const sidenavAutomaticCheckinListener = $rootScope.$on('sidenavAutomaticCheckin:open', function (event, segment) {
      $scope.loadLocalStorageMarketingFlag();

      $scope.resetCheckinSidenav();
      $scope.segment = segment;

      $scope.status = 'loading';

      try {
        $scope.setProfileData();
        $scope.getACIMarkup();

        var logRequestParams = {
          interactionName: 'upsell-automatic-checkin-2-opened',
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          language: $rootScope.activeLocale
        };

        logsProvider.logInteraction(logRequestParams);

        angular.forEach($rootScope.booking.passengers, function (passenger) {
          if (passenger.passengerTypeCode !== 'INF') {
            $scope.passengersFrequentFlyerNumber.push({
              pnrPosition: passenger.pnrPosition,
              name: null,
              membership_number: null
            });
          }
        });

        $scope.passengersWithoutInfant = $scope.getPassengersWithoutInfant();
        $scope.getInitPassenger();
        $scope.status = 'success';
      } catch (e) {
        $scope.status = 'error';
      }
    });

    $scope.$on('$destroy', sidenavAutomaticCheckinListener);

    $scope.getACIMarkup = function() {
      markups.getMarkups(['automaticCheckin']).then(
        function successCallback(response) {
          if (response.meta && response.meta.success && response.data) {
            $scope.markupACI = response.data.automaticCheckin;
            $scope.setCheckinData();
          } else {
            throw 'Automatic checkin markups error.';
          }
        },
        function errorCallback() {
          throw 'Automatic checkin markups error.';
        }
      );
    };

    $scope.getInitPassenger = function() {
      angular.forEach($rootScope.booking.passengers, function(passenger) {
        if (passenger.passengerTypeCode !== 'INF') {
          $scope.checkinPassengerList.push({
            pnrPosition: passenger.pnrPosition,
            selected: false,
            passengerTypeCode: passenger.passengerTypeCode
          });
        }
      });
    };

    $scope.preFillForms = function() {
      if (profileHelper.isLoggedIn()) {
        var profileData = $scope.profile && $scope.profile.data;
      }

      $scope.resetDates();

      var passengerData = $filter('filter')($rootScope.booking.passengers, {'pnrPosition': $scope.selectedPassengerPnrPosition})[0];

      // pre-fill from booking if not present in basket
      $scope.checkinData[$scope.selectedPassengerPnrPosition]['gender'] = $scope.checkinData[$scope.selectedPassengerPnrPosition]['gender'] || passengerData.gender;
      $scope.checkinData[$scope.selectedPassengerPnrPosition]['documentNumber'] = $scope.checkinData[$scope.selectedPassengerPnrPosition]['documentNumber'] || passengerData.documentNumber;
      $scope.checkinData[$scope.selectedPassengerPnrPosition]['documentIssuingCountry'] = $scope.checkinData[$scope.selectedPassengerPnrPosition]['documentIssuingCountry'] || passengerData.documentIssuingCountry;
      $scope.checkinData[$scope.selectedPassengerPnrPosition]['mobilePhone'] = $scope.checkinData[$scope.selectedPassengerPnrPosition]['mobilePhone'] || (profileData && profileData.mobileNumber);
      $scope.checkinData[$scope.selectedPassengerPnrPosition]['documentType'] = $scope.requirements[$scope.selectedPassengerPnrPosition].travelDocument && $scope.requirements[$scope.selectedPassengerPnrPosition].travelDocument.type;


      $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelDocumentForm', 'documentNumber');
      $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelDocumentForm', 'documentIssuingCountry');
      $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'advancePassengerInformationForm', 'mobilePhone');
    };

    $scope.setCheckinData = function() {
      if ($localStorage.cockpit.baskets[$rootScope.activeFilekey] && $localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins) {
        angular.forEach($localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins.passengers, function(passengerACI, pnrPosition) {
          angular.merge($localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins.passengers[pnrPosition], $scope.markupACI);
        });

        $scope.checkinData = angular.copy($localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins.passengers);
        $scope.resetDates();

        $scope.email.value = angular.copy($localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins.email);
      }
    };

    $scope.resetDates = function() {
      var passengerList = ($localStorage.cockpit.baskets[$rootScope.activeFilekey] && $localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins && $localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins.passengers)
                          || $rootScope.booking.passengers;
      var passengerData = $filter('filter')($filter('toArray')(passengerList), {'pnrPosition': $scope.selectedPassengerPnrPosition})[0];

      if (passengerData) {
        $scope.dates[$scope.selectedPassengerPnrPosition] = $scope.dates[$scope.selectedPassengerPnrPosition] || {};

        if (passengerData.birthday) {
          var splittedBirthday = passengerData.birthday.split("-");
          $scope.dates[$scope.selectedPassengerPnrPosition]['birthdayDay'] = splittedBirthday[2];
          $scope.dates[$scope.selectedPassengerPnrPosition]['birthdayMonth'] = splittedBirthday[1];
          $scope.dates[$scope.selectedPassengerPnrPosition]['birthdayYear'] = splittedBirthday[0];
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'advancePassengerInformationForm', 'birthdayYear');
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'advancePassengerInformationForm', 'birthdayMonth');
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'advancePassengerInformationForm', 'birthdayDay');
        }

        if (passengerData.documentIssuingDate) {
          var splittedDocumentIssuingDate = passengerData.documentIssuingDate.split("-");
          $scope.dates[$scope.selectedPassengerPnrPosition]['documentIssuingDay'] = splittedDocumentIssuingDate[2];
          $scope.dates[$scope.selectedPassengerPnrPosition]['documentIssuingMonth'] = splittedDocumentIssuingDate[1];
          $scope.dates[$scope.selectedPassengerPnrPosition]['documentIssuingYear'] = splittedDocumentIssuingDate[0];
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelDocumentForm', 'documentIssuingYear');
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelDocumentForm', 'documentIssuingMonth');
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelDocumentForm', 'documentIssuingDay');
        }

        if (passengerData.documentExpirationDate) {
          var splittedDocumentExpirationDate = passengerData.documentExpirationDate.split("-");
          $scope.dates[$scope.selectedPassengerPnrPosition]['documentExpirationDay'] = splittedDocumentExpirationDate[2];
          $scope.dates[$scope.selectedPassengerPnrPosition]['documentExpirationMonth'] = splittedDocumentExpirationDate[1];
          $scope.dates[$scope.selectedPassengerPnrPosition]['documentExpirationYear'] = splittedDocumentExpirationDate[0];
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelDocumentForm', 'documentExpirationYear');
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelDocumentForm', 'documentExpirationMonth');
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelDocumentForm', 'documentExpirationDay');
          $scope.checkDocumentExpirationDate($scope.dates[$scope.selectedPassengerPnrPosition].documentExpirationYear, $scope.dates[$scope.selectedPassengerPnrPosition].documentExpirationMonth, $scope.dates[$scope.selectedPassengerPnrPosition].documentExpirationDay)
        } else {
          $scope.isDocumentExpirationDateValid[$scope.selectedPassengerPnrPosition] = true;
        }

        if (passengerData.travelPermission && passengerData.travelPermission.visa && passengerData.travelPermission.visa.issuingDate) {
          var splittedVisaIssuingDate = passengerData.travelPermission.visa.issuingDate.split("-");
          $scope.dates[$scope.selectedPassengerPnrPosition]['visaIssuingDay'] = splittedVisaIssuingDate[2];
          $scope.dates[$scope.selectedPassengerPnrPosition]['visaIssuingMonth'] = splittedVisaIssuingDate[1];
          $scope.dates[$scope.selectedPassengerPnrPosition]['visaIssuingYear'] = splittedVisaIssuingDate[0];
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelPermissionsForm', 'visaIssuingYear');
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelPermissionsForm', 'visaIssuingMonth');
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelPermissionsForm', 'visaIssuingDay');
        }

        if (passengerData.travelPermission && passengerData.travelPermission.visa && passengerData.travelPermission.visa.expirationDate) {
          var splittedVisaExpirationDate = passengerData.travelPermission.visa.expirationDate.split("-");
          $scope.dates[$scope.selectedPassengerPnrPosition]['visaExpirationDay'] = splittedVisaExpirationDate[2];
          $scope.dates[$scope.selectedPassengerPnrPosition]['visaExpirationMonth'] = splittedVisaExpirationDate[1];
          $scope.dates[$scope.selectedPassengerPnrPosition]['visaExpirationYear'] = splittedVisaExpirationDate[0];
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelPermissionsForm', 'visaExpirationYear');
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelPermissionsForm', 'visaExpirationMonth');
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelPermissionsForm', 'visaExpirationDay');
        }

        if (passengerData.travelPermission && passengerData.travelPermission.residentPermit && passengerData.travelPermission.residentPermit.expirationDate) {
          var splittedResidentPermitExpirationDate = passengerData.travelPermission.residentPermit.expirationDate.split("-");
          $scope.dates[$scope.selectedPassengerPnrPosition]['residentPermitExpirationDay'] = splittedResidentPermitExpirationDate[2];
          $scope.dates[$scope.selectedPassengerPnrPosition]['residentPermitExpirationMonth'] = splittedResidentPermitExpirationDate[1];
          $scope.dates[$scope.selectedPassengerPnrPosition]['residentPermitExpirationYear'] = splittedResidentPermitExpirationDate[0];
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelPermissionsForm', 'residentPermitExpirationYear');
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelPermissionsForm', 'residentPermitExpirationMonth');
          $scope.validateDropdown($scope.selectedPassengerPnrPosition, 'travelPermissionsForm', 'residentPermitExpirationDay');
        }
      }
    };

    $scope.hasNationalitySelected = function() {
      var hasNationalitySelected = false;

      angular.forEach($scope.checkinData, function(passengerData) {
        if (passengerData.nationality) {
          hasNationalitySelected = true;
        }
      });

      return hasNationalitySelected;
    };

    $scope.validateForm = function(passengerForm, section) {
      if (passengerForm && section && $scope.requirements[passengerForm][section]) {
        var sectionForm = section + 'Form';

        angular.forEach($scope.checkinForm[passengerForm][sectionForm].$error, function(errorType) {
          angular.forEach(errorType, function(errorField) {
            errorField.$setTouched();
          });
        });
      }
    };

    $scope.addAutomaticCheckinToBasket = function() {
      $scope.checkinForm.$setSubmitted();
      $scope.checkinForm.email.$setDirty();
      $scope.status = 'automaticCheckinsRequirementsSuccess';

      angular.forEach($scope.checkinData, function(passenger, pnrPosition) {
        $scope.validateForm(pnrPosition, 'travelDocument');
        $scope.validateForm(pnrPosition, 'travelPermissions');
        $scope.validateForm(pnrPosition, 'advancePassengerInformation');
      });

      if (!$scope.hasNationalitySelected()) {
        $scope.status = 'automaticCheckinCreateNoNationality';
        return;
      }

      var passengersDocumentExpirationDatesValid = !$filter('isEmpty')($scope.isDocumentExpirationDateValid) && Object.values($scope.isDocumentExpirationDateValid).every(function(passengerDocumentValid) {
        return passengerDocumentValid;
      });

      if (!$scope.checkinForm.$valid || !passengersDocumentExpirationDatesValid) {
        $scope.status = 'automaticCheckinCreateFormErrors';
        return;
      }

      var payload = {
        'filekey': $rootScope.activeFilekey,
        'lastname': $rootScope.activeLastname,
        'email': $scope.email.value,
        'preferences': {
          'seating': $scope.seatPreferences.seating,
          'locationInPlane': $scope.seatPreferences.locationInPlane,
        },
        'passengers': $scope.checkinData
      };

      angular.forEach($scope.checkinData, function(passenger, pnrPosition) {
        var bookingPassenger = $filter('filter')($rootScope.booking.passengers, {'pnrPosition': pnrPosition})[0];
        passenger.firstName = bookingPassenger.firstname;
        passenger.lastName = bookingPassenger.lastname;
        passenger.pnrPosition = bookingPassenger.pnrPosition;
        angular.merge(passenger, $scope.markupACI);

        if ($scope.dates[pnrPosition]) {
          if ($scope.dates[pnrPosition].birthdayDay) {
            passenger.birthday = $scope.dates[pnrPosition].birthdayYear + '-' + $scope.dates[pnrPosition].birthdayMonth + '-' + $scope.dates[pnrPosition].birthdayDay;
          }

          if ($scope.dates[pnrPosition].documentIssuingDay) {
            passenger.documentIssuingDate = $scope.dates[pnrPosition].documentIssuingYear + '-' + $scope.dates[pnrPosition].documentIssuingMonth + '-' + $scope.dates[pnrPosition].documentIssuingDay;
          }

          if ($scope.dates[pnrPosition].documentExpirationDay) {
            passenger.documentExpirationDate = $scope.dates[pnrPosition].documentExpirationYear + '-' + $scope.dates[pnrPosition].documentExpirationMonth + '-' + $scope.dates[pnrPosition].documentExpirationDay;
          }

          if ($scope.dates[pnrPosition].visaIssuingDay) {
              passenger.travelPermission['visa']['issuingDate'] = $scope.dates[pnrPosition].visaIssuingYear + '-' + $scope.dates[pnrPosition].visaIssuingMonth + '-' + $scope.dates[pnrPosition].visaIssuingDay;
          }

          if ($scope.dates[pnrPosition].visaExpirationDay) {
            passenger.travelPermission['visa']['expirationDate'] = $scope.dates[pnrPosition].visaExpirationYear + '-' + $scope.dates[pnrPosition].visaExpirationMonth + '-' + $scope.dates[pnrPosition].visaExpirationDay;
          }

          if (passenger.travelPermission && passenger.travelPermission['residentPermit']) {
            if (!passenger.travelPermission['residentPermit']['noExpiredDate'] && $scope.dates[pnrPosition].residentPermitExpirationDay) {
              passenger.travelPermission['residentPermit']['expirationDate'] = $scope.dates[pnrPosition].residentPermitExpirationYear + '-' + $scope.dates[pnrPosition].residentPermitExpirationMonth + '-' + $scope.dates[pnrPosition].residentPermitExpirationDay;
            } else {
              delete passenger.travelPermission['residentPermit']['expirationDate'];
            }
          }
        }

        angular.forEach(passenger.frequentFlyerPrograms, function(frequentFlyerProgram) {
          if ($scope.frequentFlyerPrograms.indexOf(frequentFlyerProgram.name) === -1 || frequentFlyerProgram.name === gettextCatalog.getString('None')) {
            delete passenger.frequentFlyerPrograms[0];
          }

          if ($filter('isEmpty')(passenger.frequentFlyerPrograms)) {
            delete passenger.frequentFlyerPrograms;
          }
        });
      });

      $localStorage.cockpit.baskets[$rootScope.activeFilekey] = $localStorage.cockpit.baskets[$rootScope.activeFilekey] || {};
      $localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins = angular.copy(payload);
      $rootScope.$emit('refreshBasket');

      var logRequestParams = {
        interactionName: 'upsell-automatic-checkin-5-basket',
        filekey: $rootScope.activeFilekey,
        lastname: $rootScope.activeLastname,
        language: $rootScope.activeLocale
      };

      logsProvider.logInteraction(logRequestParams);
    };

    $scope.resetPassengerAci = function(pnrPosition, event) {
      if (event) {
        event.stopPropagation();
      }

      delete $scope.checkinData[pnrPosition];
      $scope.removeAutomaticCheckinFromBasket(pnrPosition);
    };

    $scope.removeAutomaticCheckinFromBasket = function(pnrPosition, event) {
      basketHelper.removeAutomaticCheckinFromBasket(pnrPosition, event);
    };

    $scope.setCountries = function() {
      return countries.getCountries();
    };

    $scope.getAutomaticCheckinRequirements = function(countryCode) {
      if (countryCode) {
        $scope.status = 'automaticCheckinsRequirementsLoading';

        // TODO: COPIT-4487 Adjust request logic/format based on new UX/UI
        var requestParams = {
          pnrIdentifier: $rootScope.pnrIdentifier,
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          nationality: countryCode,
        };

        automaticCheckin.getAutomaticCheckinRequirements(requestParams).then(
          function successCallback(response) {
            if (response.meta && response.meta.success) {
              $scope.status = 'automaticCheckinsRequirementsSuccess';
              $scope.requirements[$scope.selectedPassengerPnrPosition] = response.data;
              $scope.preFillForms();

              var logRequestParams = {
                interactionName: 'upsell-automatic-checkin-3-requirements',
                filekey: $rootScope.activeFilekey,
                lastname: $rootScope.activeLastname,
                language: $rootScope.activeLocale
              };

              logsProvider.logInteraction(logRequestParams);
            } else {
              $scope.status = 'automaticCheckinsRequirementsFailed';
            }
          },
          function errorCallback() {
            $scope.status = 'automaticCheckinsRequirementsFailed';
          }
        )
      }
    };

    $scope.isFrequentFlyerNumberRequired = function(frequentFlyerProgram) {
      return frequentFlyerProgram && frequentFlyerProgram !== gettextCatalog.getString('None');
    };

    $scope.isFrequentFlyerProgramRequired = function(frequentFlyerProgram) {
      return frequentFlyerProgram && frequentFlyerProgram.membershipNumber && (!frequentFlyerProgram.name || frequentFlyerProgram.name === gettextCatalog.getString('None'));
    };

    $scope.prepareYearsDropdown = function(isFuture) {
      var start = isFuture ? moment().year() : moment().year() - 120;
      var end = isFuture ? moment().year() + 10 : moment().year();

      return $scope.getRange(start, end);
    };

    $scope.getRange = function(start, end) {
      var range = [];

      for (var i = start; i <= end; i++) {
        var nb = i < 10 ? "0" + i : i;
        range.push(nb);
      }

      return range;
    };

    $scope.getNumberOfDaysInMonth = function(year, month) {
      return moment(year + '-' + month, 'YYYY-MM').daysInMonth();
    };

    $scope.daysInMonth = function(year, month) {
      return year === moment.utc().format('YYYY') && month === moment.utc().format('MM') ? moment.utc().format('D') : moment(year + '-' + month, 'YYYY-MM').daysInMonth();
    };

    $scope.monthsInYear = function(year) {
      if (year) {
        return year === moment.utc().format('YYYY') ? moment.utc().format('MM') : 12;
      }
    };

    $scope.displayMonth = function(year, month, isFuture) {
      if (year && month) {
        return isFuture && year === moment.utc().format('YYYY') ? parseInt(month) >= parseInt($scope.monthsInYear(year)) : parseInt(month) <= parseInt($scope.monthsInYear(year));
      }
    };

    $scope.displayDay = function(year, month, day, isFuture) {
      if (year && month) {
        if (isFuture && year === moment.utc().format('YYYY') && month === moment.utc().format('MM')) {
          return day >= $scope.daysInMonth(year, month);
        } else {
          return day <= $scope.daysInMonth(year, month);
        }
      }
    };

    $scope.getPassengersWithoutInfant = function() {
      var adultOrChildPassengers = [];

      if ($rootScope.booking && $rootScope.booking.segments[0]) {
        angular.forEach($rootScope.booking.segments[0].passengers, function(passenger) {
          if (passenger.passengerTypeCode !== 'INF') {
            adultOrChildPassengers.push(passenger);
          }
        });
      }

      return adultOrChildPassengers;
    };

    $scope.selectPassenger = function(pnrPosition) {
      if (pnrPosition) {
        $scope.selectedPassengerPnrPosition = pnrPosition;
        $scope.isSeatPreferenceOpen = false;
        $scope.isPassengerDetailsOpen = true;

        var passengerData = $filter('filter')($filter('toArray')($rootScope.booking.passengers), {'pnrPosition': $scope.selectedPassengerPnrPosition})[0];
        var checkinPassenger = $filter('filter')($scope.checkinPassengerList, {'pnrPosition': pnrPosition})[0];
        checkinPassenger['documentType'] = passengerData.documentType;
        checkinPassenger['nationality'] = passengerData.nationality;

        if (checkinPassenger['nationality']) {
          $scope.getAutomaticCheckinRequirements(checkinPassenger['nationality']);
        }

        $scope.openCheckinMobileSidenav();
      }

      angular.forEach($scope.checkinPassengerList, function(passengerCheckin, index) {
        $scope.checkinPassengerList[index].selected = passengerCheckin.pnrPosition === pnrPosition;
      });

      if ($scope.checkinData[$scope.selectedPassengerPnrPosition] && $scope.checkinData[$scope.selectedPassengerPnrPosition].nationality && !$scope.requirements[$scope.selectedPassengerPnrPosition]) {
        $scope.getAutomaticCheckinRequirements($scope.checkinData[$scope.selectedPassengerPnrPosition].nationality);
      }
    };

    $scope.selectSeatPreference = function() {
      if ($scope.selectedPassengerPnrPosition) {
        var passengerSelected = $filter('filter')($scope.checkinPassengerList, {'pnrPosition': $scope.selectedPassengerPnrPosition})[0];
        passengerSelected.selected = false;
        $scope.selectedPassengerPnrPosition = null;
        $scope.isPassengerDetailsOpen = false;
      }

      $scope.isSeatPreferenceOpen = true;

      $scope.openCheckinMobileSidenav();

      var logRequestParams = {
        interactionName: 'upsell-automatic-checkin-4-seats',
        filekey: $rootScope.activeFilekey,
        lastname: $rootScope.activeLastname,
        language: $rootScope.activeLocale
      };

      logsProvider.logInteraction(logRequestParams);
    };

    $scope.openCheckinMobileSidenav = function() {
      if ($rootScope.isExtraSmallScreen) {
        $mdSidenav('sidenavAutomaticCheckinMobile').open();
      }
    };

    $scope.getAirlineTail = function(operatingAirlineCode) {
      if (operatingAirlineCode) {
        return cockpitCdnUrl + '/airlines/tails/' + $filter('lowercase')(operatingAirlineCode) + '.png';
      }
    };

    $scope.setProfileData = function() {
      $scope.email.value = $rootScope.profile && $rootScope.profile.data && $rootScope.profile.data.email;
    };

    $scope.close = function(sideNav) {
      if (sideNav === 'mobile') {
        $mdSidenav('sidenavAutomaticCheckinMobile').close();

        if ($scope.isPassengerDetailsOpen) {
          var passengerSelected = $filter('filter')($scope.checkinPassengerList, {'pnrPosition': $scope.selectedPassengerPnrPosition})[0];
          passengerSelected.selected = false;
          $scope.selectedPassengerPnrPosition = null;
          $scope.isPassengerDetailsOpen = false;
        } else {
          $scope.isSeatPreferenceOpen = false;
        }
      } else if (sideNav === 'desktop') {
        $mdSidenav('sidenavAutomaticCheckin').close();
      }
    };

    $scope.checkDocumentExpirationDate = function(documentExpirationYear, documentExpirationMonth, documentExpirationDay) {
      if (documentExpirationYear && documentExpirationMonth && documentExpirationDay && $scope.requirements[$scope.selectedPassengerPnrPosition] && $scope.requirements[$scope.selectedPassengerPnrPosition].travelDocument) {
        var documentExpirationDate = documentExpirationYear +'-'+ documentExpirationMonth +'-'+ documentExpirationDay;
        $scope.isDocumentExpirationDateValid[$scope.selectedPassengerPnrPosition] = documentExpirationDate > $scope.requirements[$scope.selectedPassengerPnrPosition].travelDocument.docExpirationDate;
      }
    };

    $scope.validateDropdown = function(passengerForm, sectionForm, name) {
      if ($scope.checkinForm[passengerForm] && $scope.checkinForm[passengerForm][sectionForm] && $scope.checkinForm[passengerForm][sectionForm][name]) {
        $scope.checkinForm[passengerForm][sectionForm][name].$setTouched();
      }
    };

    $scope.openBasket = function() {
      $scope.close('mobile');
      $scope.close('desktop');
      basketHelper.toggleBasket(true);
    };
  }
]);
