cockpitApp.filter('truncateTicketNumbers', function() {
  return function (ticketNumbers) {
    if (ticketNumbers.length > 0) {
      return ticketNumbers.reduce(function (prev, curr, index, orig) {
        if (orig[index - 1].substring(0, 14) === curr.substr(0, 14)) {
          return prev;
        } else if (orig[index - 1].substring(0, 11) === curr.substr(0, 11)) {
          return prev + ', -' + curr.substr(11);
        } else {
          return prev + ', ' + curr;
        }
      });
    }
  }
});
