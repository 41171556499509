cockpitApp.provider('profile', [
  function () {
    this.apiProfileRegisterEndpoint = null;
    this.apiProfileVerifyEndpoint = null;
    this.apiProfileLoginEndpoint = null;
    this.apiProfileLinkAssistantEndpoint = null;
    this.apiProfileLogoutEndpoint = null;
    this.apiProfilePasswordEmailEndpoint = null;
    this.apiProfilePasswordResetEndpoint = null;
    this.apiProfileEditEndpoint = null;
    this.apiProfilePictureEndpoint = null;
    this.apiProfileSocialAuthCallbackEndpoint = null;
    this.apiProfileDeleteEndpoint = null;
    this.apiProfileOrdersEndpoint = null;
    this.apiProfilePaymentsEndpoint = null;
    this.apiProfileRemoveBookingEndpoint = null;

    this.setApiProfileRegisterEndpoint = function (newApiProfileRegisterEndpoint) {
      this.apiProfileRegisterEndpoint = newApiProfileRegisterEndpoint;
    };

    this.setApiProfileVerifyEndpoint = function (newApiProfileVerifyEndpoint) {
      this.apiProfileVerifyEndpoint = newApiProfileVerifyEndpoint;
    };

    this.setApiProfileLoginEndpoint = function (newApiProfileLoginEndpoint) {
      this.apiProfileLoginEndpoint = newApiProfileLoginEndpoint;
    };

    this.setApiProfileLinkAssistantEndpoint = function (newApiProfileLinkAssistantEndpoint) {
      this.apiProfileLinkAssistantEndpoint = newApiProfileLinkAssistantEndpoint;
    };

    this.setApiProfileLogoutEndpoint = function (newApiProfileLogoutEndpoint) {
      this.apiProfileLogoutEndpoint = newApiProfileLogoutEndpoint;
    };

    this.setApiProfilePasswordEmailEndpoint = function (newApiProfilePasswordEmailEndpoint) {
      this.apiProfilePasswordEmailEndpoint = newApiProfilePasswordEmailEndpoint;
    };

    this.setApiProfilePasswordResetEndpoint = function (newApiProfilePasswordResetEndpoint) {
      this.apiProfilePasswordResetEndpoint = newApiProfilePasswordResetEndpoint;
    };

    this.setApiProfileEditEndpoint = function (newApiProfileEditEndpoint) {
      this.apiProfileEditEndpoint = newApiProfileEditEndpoint;
    };

    this.setApiProfilePictureEndpoint = function (newApiProfilePictureEndpoint) {
      this.apiProfilePictureEndpoint = newApiProfilePictureEndpoint;
    };

    this.setApiProfileSocialAuthCallbackEndpoint = function (newApiProfileSocialAuthCallbackEndpoint) {
      this.apiProfileSocialAuthCallbackEndpoint = newApiProfileSocialAuthCallbackEndpoint;
    };

    this.setApiProfileDeleteEndpoint = function (newApiProfileDeleteEndpoint) {
      this.apiProfileDeleteEndpoint = newApiProfileDeleteEndpoint;
    };

    this.setApiProfileOrdersEndpoint = function (newApiProfileOrdersEndpoint) {
      this.apiProfileOrdersEndpoint = newApiProfileOrdersEndpoint;
    };

    this.setApiProfilePaymentsEndpoint = function (newApiProfilePaymentsEndpoint) {
      this.apiProfilePaymentsEndpoint = newApiProfilePaymentsEndpoint;
    };

    this.setApiProfileRemoveBookingEndpoint = function (newApiProfileRemoveBookingEndpoint) {
      this.apiProfileRemoveBookingEndpoint = newApiProfileRemoveBookingEndpoint;
    };

    this.$get = ['$rootScope', 'cockpitClient', '$localStorage', 'authManager', '$location', 'gettextCatalog', 'bookingHelper', 'profileHelper', 'componentHelper', 'navigationHelper',
      function ($rootScope, cockpitClient, $localStorage, authManager, $location, gettextCatalog, bookingHelper, profileHelper, componentHelper, navigationHelper) {
        var self = this;

        return {
          register: register,
          verify: verify,
          login: login,
          linkProfileAssistant: linkProfileAssistant,
          logout: logout,
          sendEmailResetPassword: sendEmailResetPassword,
          resetPassword: resetPassword,
          edit: edit,
          changeProfilePicture: changeProfilePicture,
          socialAuthCallback: socialAuthCallback,
          deleteProfile: deleteProfile,
          getProfileOrders: getProfileOrders,
          getProfilePayments: getProfilePayments,
          removeBookingFromProfile: removeBookingFromProfile
        };

        function register(requestParams) {
          var response = cockpitClient.remoteCall(self.apiProfileRegisterEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }

        function verify(requestParams) {
          var response = cockpitClient.remoteCall(self.apiProfileVerifyEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }

        function login(requestParams) {
          var response = cockpitClient.remoteCall(self.apiProfileLoginEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }

        function linkProfileAssistant(requestParams) {
          var response = cockpitClient.remoteCall(self.apiProfileLinkAssistantEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }

        function logout() {
          $rootScope.profile = undefined;
          sessionStorage.removeItem('session_profile');
          // Temporary: Remove all the baskets from local storage as the bookings have been removed
          $localStorage.cockpit.baskets = {};

          authManager.unauthenticate();

          var response = cockpitClient.remoteCall(self.apiProfileLogoutEndpoint, {}, 'POST', null, null);
          // Profile already logged out. No need to know the response status on the client
          cockpitClient.resolveRequest(response);
        }

        function sendEmailResetPassword(requestParams) {
          var response = cockpitClient.remoteCall(self.apiProfilePasswordEmailEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }

        function resetPassword(requestParams) {
          var response = cockpitClient.remoteCall(self.apiProfilePasswordResetEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }

        function edit(requestParams) {
          var response = cockpitClient.remoteCall(self.apiProfileEditEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }

        function changeProfilePicture(requestParams) {
          var response = cockpitClient.remoteCall(self.apiProfilePictureEndpoint, requestParams, 'POST', 'formData', null);

          return cockpitClient.resolveRequest(response);
        }

        function deleteProfile(requestParams) {
          var response = cockpitClient.remoteCall(self.apiProfileDeleteEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }

        function removeBookingFromProfile(requestParams) {
          var response = cockpitClient.remoteCall(self.apiProfileRemoveBookingEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }

        function socialAuthCallback() {
          if ($location.search().socialLoginError) {
            openLoginDialogFromSocialMediaError($location.search().socialLoginError);
          } else if ($location.search().platform && ($location.search().code || ($location.search().oauth_token && $location.search().oauth_verifier))) {
            var requestParams = {
              platform: $location.search().platform,
              url: $location.absUrl(),
              locale: $rootScope.activeLocale,
              bookingsCredentials: bookingHelper.getBookingsCredentials()
            };

            switch ($location.search().platform) {
              case 'facebook':
              case 'google':
                requestParams.code = $location.search().code;
                break;
              case 'twitter':
                requestParams.oauth_token = $location.search().oauth_token;
                requestParams.oauth_verifier = $location.search().oauth_verifier;
                break;
            }

            var response = cockpitClient.remoteCall(self.apiProfileSocialAuthCallbackEndpoint, requestParams, 'POST');

            cockpitClient.resolveRequest(response).then(
              function successCallback (response) {
                if (response.meta && response.meta.success && response.data) {
                  navigationHelper.profileLoggedInPostAction(response.data, requestParams.platform);
                } else {
                  openLoginDialogFromSocialMediaError(requestParams.platform)
                }
              }, function errorCallback (response) {
                openLoginDialogFromSocialMediaError(requestParams.platform);
              }
            );
          }
        }

        function openLoginDialogFromSocialMediaError(platform) {
          var dialogData = {
            data: {
              platform: platform
            },
            onShowing: 'loginFromSocialMediaError'
          };
          componentHelper.openDialog('/element/dialog/profile/main', event, dialogData);
        }

        function getProfileOrders(requestParams) {
          var response = cockpitClient.remoteCall(self.apiProfileOrdersEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }

        function getProfilePayments() {
          var response = cockpitClient.remoteCall(self.apiProfilePaymentsEndpoint, {}, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }
      }
    ];
  }
]);
