cockpitApp.filter('translateSeatMapLocation', [
  'gettextCatalog',
  function (gettextCatalog) {
    return function (value) {
      switch (value) {
        case 'MAIN_DECK':
          return gettextCatalog.getString('Main deck');
        case 'UPPER_DECK':
          return gettextCatalog.getString('Upper deck');
        default:
          return gettextCatalog.getString(value);
      }
    }
  }
]);
