cockpitApp.factory('localeHelper', [
  '$rootScope', '$location', '$localStorage', '$window', '$filter', 'gettextCatalog', 'reCaptcha', 'logsProvider',
  function($rootScope, $location, $localStorage, $window, $filter, gettextCatalog, reCaptcha, logsProvider)
  {
    var factory = {};

    $rootScope.supportedLocales = {
      'de': 'de_DE',
      'en': 'en_GB',
      'us': 'en_US',
      'es': 'es_ES',
      'fr': 'fr_FR'
    };

    $rootScope.relatedCountries = {
      'de_DE': ['DE', 'AT', 'CH'],
      'en_GB': ['GB'],
      'en_US': ['US', 'CA'],
      'es_ES': ['ES'],
      'fr_FR': ['FR']
    };

    var locales = Object.keys($rootScope.supportedLocales).map(function(item) {
      return $rootScope.supportedLocales[item];
    });

    factory.getLanguageName = function(locale) {
      switch (locale) {
        case 'en_GB':
          return gettextCatalog.getString('English (United Kingdom)');
        case 'en_US':
          return gettextCatalog.getString('English (United States)');
        case 'de_DE':
          return gettextCatalog.getString('Deutsch (German)');
        case 'es_ES':
          return gettextCatalog.getString('Español (Spanish)');
        case 'fr_FR':
          return gettextCatalog.getString('Français (French)');
        case 'it_IT':
          return gettextCatalog.getString('Italiano (Italian)');
        case 'nl_NL':
          return gettextCatalog.getString('Nederlands (Dutch)');
        case 'ru_RU':
          return gettextCatalog.getString('Pусский (Russian)');
        default:
          return locale;
      }
    };

    factory.getLanguageFromBrowser = function() {
      var browserLanguage = $window.navigator.language.substring(0, 2);

      if (browserLanguage === 'en') {
        var browserLocale = $window.navigator.language.substring(3).toLowerCase();
        browserLanguage = browserLocale && browserLocale === 'gb' ? browserLanguage : browserLocale;
      }

      return $rootScope.supportedLocales[browserLanguage] || 'en_GB';
    };

    factory.getLanguageFromUrl = function(localeParam, isLanguage) {
      if (localeParam) {
        localeParam = localeParam.toLowerCase();

        for (var i = 0, len = locales.length; i < len; i++) {
          var splitLocale = isLanguage ? locales[i].split('_')[0] : locales[i].split('_')[1].toLowerCase();

          if (splitLocale === localeParam) {
            localeParam = locales[i];
            break;
          }
        }

        return locales.indexOf(localeParam) > -1 ? localeParam : null;
      }
    };

    factory.getLanguage = function() {
      var locale = $rootScope.iframePayload.locale || null;

      if ($location.search().language) {
        locale = this.getLanguageFromUrl($location.search().language, true);
      }

      if (locales.indexOf(locale) === -1 && $location.path().split('/')[1].length === 2) {
        locale = this.getLanguageFromUrl($location.path().split('/')[1]);
      }

      locale = locales.indexOf(locale) === -1 ? $localStorage.cockpit && $localStorage.cockpit.activeLocale : locale;
      locale = locales.indexOf(locale) === -1 ? this.getLanguageFromBrowser() : locale;

      return locale;
    };

    factory.setLocaleDependencies = function() {
      gettextCatalog.setCurrentLanguage($rootScope.activeLocale);
      gettextCatalog.loadRemote('/lang/' + $rootScope.activeLocale + '.json');
      $localStorage.cockpit.activeLocale = $rootScope.activeLocale;

      var language = $rootScope.activeLocale.split('_')[0];
      var locale = $rootScope.activeLocale.split('_')[1].toLowerCase();
      var momentLocale = $rootScope.supportedLocales[locale] ? locale : language;
      moment.locale(momentLocale);
      reCaptcha.loadGoogleRecaptcha(language);
    };

    factory.changeLocale = function(localeTo, interactionName) {
      var localeFrom = $rootScope.activeLocale;

      var logRequestParams = {
        interactionName: interactionName + 'LanguageChanged',
        filekey: $rootScope.activeFilekey,
        lastname: $rootScope.activeLastname,
        language: localeFrom,
        details: {
          languageFrom: localeFrom,
          languageTo: localeTo
        }
      };

      logsProvider.logInteraction(logRequestParams);

      $rootScope.activeLocale = localeTo;
      this.setLocaleDependencies();
      $rootScope.countries = $filter('sortCountries')(localeTo);
    };

    factory.getCountryFlag = function(countryCode) {
      if (!$filter('isEmpty')($rootScope.countries)) {
        var currentLocaleArray = $filter('filter')($rootScope.countries, {'code': countryCode.substring(3,5)});

        return !$filter('isEmpty')(currentLocaleArray) ? currentLocaleArray[0].flagUrl : null;
      }
    };

    return factory;
  }
]);
