cockpitApp.provider('selectMeal', [
  function () {
    this.apiMealMenuEndpoint = null;
    this.apiMealModifyEndpoint = null;
  
    this.setApiMealMenuEndpoint = function (newApiMealMenuEndpoint) {
      this.apiMealMenuEndpoint = newApiMealMenuEndpoint;
    };
  
    this.setApiMealModifyEndpoint = function (newApiMealModifyEndpoint) {
      this.apiMealModifyEndpoint = newApiMealModifyEndpoint;
    };
    
    this.$get = ['cockpitClient', '$localStorage', '$rootScope',
      function (cockpitClient, $localStorage, $rootScope) {
        var self = this;
        
        return {
          getAirlineMealMenu: getAirlineMealMenu,
          modifyMealSelection: modifyMealSelection
        };
  
        function getAirlineMealMenu(requestParams) {
          var response = cockpitClient.remoteCall(self.apiMealMenuEndpoint, requestParams, 'POST', null, null);
  
          var resolvedResponse = cockpitClient.resolveRequest(response);
  
          resolvedResponse.then(
            function successCallback(response) {
              if (response.meta && response.meta.success && response.data) {
                $localStorage.cockpit.translations.meals = $localStorage.cockpit.translations.meals || {};
                $localStorage.cockpit.weblinks.meals = $localStorage.cockpit.weblinks.meals || {};
                $localStorage.cockpit.weblinks.meals[requestParams.airlineCode] = $localStorage.cockpit.weblinks.meals[requestParams.airlineCode] || {};
                angular.merge($localStorage.cockpit.translations.meals, response.data.translations);
                angular.merge($localStorage.cockpit.weblinks.meals[requestParams.airlineCode], response.data.weblinks);
                $rootScope.translations = $localStorage.cockpit.translations;
                $rootScope.weblinks = $localStorage.cockpit.weblinks;
              }
            }
          );
  
          return resolvedResponse;
        }
  
        function modifyMealSelection(requestParams) {
          var response = cockpitClient.remoteCall(self.apiMealModifyEndpoint, requestParams, 'POST', null, null);
    
          return cockpitClient.resolveRequest(response);
        }
      }
    ];
  }
]);
