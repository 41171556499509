cockpitApp.filter('objectToArray', function () {
  return function (object) {
    if (object) {
      if (!$.isArray(object)) {
        return Object.keys(object).map(function (key) {
          return object[key];
        });
      }
      
      return object;
    }
  }
});
