cockpitApp.filter('durationDisplay', [
  'gettextCatalog',
  function(gettextCatalog) {
    return function(durationAsMinutes) {
      if (durationAsMinutes) {
        var value = parseInt(durationAsMinutes);
        var units = {
          'years': 24*60*365,
          'months': 24*60*30,
          'days': 24*60,
          'hours': 60,
          'minutes': 1
        };

        var result = {};

        for (var name in units) {
          result[name] = Math.floor(value/units[name]);
          value %= units[name];
        }

        result['minutes'] = result['minutes'] < 10 ? '0' + result['minutes'] : result['minutes'];

        var years = gettextCatalog.getPlural(result['years'], '{{years}} yr.', '{{years}} yr.', {'years':  result['years']});
        var months = gettextCatalog.getPlural(result['months'], '{{months}} mth', '{{months}} mths', {'months':  result['months']});
        var days = gettextCatalog.getPlural(result['days'], '{{days}} d', '{{days}} d', {'days':  result['days']});
        var hours = gettextCatalog.getPlural(result['hours'], '{{hours}} hr', '{{hours}} hrs', {'hours':  result['hours']});
        var minutes = gettextCatalog.getPlural(result['minutes'], '{{minutes}} min', '{{minutes}} mins', {'minutes':  result['minutes']});

        // We display only the 2 bigger units that are different than 0
        switch (true) {
          case result['years'] > 0:
            return result['months'] > 0 ? [years, months].join(' ') : years;
          case result['months'] > 0:
            return result['days'] > 0 ? [months, days].join(' ') : months;
          case result['days'] > 0:
            return result['hours'] > 0 ? [days, hours].join(' ') : days;
          case result['hours'] > 0:
            return result['minutes'] > 0 ? [hours, minutes].join(' ') : hours;
          case result['minutes'] > 0:
            return minutes;
        }
      }
    }
  }
]);
