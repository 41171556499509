cockpitApp.provider('insurance', [
  function () {
    this.apiInsuranceSetooEndpoint = null;
    
    this.setApiInsuranceSetooEndpoint = function (newApiInsuranceSetooEndpoint) {
      this.apiInsuranceSetooEndpoint = newApiInsuranceSetooEndpoint;
    };
    
    this.$get = ['$rootScope', 'cockpitClient',
      function ($rootScope, cockpitClient) {
        var self = this;
        
        return {
          getSetooOffer: getSetooOffer
        };
        
        function getSetooOffer() {
          var requestParams = {
            'filekey': $rootScope.activeFilekey,
            'locale': $rootScope.activeLocale,
            'lastname': $rootScope.activeLastname
          };
          
          var response = cockpitClient.remoteCall(self.apiInsuranceSetooEndpoint, requestParams, 'POST', null, null);
          
          return cockpitClient.resolveRequest(response);
        }
      }
    ];
  }
]);
