cockpitApp.filter('translateCashUpgradeDescription', ['gettextCatalog', function(gettextCatalog) {
  return function (value) {
    switch (value.toUpperCase()) {
      case 'UPGRADE TO CABIN: FIRST':
        return gettextCatalog.getString('Upgrade to first class cabin');
      case 'UPGRADE TO CABIN: BUSINESS':
        return gettextCatalog.getString('Upgrade to business cabin');
      case 'UPGRADE TO CABIN: PREMIUM ECONOMY':
        return gettextCatalog.getString('Upgrade to premium economy cabin');
      default:
        return value;
    }
  }
}]);
