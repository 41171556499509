cockpitApp.controller('notificationDialogController', [
  '$scope', '$rootScope', '$mdDialog', '$localStorage', 'navigationHelper', 'dataHelper',
  function ($scope, $rootScope, $mdDialog, $localStorage, navigationHelper, dataHelper)
  {
    $scope.goToDetails = function (filekey, searchName) {
      navigationHelper.redirectWithLocale('/details/:' + filekey + '/:' + searchName);
      $scope.close();
      dataHelper.setValue(true, 'openBasket');
    };
  
    $rootScope.$on('displayNotificationDialog', function(event, data) {
      $scope.data = data;
    });

    $scope.close = function() {
      $mdDialog.cancel();
    };
  }
]);
