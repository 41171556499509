cockpitApp.filter('isSameDate', function() {
  return function(dateTimeOne, dateTimeTwo, unit, timezoneOne, timezoneTwo) {
    unit = unit || 'day';
    timezoneOne = timezoneOne || moment.tz.guess();
    timezoneTwo = timezoneTwo || moment.tz.guess();

    if (!moment.isMoment(dateTimeOne)) {
      dateTimeOne = moment.tz(dateTimeOne, timezoneOne);
    }

    if (!moment.isMoment(dateTimeTwo)) {
      dateTimeTwo = dateTimeTwo ? moment.tz(dateTimeTwo, timezoneTwo) : moment.tz(timezoneTwo);
    }

    return dateTimeOne.isSame(dateTimeTwo, unit);
  }
});
