cockpitApp.filter('hasPastSegments', [
  '$filter',
  function($filter) {
    return function(segments) {
      if (!$filter('isEmpty')(segments)) {
        return segments.some(function(segment) {
          return $filter('isPast')(segment.arrivalDateTime, segment.arrivalTimezone);
        });
      }
    }
  }
]);
