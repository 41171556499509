cockpitApp.filter('responsiveAirportTerminal', function() {
  return function(value, isMobile) {
    if (value) {
      var airportTerminal = value.toLowerCase();
      if (isMobile) {
        airportTerminal = airportTerminal.replace("international", 'int.');
      }
  
      var airportTerminalSplitted = airportTerminal.split(" ");
      var airportTerminalNumber = airportTerminalSplitted.pop();
      var airportTerminalPrefix = airportTerminalSplitted.join(" ");
      
      return airportTerminalPrefix.charAt(0).toUpperCase() + airportTerminalPrefix.substr(1).toLowerCase() + " " + airportTerminalNumber.toUpperCase();
    }
  }
});
