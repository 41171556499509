cockpitApp.controller('progressBarController', [
  '$rootScope', '$timeout', 'ngProgressFactory',
  function ($rootScope, $timeout, ngProgressFactory)
  {
    var progressbar = ngProgressFactory.createInstance();
    progressbar.setParent(document.getElementById('progress-bar'));

    $rootScope.$on('startProgress', function () {
      progressbar.reset();
      progressbar.start();
    });

    $rootScope.$on('stopProgress', function () {
      progressbar.complete();
    });
  }
]);
