cockpitApp.controller('baggageController', [
  '$rootScope', '$scope', '$mdSidenav', '$filter', '$localStorage', 'ancillary', 'baggageHelper', 'basketHelper', 'bookingHelper', 'componentHelper', 'domHelper', 'gettextCatalog', 'logsProvider', 'profile', 'profileHelper',
  function($rootScope, $scope, $mdSidenav, $filter, $localStorage, ancillary, baggageHelper, basketHelper, bookingHelper, componentHelper, domHelper, gettextCatalog, logsProvider, profile, profileHelper)
  {
    $scope.resetBaggageSidenav = function() {
      $scope.basket = $localStorage.cockpit.baskets[$rootScope.activeFilekey];
      $scope.baggageCarouselLabels = {};
      $scope.baggageMaxWeight = {};
      $scope.baggageSelected = {};
      $scope.selectedPassengerPnrPosition = null;
      $scope.passengerList = [];
      $scope.segmentPriceLists = null;
      $scope.selectedBaggageLabel = null;
      $scope.baggageCounter = {};
      $scope.maxCounter = null;
      $scope.ancillaryUnavailable = {
        message: gettextCatalog.getString('The airline does not provide us with a baggage selection for your selected flights.'),
        imageNormal: '/img/moods/pages/baggage.png',
        imageDouble: '/img/moods/pages/baggage@2x.png',
        imageTriple: '/img/moods/pages/baggage@3x.png',
      }
    };

    $rootScope.$on('sidenavBaggage:open', function(event, data) {
      $scope.status = 'loading';
      $scope.resetBaggageSidenav();
      $scope.getBaggageData();
    });

    $scope.getBaggageData = function() {
      var requestParams = {
        filekey: $rootScope.activeFilekey,
        lastname: $rootScope.activeLastname,
        serviceType: 'BAGGAGE',
      };

      var logRequestParamsBaggageOpened = {
        interactionName: 'baggageOpened',
        filekey: $rootScope.activeFilekey,
        lastname: $rootScope.activeLastname,
        language: $rootScope.activeLocale
      };

      logsProvider.logInteraction(logRequestParamsBaggageOpened);

      ancillary.getBaggageList(requestParams).then(
        function successCallback(response) {
          if (response.meta && response.meta.success) {
            if ($filter('isEmpty')(response.data) || $filter('isEmpty')(response.data.baggage)) {
              $scope.status = 'error';
              $scope.passengerList = bookingHelper.getPassengersToDisplay();
            } else {
              $scope.baggage = response.data.baggage;
              angular.copy($scope.baggage, $scope.baggageSelected);

              $scope.checkBasketBaggage();

              angular.forEach(response.data.translations, function(translation, index) {
                $localStorage.cockpit.translations[index] = translation;
              });

              $scope.passengerList = bookingHelper.getPassengersToDisplay();
              $scope.passengerList[0].pnrPosition && $scope.selectPassenger($scope.passengerList[0].pnrPosition);
              $scope.status = 'success';
              var logRequestParamsBaggageOpenedAvailable = angular.copy(logRequestParamsBaggageOpened);
              logRequestParamsBaggageOpenedAvailable.interactionName = 'baggageOpenedAvailable';
              logsProvider.logInteraction(logRequestParamsBaggageOpenedAvailable);
            }
          } else {
            $scope.status = 'error';
          }
        },
        function errorCallback() {
          $scope.status = 'error';
        }
      );
    };

    $scope.$watch(function() {
      return $mdSidenav('sidenavBaggage').isOpen();
    }, function(newValue) {
      $rootScope.isSideNavOpen = newValue;

      if (!$rootScope.isSideNavOpen) {
        $scope.resetBaggageSidenav();
      }
    });

    $scope.openBasket = function() {
      $scope.close();
      basketHelper.toggleBasket(true);
    };

    $scope.close = function() {
      $mdSidenav('sidenavBaggage').close();
    };

    $scope.checkBasketBaggage = function() {
      angular.forEach($scope.baggageSelected, function(pnrPositionBaggage, pnrPosition) {
        angular.forEach(pnrPositionBaggage, function(passengerBaggage, baggageCode) {
          $scope.baggageSelected[pnrPosition][baggageCode].segmentPriceList = {};
          $scope.baggageSelected[pnrPosition][baggageCode].label = $filter('translateData')(baggageCode, 'baggage', true);

          if ($scope.basket && $scope.basket.baggage && $scope.basket.baggage[pnrPosition]
            && $scope.basket.baggage[pnrPosition][baggageCode]
            && !$filter('isEmpty')($scope.basket.baggage[pnrPosition][baggageCode].segmentPriceList)) {
            angular.copy($scope.basket.baggage[pnrPosition][baggageCode].segmentPriceList, $scope.baggageSelected[pnrPosition][baggageCode].segmentPriceList);
          }
        });
      });
    };

    $scope.isSelectAllCounterDisabled = function(counter, interaction){
      if ($scope.baggageCarouselLabels && $scope.selectedPassengerPnrPosition && $scope.selectedBaggageLabel && $scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition]
        && $scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel]) {
        return (counter === $scope.minCounter.selectAll && interaction === 'remove') || (counter === $scope.maxCounter && interaction === 'add');
      }
    };

    $scope.isSegmentCounterDisabled = function(baggageCounter, index) {
      var bagCodeIndex = Object.keys($scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel])[baggageCounter - 1];

      return !bagCodeIndex || ($scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel][bagCodeIndex].segmentPriceList[index] &&
        $scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel][bagCodeIndex].segmentPriceList[index].purchased)
    };

    $scope.autoFillCounter = function() {
      var bagCodeKeys = Object.keys($scope.baggage[$scope.selectedPassengerPnrPosition]);
      var segmentListIndex = null;
      var segmentListBaggageCode = null;
      var baggageCounter = null;

      // Fills the counter with the already purchased baggages
      angular.forEach($scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition], function(baggageLabelsBaggage, baggageLabel) {
        angular.forEach(baggageLabelsBaggage, function (baggage, baggageCode) {
          angular.forEach(baggage.segmentPriceList, function (segmentPriceList, segmentListIndex) {
            if (segmentPriceList.purchased) {
              if ($scope.baggageCounter[$scope.selectedPassengerPnrPosition][baggageLabel][segmentListIndex] === 0) {
                $scope.baggageCounter[$scope.selectedPassengerPnrPosition][baggageLabel][segmentListIndex]++;
              }
              $scope.minCounter['selectAll'] = $scope.getSelectAllCounter(baggageLabel);
              baggageCounter = $scope.baggageCounter[$scope.selectedPassengerPnrPosition][baggageLabel][segmentListIndex] === $scope.maxCounter ? $scope.maxCounter - 1 : $scope.baggageCounter[$scope.selectedPassengerPnrPosition][baggageLabel][segmentListIndex];
              $scope.setSegmentPriceLists(baggageLabel, segmentListIndex, baggageCounter);
            }
          });
        });
      });

      // Fills the counter with the basket baggages
      if ($scope.basket && $scope.basket.baggage && $scope.basket.baggage[$scope.selectedPassengerPnrPosition]) {
       angular.forEach($scope.basket.baggage[$scope.selectedPassengerPnrPosition], function(passengerBaggage) {
        angular.forEach(passengerBaggage.segmentPriceList, function(segmentPriceList, baggageCodeIndex) {
          segmentListIndex = parseInt(baggageCodeIndex.slice(-1));
          segmentListBaggageCode = baggageCodeIndex.slice(0, -2);
          var baggageLabel;

          angular.forEach($scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition], function(baggageLabelsBaggage, label) {
            angular.forEach(baggageLabelsBaggage, function(baggage, baggageCode) {
              if (baggageCode === segmentListBaggageCode) {
                baggageLabel = label;
              }
            });
          });

          if (bagCodeKeys.includes(segmentListBaggageCode)) {
            $scope.baggageCounter[$scope.selectedPassengerPnrPosition][baggageLabel][segmentListIndex] = 1;
            baggageCounter = $scope.baggageCounter[$scope.selectedPassengerPnrPosition][baggageLabel][segmentListIndex] === $scope.maxCounter ? $scope.maxCounter - 1 : $scope.baggageCounter[$scope.selectedPassengerPnrPosition][baggageLabel][segmentListIndex];
            $scope.setSegmentPriceLists(baggageLabel, segmentListIndex, baggageCounter);
          }
         });
       });
      }
    };

    // Flags the already purchased baggages with 'purchased' -> true
    $scope.checkPurchasedBaggage = function(pnrPosition) {
      angular.forEach($scope.baggage[pnrPosition], function(passengerBaggage, baggageCode) {
        angular.forEach(passengerBaggage.segmentPriceList, function(segmentPriceListDetails, segmentPriceListCode) {
          angular.forEach(segmentPriceListDetails.includedSegments, function(includedSegment) {
            angular.forEach($rootScope.booking.segments, function(segment) {
              if (segment.segmentExtendedNumber == includedSegment.number || segment.segmentExtendedNumber == includedSegment.crypticSegmentValue) {
                angular.forEach(segment.passengers, function(passenger) {
                  if (pnrPosition === passenger['pnrPosition']) {
                    angular.forEach(passenger.specialServiceRequests, function(specialServiceRequest) {
                      if (specialServiceRequest.code === baggageCode.substring(0, 4)) {
                        $scope.baggage[pnrPosition][baggageCode].segmentPriceList[segmentPriceListCode]['purchased'] = true;
                      }
                    });
                  }
                });
              }
            });
          });
        });
        if ($filter('isEmpty')($scope.baggage[pnrPosition][baggageCode].segmentPriceList)) {
          delete $scope.baggage[pnrPosition][baggageCode];
        }
      });
    };

    $scope.selectPassenger = function(pnrPosition) {
      $scope.minCounter = {
        'selectAll': 0,
      };

      angular.forEach($scope.passengerList, function(passengerBaggage, index) {
        if (passengerBaggage.pnrPosition === pnrPosition) {
          $scope.selectedPassengerPnrPosition = pnrPosition;
          $scope.selectedPassengerId = passengerBaggage.id;
        }
        $scope.passengerList[index].selected = passengerBaggage.pnrPosition === pnrPosition;
      });

      $scope.checkPurchasedBaggage(pnrPosition);
      $scope.baggageCarouselLabels[pnrPosition] = baggageHelper.getBaggageCodeLabel($scope.baggage[pnrPosition]);

      angular.forEach($scope.baggageCarouselLabels[pnrPosition], function(baggage, baggageLabel) {
        var baggageMaxWeight = baggage[Object.keys(baggage)[0]]['segmentPriceList'][0]['baggageMaxWeight'];

        if (baggageMaxWeight) {
          if ($rootScope.booking.currencyCode !== 'USD') {
            $scope.baggageMaxWeight[baggageLabel] = (baggageMaxWeight['KG'] || 0) + 'kg';
          } else {
            $scope.baggageMaxWeight[baggageLabel] = (baggageMaxWeight['LB'] || 0) + 'lb';
          }
        }
      });

      $scope.baggageCounter[$scope.selectedPassengerPnrPosition] = $scope.baggageCounter[$scope.selectedPassengerPnrPosition] || {};
      $scope.selectBaggageLabel(Object.keys($scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition])[0]);
    };

    $scope.selectBaggageLabel = function(baggage) {
      $scope.selectedBaggageLabel = baggage;
      $scope.maxCounter = Object.keys($scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel]).length;
      $scope.setSegmentPriceLists();
      $scope.autoFillCounter();
    };

    $scope.getSelectAllCounter = function(baggageLabel) {
      var segmentCounters = Object.assign({}, $scope.baggageCounter[$scope.selectedPassengerPnrPosition][baggageLabel]);
      delete segmentCounters.selectAll;
      var counters = $filter('toArray')(segmentCounters);
      return Math.min.apply(null, counters);
    };

    $scope.setBaggageCounter = function() {
      angular.forEach($scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition], function(baggageLabelsBaggage, baggageLabel) {
        angular.forEach(baggageLabelsBaggage, function(baggage) {
          angular.forEach(baggage.segmentPriceList, function (segmentPriceList, index) {
            $scope.baggageCounter[$scope.selectedPassengerPnrPosition][baggageLabel] = $scope.baggageCounter[$scope.selectedPassengerPnrPosition][baggageLabel] || {};
            $scope.baggageCounter[$scope.selectedPassengerPnrPosition][baggageLabel][index] = $scope.baggageCounter[$scope.selectedPassengerPnrPosition][baggageLabel][index] || 0;
          });
          $scope.baggageCounter[$scope.selectedPassengerPnrPosition][baggageLabel]['selectAll'] = 0;
        });
      });
    };

    $scope.setSegmentPriceLists = function(baggageLabel, index, baggageCounter) {
      var bagCodeIndex = Object.keys($scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel])[baggageCounter] || Object.keys($scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel])[0];
      var segmentPriceLists = $scope.segmentPriceLists || null;

      if ($scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition] && $scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel] && $scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel][bagCodeIndex] &&
        $scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel][bagCodeIndex].segmentPriceList) {

        if (typeof index !== 'undefined' && $scope.segmentPriceLists) {
          segmentPriceLists[index] = Object.assign({}, $scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel][bagCodeIndex].segmentPriceList[index]);
        } else {
          segmentPriceLists = Object.assign({}, $scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel][bagCodeIndex].segmentPriceList);
        }

        $scope.segmentPriceLists = segmentPriceLists;

        typeof $scope.baggageCounter[$scope.selectedPassengerPnrPosition][baggageLabel] !== 'undefined' || $scope.setBaggageCounter();

        angular.forEach($scope.baggageCounter[$scope.selectedPassengerPnrPosition], function(baggage, label) {
          $scope.baggageCounter[$scope.selectedPassengerPnrPosition][label]['selectAll'] = $scope.getSelectAllCounter(label);
        });
      }
    };

    $scope.changeBaggageSegment = function(index, operation) {
      if (!profileHelper.isLoggedIn() && !$rootScope.isAuthenticated) {
        var dialogData = {
          onShowing: 'loginFromBaggage'
        };

        componentHelper.openDialog('/element/dialog/profile/main', event, dialogData);
        profile.logout();

        return false;
      }

      var passengerId = $scope.passengerList.find(function(passenger) {
        return passenger.pnrPosition === $scope.selectedPassengerPnrPosition;
      }).id;

      var baggageCode;

      switch (operation) {
        case 'add':
          if ($scope.baggageCounter[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel][index] < $scope.maxCounter) {
            baggageCode = Object.keys($scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel])[$scope.baggageCounter[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel][index]];
            $scope.baggageCounter[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel][index]++;
            $scope.baggageSelected[$scope.selectedPassengerPnrPosition][baggageCode].segmentPriceList[baggageCode + ':' + index] = $scope.segmentPriceLists[index];
            $scope.baggageSelected[$scope.selectedPassengerPnrPosition][baggageCode].segmentPriceList[baggageCode + ':' + index].passengerId = passengerId;
          }

          break;
        case 'remove':
          if (!$scope.isSegmentCounterDisabled($scope.baggageCounter[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel][index], index)) {
            $scope.baggageCounter[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel][index]--;
            baggageCode = Object.keys($scope.baggageCarouselLabels[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel])[$scope.baggageCounter[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel][index]];
          }

          if ($scope.baggageSelected[$scope.selectedPassengerPnrPosition][baggageCode] && $scope.baggageSelected[$scope.selectedPassengerPnrPosition][baggageCode].segmentPriceList[baggageCode + ':' + index]) {
            delete $scope.baggageSelected[$scope.selectedPassengerPnrPosition][baggageCode].segmentPriceList[baggageCode + ':' + index];
          }

          break;
      }

      var baggageCounter = $scope.baggageCounter[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel][index] === $scope.maxCounter ? $scope.maxCounter - 1 : $scope.baggageCounter[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel][index];

      $scope.addBaggageToBasket();
      $scope.setSegmentPriceLists($scope.selectedBaggageLabel, index, baggageCounter);
    };

    $scope.selectAllBaggages = function(operation) {
      if (!profileHelper.isLoggedIn() && !$rootScope.isAuthenticated) {
        var dialogData = {
          onShowing: 'loginFromBaggage'
        };

        componentHelper.openDialog('/element/dialog/profile/main', event, dialogData);
        profile.logout();

        return false;
      }

      for (var index in $scope.baggageCounter[$scope.selectedPassengerPnrPosition][$scope.selectedBaggageLabel]) {
        if (index !== 'selectAll') {
          $scope.changeBaggageSegment(index, operation);
        }
      }
    };

    $scope.addBaggageToBasket = function() {
      $localStorage.cockpit.baskets[$rootScope.activeFilekey] = $localStorage.cockpit.baskets[$rootScope.activeFilekey] || {};

      var baggageTemp = {};
      angular.copy($scope.baggageSelected, baggageTemp);

      angular.forEach(baggageTemp, function(pnrPositionBaggage, pnrPosition) {
        angular.forEach(pnrPositionBaggage, function(passengerBaggage, baggageCode) {
          if ($filter('isEmpty')(passengerBaggage.segmentPriceList)) {
            delete baggageTemp[pnrPosition][baggageCode];
          }
        });

        if ($filter('isEmpty')(baggageTemp[pnrPosition])) {
          delete baggageTemp[pnrPosition];
        }
      });

      $localStorage.cockpit.baskets[$rootScope.activeFilekey].baggage = baggageTemp;
      $rootScope.$emit('refreshBasket');
    };

    $scope.getTotalPricePerLabel = function(pnrPosition, baggageLabel) {
      var baggageCodePrice = 0;
      var baggagesLabels = null;

      if ($rootScope.basket && $rootScope.basket.baggage && $rootScope.basket.baggage[pnrPosition]) {
        baggagesLabels = baggageHelper.getBaggageCodeLabel($rootScope.basket.baggage[pnrPosition]);
        angular.forEach(baggagesLabels[baggageLabel], function(baggage) {
          angular.forEach(baggage.segmentPriceList, function(segmentPriceList) {
            baggageCodePrice = baggageCodePrice + parseFloat(segmentPriceList.purchasePrice.value);
          })
        });
      }

      return $filter('number')(baggageCodePrice, 2);
    };

    $scope.getNumberOfAncillarySelectedPerPassenger = function(pnrPosition) {
      var numberOfBaggage = 0;

      angular.forEach($scope.baggageSelected[pnrPosition], function(baggageCode) {
        if (Object.keys(baggageCode.segmentPriceList).length) {
          numberOfBaggage += parseInt(Object.keys(baggageCode.segmentPriceList).length);
        }
      });

      return numberOfBaggage;
    };

    $scope.getNumberOfBaggagePerBaggageLabel = function(baggageLabel) {
      var baggageCounter = Object.assign({}, $scope.baggageCounter[$scope.selectedPassengerPnrPosition][baggageLabel]);
      delete baggageCounter.selectAll;
      var counter = $filter('toArray')(baggageCounter);

      return counter.reduce(function(a, b) {
        return a + b;
      }, 0);
    }

    $scope.displayHorizontalScrollIndicator = function(elementId, direction) {
      return domHelper.displayHorizontalScrollIndicator(elementId, direction);
    };

    $scope.displayVerticalScrollIndicator = function(elementId, direction) {
      return domHelper.displayVerticalScrollIndicator(elementId, direction);
    };

    $scope.redirectToCheckout = function(event) {
      $scope.close();
      basketHelper.redirectToCheckout(event);
    };
  }
]);
