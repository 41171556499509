cockpitApp.controller('profileRegistrationController', [
  '$rootScope', '$scope', '$mdSidenav', '$mdDialog', '$location', '$localStorage', '$filter', '$window', 'profile', 'gettextCatalog', 'authManager', 'navigationHelper', 'componentHelper', 'profileHelper', 'reCaptcha', '$timeout', 'bookingHelper', 'logsProvider',
  function($rootScope, $scope, $mdSidenav, $mdDialog, $location, $localStorage, $filter, $window, profile, gettextCatalog, authManager, navigationHelper, componentHelper, profileHelper, reCaptcha, $timeout, bookingHelper, logsProvider)
  {
    $scope.activeForm = 'login';

    $scope.errorStatus = [
      'loginFailed',
      'registrationFailed',
      'loggedOut',
      'passwordEmailFailed',
      'profileNotVerified',
      'profileNotExist',
      'loginFromSocialMediaError'
    ];

    $scope.successStatus = [
      'registrationSuccess',
      'passwordEmailSuccess'
    ];

    $scope.warningStatus = [
      'loginFromBasket',
      'loginToCheckout',
      'loginFromBaggage',
      'loginFromSeatMap',
      'loginFromSelectMeal',
      'loginFromFrequentFlyerNumber'
    ];

    $scope.loadingStatus = [
      'loading',
      'loadingFacebook',
      'loadingTwitter',
      'loadingGoogle'
    ];

    $scope.captchaIdRegister = null;
    $scope.captchaIdLogin = null;
    var reCaptchaLoadedRegisterListener = null;
    var reCaptchaLoadedLoginListener = null;

    $scope.displayReCaptchaLogin = function(origin) {
      $timeout(function() {
        if ($localStorage.cockpit.error.captcha.displayLoginCaptcha) {
          reCaptcha.resetCaptcha($scope.captchaIdLogin);

          if ($rootScope.reCaptchaLoaded) {
            $scope.captchaIdLogin = reCaptcha.displayCaptcha(origin);
          } else {
            reCaptchaLoadedLoginListener = $rootScope.$on('reCaptchaLoaded', function() {
              $scope.captchaIdLogin = reCaptcha.displayCaptcha(origin);
            });
          }
        }
      }, 400);
    };

    $scope.displayReCaptchaRegister = function(origin) {
      $timeout(function() {
        reCaptcha.resetCaptcha($scope.captchaIdRegister);

        if ($rootScope.reCaptchaLoaded) {
          $scope.captchaIdRegister = reCaptcha.displayCaptcha(origin);
        } else {
          reCaptchaLoadedRegisterListener = $rootScope.$on('reCaptchaLoaded', function() {
            $scope.captchaIdRegister = reCaptcha.displayCaptcha(origin);
          });
        }
      }, 400);
    };

    $scope.close = function() {
      $mdDialog.cancel();
    };

    $scope.getProfile = function() {
      return profileHelper.getProfile();
    };

    $scope.loginProfile = function() {
      $scope.status = 'loading';

      if ($scope.login.$valid) {
        $scope.login.$setSubmitted();

        var requestParams = {
          email: $scope.login.email.$viewValue,
          password: $scope.login.password.$viewValue,
          bookingsCredentials: bookingHelper.getBookingsCredentials(),
          locale: $rootScope.activeLocale
        };

        profile.login(requestParams).then(
          function successCallback (response) {
            if (response && response.status !== 500) {
              if (response.meta && response.meta.success) {
                $localStorage.cockpit.error.captcha.displayLoginCaptcha = false;
                reCaptcha.resetCaptcha($scope.captchaIdLogin);
                $scope.captchaIdLogin = undefined;

                if (response.data) {
                  navigationHelper.profileLoggedInPostAction(response.data);
                }

                $mdSidenav('menuMainSidenav').close();
                $mdDialog.cancel();
              } else {
                $scope.status = 'loginFailed';

                if (response.data && response.data.meta && response.data.meta.displayLoginCaptcha) {
                  $localStorage.cockpit.error.captcha.displayLoginCaptcha = response.data.meta.displayLoginCaptcha;
                  $scope.resetLoginCaptcha();
                }
              }
            } else {
              if (response.data && response.data.meta && response.data.meta.displayLoginCaptcha) {
                $localStorage.cockpit.error.captcha.displayLoginCaptcha = response.data.meta.displayLoginCaptcha;
                $scope.resetLoginCaptcha();
              }

              if (response.status === 500 && response.data && response.data.data && response.data.data.exception_code === 707) {
                $scope.status = 'profileNotVerified';
              } else if (response.status === 500 && response.data && response.data.data && response.data.data.exception_code === 705) {
                $scope.status = 'profileNotExist';
              } else {
                $scope.status = 'loginFailed';
              }
            }
          }, function errorCallback (response) {
            $scope.resetLoginCaptcha();
            $scope.status = 'loginFailed';
          }
        );
      }
    };

    $scope.resetLoginCaptcha = function() {
      if ($scope.captchaIdLogin) {
        $scope.captchaIdLogin = reCaptcha.resetCaptcha($scope.captchaIdLogin);
      } else {
        $scope.captchaIdLogin = reCaptcha.displayCaptcha($scope.reCaptchaLoginId);
      }

      $scope.reCaptchaValid.login = false;
    };

    $scope.submitLogin = function() {
      if ($localStorage.cockpit.error.captcha && $localStorage.cockpit.error.captcha.displayLoginCaptcha) {
        $scope.status = 'loading';

        var requestParams = {
          captchaResponse: grecaptcha.getResponse($scope.captchaIdLogin)
        };

        reCaptcha.verify(requestParams).then(
          function successCallback(response) {
            if (response && response.meta) {
              $scope.loginProfile();
            }
          }
        );
      } else {
        $scope.loginProfile();
      }
    };

    $scope.socialAuth = function(platform) {
      $window.open(cockpitApiUrl + cockpitApiProfileSocialAuthEndpoint + '/' + platform, '_self');
    };

    $scope.registerProfile = function(registerForm) {
      $scope.status = 'loading';

      if (registerForm.$valid) {
        registerForm.$setSubmitted();

        var requestParams = {
          email: registerForm.email.$viewValue,
          password: registerForm.password.$viewValue,
          password_confirmation: registerForm.confirmPassword.$viewValue,
          url: $location.absUrl(),
          locale: $rootScope.activeLocale
        };

        var captchaParams = {
          captchaResponse: grecaptcha.getResponse($scope.captchaIdRegister)
        };

        reCaptcha.verify(captchaParams).then(
          function successCallback(response) {
            if (response.meta && response.meta.success) {
              profile.register(requestParams).then(
                function successCallback (response) {
                  if (response.meta && response.meta.success) {
                    reCaptcha.resetCaptcha($scope.captchaIdRegister);
                    $scope.captchaIdRegister = undefined;
                    $scope.status = 'registrationSuccess';

                    var logRequestParams = {
                      interactionName: 'profileRegistration',
                      details: {
                        email: requestParams.email,
                        url: $location.absUrl(),
                        locale: $rootScope.activeLocale
                      },
                      locale: $rootScope.activeLocale
                    };

                    logsProvider.logInteraction(logRequestParams);
                  } else {
                    $scope.status = 'registrationFailed';
                    $scope.resetRegisterCaptcha();

                    if (response.data && response.data.data && response.data.data.exception_code === 702) {
                      $scope.error = 'email';
                    }
                  }
                }, function errorCallback () {
                  $scope.status = 'registrationFailed';
                  $scope.resetRegisterCaptcha();
                }
              );
            } else {
              $scope.status = 'registrationFailed';
              $scope.resetRegisterCaptcha();
            }
          }, function errorCallback () {
            $scope.status = 'registrationFailed';
            $scope.resetRegisterCaptcha();
          }
        );
      }
    };

    $scope.resetRegisterCaptcha = function() {
      reCaptcha.resetCaptcha($scope.captchaIdRegister);
      $scope.reCaptchaValid.register = false;
    };

    $scope.sendEmailResetPassword = function(passwordEmailForm) {
      $scope.status = 'loading';

      if (passwordEmailForm.$valid) {
        passwordEmailForm.$setSubmitted();

        var requestParams = {
          email: passwordEmailForm.email.$viewValue,
          locale: $rootScope.activeLocale
        };

        profile.sendEmailResetPassword(requestParams).then(
          function successCallback (response) {
            if (response && response.meta && response.meta.success) {
              $scope.status = 'passwordEmailSuccess';
            } else {
              $scope.status = 'passwordEmailFailed';
            }
          }, function errorCallback () {
            $scope.status = 'passwordEmailFailed';
          }
        );
      }
    };

    $scope.showForm = function(currentForm, email, origin) {
      if (currentForm) {
        if (email) {
          $scope.email = email;
        }

        if (origin !== 'welcomePage') {
          $timeout(function() {
            angular.element('form[name="' + currentForm + '"] input[name="email"]').focus();
          }, 400);
        }

        $scope.password = '';
        $scope.status = 'none';
        $scope.error = 'none';
        $scope.activeForm = currentForm;
      }
    };

    $scope.postAction = function(previousForm, loginForm) {
      if (previousForm && !$filter('isEmpty')(previousForm)) {
        var email = previousForm.email.$viewValue;

        switch ($scope.status) {
          case 'registrationSuccess':
          case 'passwordEmailSuccess':
            loginForm.$setPristine();
            $scope.showForm('login', email);
            break;
          case 'registrationFailed':
          case 'passwordEmailFailed':
            previousForm.$setPristine();
            $scope.showForm(previousForm.$name, email);
            break;
        }
      }
    };

    $scope.displayErrorMessages = function() {
      return $scope.errorStatus.indexOf($scope.status) > -1;
    };

    $scope.displaySuccessMessages = function() {
      return $scope.successStatus.indexOf($scope.status) > -1;
    };

    $scope.displayWarningMessages = function() {
      return $scope.warningStatus.indexOf($scope.status) > -1;
    };

    $scope.loading = function() {
      return $scope.loadingStatus.indexOf($scope.status) > -1;
    };

    $scope.displayLoginReCaptcha = function() {
      return !$scope.loading() && $localStorage.cockpit.error.captcha.displayLoginCaptcha;
    };

    var loginFromResetPasswordListener = $rootScope.$on('loginFromResetPassword', function(event, email) {
      $scope.showForm('login', email);
    });

    var registerPageListener = $rootScope.$on('registerPage', function() {
      $scope.showForm('register');
    });

    var loginFromSocialMediaErrorListener = $rootScope.$on('loginFromSocialMediaError', function(event, data) {
      $scope.status = 'loginFromSocialMediaError';
      $scope.platformLoginError = data.platform;
    });

    var loginFromVerifyListener = $rootScope.$on('loginFromVerify', function(event, email) {
      $scope.showForm('login', email);
    });

    var profileLoggedOutListener = $rootScope.$on('profileLoggedOut', function(event, email) {
      $scope.showForm('login', email);
      $scope.status = 'loggedOut';
    });

    $scope.$on('$destroy',
      loginFromResetPasswordListener,
      registerPageListener,
      loginFromSocialMediaErrorListener,
      loginFromVerifyListener,
      profileLoggedOutListener,
      reCaptchaLoadedRegisterListener,
      reCaptchaLoadedLoginListener
    );
  }
]);
