cockpitApp.directive('offsetTop', ['$rootScope',
  function($rootScope) {
    return {
      link: function(scope, element, attrs) {
        var lastScrollTop = 0;
        
        scope.$watch(function() {
          var scrollTop = element.scrollTop();
          var limit = scrollTop >= lastScrollTop ? -50 : 50;
          $rootScope.isNavbarFloating = scrollTop > angular.element('.background-header').height() + limit;
          lastScrollTop = scrollTop;
        });
      }
    }
  }
]);
