cockpitApp.filter('translateSeatCharacteristic', ['gettextCatalog', function(gettextCatalog) {
  return function (value) {
    switch (value) {
      case 'ADJACENT_TO_LAVATORY_SEAT':
        return gettextCatalog.getString('Adjacent to lavatory seat');
      case 'ADJACENT_TO_GALLEY_SEAT':
        return gettextCatalog.getString('Adjacent to galley seat');
      case 'NO_SEAT_LAVATORY':
        return gettextCatalog.getString('Lavatory');
      case 'NO_SEAT_GALLEY':
        return gettextCatalog.getString('Galley');
      case 'NO_FACILITY_SEAT':
        return gettextCatalog.getString('No facility seat');
      case 'QUIET_ZONE_SEAT':
        return gettextCatalog.getString('Quiet zone seat');
      case 'ADULT_WITH_INFANT_SEAT':
        return gettextCatalog.getString('Adult with infant seat');
      case 'AISLE_SEAT':
        return gettextCatalog.getString('Aisle seat');
      case 'BASSINET_FACILITY_SEAT':
        return gettextCatalog.getString('Bassinet facility seat');
      case 'BLOCKED_FOR_PREFERRED_PASSENGER_SEAT':
        return gettextCatalog.getString('Blocked for preferred passenger seat');
      case 'BULKHEAD_SEAT':
        return gettextCatalog.getString('Bulkhead seat');
      case 'CHARGEABLE_SEAT':
        return gettextCatalog.getString('Chargeable seat');
      case 'CREW_SEAT':
        return gettextCatalog.getString('Crew seat');
      case 'ECONOMY_COMFORT_SEAT':
        return gettextCatalog.getString('Economy comfort seat');
      case 'EXIT_ROW_SEAT':
        return gettextCatalog.getString('Exit row seat');
      case 'FRONT_OF_CABIN_CLASS_SEAT':
        return gettextCatalog.getString('Front of cabin class seat');
      case 'PET_CABIN':
        return gettextCatalog.getString('Pet cabin');
      case 'LEG_SPACE_SEAT':
        return gettextCatalog.getString('Leg space seat');
      case 'OFFERED_LAST_SEAT':
        return gettextCatalog.getString('Offered last seat');
      case 'OVERWING_SEAT':
        return gettextCatalog.getString('Overwing seat');
      case 'PREFERENTIAL_SEAT':
        return gettextCatalog.getString('Preferential seat');
      case 'RESTRICTED_SEAT':
        return gettextCatalog.getString('Restricted seat');
      case 'RESTRICTED_RECLINE_SEAT':
        return gettextCatalog.getString('Restricted recline');
      case 'WINDOW_SEAT':
        return gettextCatalog.getString('Window seat');
      case 'WINDOW_SEAT_WITHOUT_WINDOW':
        return gettextCatalog.getString('Window seat without window');
      case 'HANDICAPPED_SEAT':
        return gettextCatalog.getString('Handicapped seat');
      case 'NOT_ALLOWED_FOR_INFANT_SEAT':
        return gettextCatalog.getString('Not allowed for infant seat');
      case 'NOT_ALLOWED_FOR_MEDICAL_SEAT':
        return gettextCatalog.getString('Not allowed for medical seat');
      case 'NOT_ALLOWED_FOR_UNACCOMPANIED_MINOR_SEAT':
        return gettextCatalog.getString('Not allowed for unaccompanied minor seat');
      case 'UNACCOMPANIED_MINORS_SEAT':
        return gettextCatalog.getString('Unaccompanied minor seat');
      case 'NO_CHILD_SEAT':
        return gettextCatalog.getString('No child seat');
      case 'RIGHT_SIDE_OF_AIRCRAFT':
        return gettextCatalog.getString('Right side of aircraft');
      case 'LEFT_SIDE_OF_AIRCRAFT':
        return gettextCatalog.getString('Left side of aircraft');
      case 'WINDOW_AND_AISLE_SEAT':
        return gettextCatalog.getString('Window and aisle seat');
      case 'ELECTRONIC_CONNECTION_SEAT':
        return gettextCatalog.getString('Seat with power outlet');
      case 'LEG_REST_AVAILABLE':
        return gettextCatalog.getString('Leg rest available');
      case 'INDIVIDUAL_VIDEO_SCREEN_WITH_CHOISE_OF_MOVIE_SEAT':
      case 'INDIVIDUAL_VIDEO_SCREEN_WITH_CHOISE_OF_MOVIE_GAMES_SEAT':
        return gettextCatalog.getString('Individual video screen included');
      case 'INDIVIDUAL_AIRPHONE':
        return gettextCatalog.getString('Air phone available');
      case 'VACANT_SEAT':
        return gettextCatalog.getString('Seat to be left vacant');
      default:
        return value;
    }
  }
}]);
