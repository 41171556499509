cockpitApp.filter('passengerType', function() {
  return function (typeCode, contains) {
    contains = contains || null;

    var re = new RegExp("^C(\\d{2})$");

    if ((match = re.exec(typeCode)) !== null) {
      var match = parseInt(match[1]);

      if (match >= 0 && match < 3) {
        typeCode = 'INF';
      }

      if (match >= 3 && match < 12) {
        typeCode = 'CHD';
      }
    }

    if (contains != null) {
      return $.inArray(typeCode, contains.split(',')) != -1;
    }

    return typeCode;
  }
});
