cockpitApp.filter('getBookings', [
  '$filter', '$rootScope', '$localStorage', 'profileHelper', 'bookingHelper',
  function($filter, $rootScope, $localStorage, profileHelper, bookingHelper) {
    return function(bookings, tab) {
      var filteredBookings = [];
      bookings = bookingHelper.getBookings();

      if (!$filter('isEmpty')(bookings)) {
        angular.forEach(bookings, function(booking) {
          var addBookingToFilteredList = false;
          var isPast = $filter('isPast')(booking.arrivalDateTime, booking.arrivalTimezone);

          switch (true) {
            case isPast && tab === 'past':
            case !isPast && tab === 'active':
            case !tab:
              addBookingToFilteredList = true;
              break;
          }

          if (addBookingToFilteredList) {
            var brandTemp = $rootScope.subdomain;

            if ($rootScope.myCockpitIframe) {
              brandTemp = $rootScope.iframePayload.source.split('-')[1];
            }

            if (brandTemp === 'mycockpit' || brandTemp === booking.branding || (booking.branding === null && booking.isLowCost)) {
              filteredBookings.push(booking);
            }
          }
        });

        filteredBookings = tab === 'past' ? $filter('orderBy')(filteredBookings, 'arrivalDateTime', true) : $filter('orderBy')(filteredBookings, 'departureDateTime');
      }

      return filteredBookings;
    }
  }
]);
