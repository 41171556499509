cockpitApp.filter('addThreeDots', function () {
  return function (string, limit) {
    var dots = "...";
    
    if (limit && string.length > limit) {
      string = string.substring(0, limit) + dots;
    }
  
    return string;
  }
});
