cockpitApp.controller('masterController', [
  '$rootScope', '$scope', '$location', '$window', '$filter', 'navigationHelper', 'gettextCatalog', 'bookingProvider', 'componentHelper', 'profileHelper', '$mdMedia', 'iframe', 'bookingHelper', 'authManager', 'basketHelper', 'logsProvider', '$routeParams',
  function ($rootScope, $scope, $location, $window, $filter, navigationHelper, gettextCatalog, bookingProvider, componentHelper, profileHelper, $mdMedia, iframe, bookingHelper, authManager, basketHelper, logsProvider, $routeParams)
  {
    $scope.basketHelper = basketHelper;

    $scope.loginJwt = function() {
      var jwtPayload = $rootScope.iframePayload;

      if ($rootScope.myCockpitIframe) {
        iframe.login(jwtPayload).then(
          function successCallback(response) {
            if (response && response.status !== 500 && response.meta && response.meta.success && response.data) {
              // Invia iframe login, use only bookings already related to the profile
              bookingHelper.setBookingsNames(response.data.profileBookings);
              $rootScope.profile = response.data;
              profileHelper.updateSessionProfile();

              var logRequestParams = {
                interactionName: 'ProfileLoginViaIframe',
                language: $rootScope.activeLocale,
                details: {
                  token: $routeParams.jwt
                }
              };

              logsProvider.logInteraction(logRequestParams);
              authManager.authenticate();

              navigationHelper.redirectWithLocale('/overview/active');
              componentHelper.slideUpSplashScreen(2000);
            } else {
              $scope.redirectIframe();
            }
          },
          function errorCallback(response) {
            $scope.redirectIframe();
          }
        );
      }
    };

    $scope.redirectIframe = function() {
      window.location.assign($location.url());
    };

    $rootScope.isPage = function(page) {
      return $location.path().indexOf(page) > -1;
    };

    $rootScope.isWelcomePage = function() {
      var path = $location.path();
      var pathLength = path.replace(/\//g, '').length;

      return path.indexOf('welcome') > -1 || pathLength === 2 || pathLength === 0;
    };

    $rootScope.displayFloatingAdd = function() {
      return !$rootScope.myCockpitIframe && !$rootScope.isPage('checkout') && !$rootScope.isWelcomePage() && !basketHelper.displayGoToCheckoutOnBottomSection();
    };

    $scope.show = function(menu) {
      return navigationHelper.show(menu);
    };

    $scope.isOverview = function() {
      return $location.path().indexOf('overview') > -1;
    };

    $scope.displayFlightOverviewTabs = function() {
      return !$rootScope.isPage('register');
    };

    $scope.updateBooking = function(origin) {
      origin = origin || null;
      bookingProvider.updateBooking($scope.booking.filekey, $scope.booking.searchName, true, 'notify', origin);
    };

    $scope.travelAgency = function() {
      return $rootScope.travelAgency && $rootScope.travelAgency.data;
    };

    $scope.brandLogoRedirect = function() {
      if ($scope.show('register')) {
        $window.open($window.location.origin + '/', '_blank');
      } else if ($rootScope.booking && $rootScope.isInviaBrand() && $rootScope.isPage('checkout')) {
        navigationHelper.redirectWithLocale('/details/:' + $rootScope.activeFilekey + '/:' + $rootScope.activeLastname);
      } else {
        navigationHelper.redirectWithLocale('/');
      }
    };

    $scope.openAddBookingDialog = function(event) {
      var dialogData = {
        onShowing: 'addBookingDialogOpening'
      };

      componentHelper.openDialog('/element/dialog/add', event, dialogData);
    };

    $scope.openLoginDialog = function(event) {
      componentHelper.openDialog('/element/dialog/profile/main', event);
    };

    $scope.isLoggedIn = function() {
      return profileHelper.isLoggedIn();
    };

    $scope.isActionDisabled = function() {
      return bookingHelper.isActionDisabled();
    };

    $scope.redirectWithLocale = function(path) {
      navigationHelper.redirectWithLocale(path);
    };

    $rootScope.isFunctionalityEnabled = function(functionality, segment) {
      if (!($rootScope.functionalities && $rootScope.activeFilekey && $rootScope.functionalities[$rootScope.activeFilekey] && $rootScope.functionalities[$rootScope.activeFilekey][functionality])) {
        return false;
      }

      var scopeFunctionality = $rootScope.functionalities[$rootScope.activeFilekey][functionality];
      var numberOfSegments = $filter('count')(scopeFunctionality);
      var isFunctionalityTrue = scopeFunctionality === true;
      var isFunctionalitySegmentEnabled = false;
      var functionalityArray = $filter('toArray')(scopeFunctionality);

      if (!Array.isArray(functionalityArray)) {
        return isFunctionalityTrue;
      }

      if (segment && segment.segmentExtendedNumber && typeof scopeFunctionality[segment.segmentExtendedNumber] !== 'undefined') {
        isFunctionalitySegmentEnabled = scopeFunctionality[segment.segmentExtendedNumber];
      } else {
        isFunctionalitySegmentEnabled = functionalityArray.some(function (functionalitySegmentEnabled, index) {
          return functionalitySegmentEnabled === true || (index === 0 && functionalitySegmentEnabled.departure) || (index === numberOfSegments - 1 && functionalitySegmentEnabled.arrival);
        });
      }

      return isFunctionalitySegmentEnabled;
    };

    $rootScope.isTravelAgencyFunctionalityEnabled = function(functionality) {
      return $rootScope.travelAgency && $rootScope.travelAgency.data && $rootScope.travelAgency.data.functionalities && $rootScope.travelAgency.data.functionalities[functionality];
    };

    $rootScope.isShoppingEnabled = function() {
      return basketHelper.isShoppingEnabled();
    };

    $rootScope.isLocalStorageFull = function() {
      return bookingProvider.isLocalStorageFull();
    };

    $scope.backToBookingDetails = function() {
      navigationHelper.redirectWithLocale('/details/:' + $scope.activeFilekey + '/:' + $scope.activeLastname);
    };

    $rootScope.getPreferenceMessage = function(name) {
      var preference = null;

      if ($rootScope.isFunctionalityEnabled(name + 'Enabled')) {
        preference = $rootScope.brandPreference(name, $rootScope.activeLocale);
      } else if ($rootScope.isTravelAgencyFunctionalityEnabled(name + 'Enabled')) {
        preference = $rootScope.subdomainPreference(name, $rootScope.activeLocale);
      }

      return preference && $filter('isTravelAgencyPreferenceValid')(preference) ? preference.value : false;
    };

    $rootScope.brandPreference = function(name, locale) {
      var preference = $rootScope.preferences && $rootScope.preferences[$rootScope.activeFilekey] && $rootScope.preferences[$rootScope.activeFilekey][name];

      return locale && preference ? preference[locale] : preference;
    };

    $rootScope.subdomainPreference = function(name, locale) {
      var preference = $rootScope.travelAgency && $rootScope.travelAgency.data && $rootScope.travelAgency.data.preferences && $rootScope.travelAgency.data.preferences[name];

      return locale && preference ? preference[locale] : preference;
    };

    $rootScope.isInviaPortal = function(company) {
      return company === 'Invia Flights Germany GmbH';
    };

    $rootScope.isInviaBrand = function () {
      const inviaBrands = ['airlinedirect', 'billigflug', 'billigfluege', 'fluege', 'flug'];

      return inviaBrands.includes($rootScope.booking.branding);
    };

    $rootScope.getSalutation = function(salutation) {
      return $filter('translateSalutation')(salutation);
    };

    $rootScope.getPassengerDisplayName = function(passenger) {
      if (passenger) {
        var passengerSalutation = passenger.salutation ? $filter('translateSalutation')(passenger.salutation, true) : '';

        return passengerSalutation + ' ' + passenger.firstname + ' ' + passenger.lastname;
      }
    };

    $rootScope.gaTracking = function(category, action, label) {
      try {
        var interactionName = label + '_' + $rootScope.source + '_' + $rootScope.booking.travelAgencyNumber + '_' + $rootScope.subdomain + '_' + $rootScope.booking.branding;

        ga('send', 'event', {
          eventCategory: category,
          eventAction: action,
          eventLabel: interactionName
        });

        return true;
      } catch (e) {
        return false;
      }
    };
  }
]);
