cockpitApp.controller('cookieBarController', [
  '$rootScope', '$scope', '$localStorage', 'navigationHelper',
  function($rootScope, $scope, $localStorage, navigationHelper)
  {
    $scope.accept = function() {
      var cWrapper = $('.cookies-wrapper');

      cWrapper.animate({
        'margin-top': -cWrapper.height()
      }, 500, function() {
        $localStorage.cockpit.cookies.read = true;
      });
    };

    $scope.read = function() {
      return $localStorage.cockpit.cookies.read;
    };
  
    $scope.show = function(menu) {
      return navigationHelper.show(menu);
    };
  }
]);
