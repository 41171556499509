cockpitApp.controller('feedbackDialogController', [
  '$scope', '$rootScope', '$mdDialog', '$location', '$filter', 'gettextCatalog', 'feedback', 'bookingHelper',
  function($scope, $rootScope, $mdDialog, $location, $filter, gettextCatalog, feedback, bookingHelper) {
    $scope.score = null;
    $scope.travelAgencyText = "";
    $scope.email = $rootScope.profile && $rootScope.profile.data && $rootScope.profile.data.email;

    $scope.onRating = function(rating) {
      $scope.score = rating;
    };

    $scope.cancel = function() {
      $mdDialog.cancel();
    };

    $scope.loadCategoryType = function() {
      var branding = ($rootScope.booking && $rootScope.booking.branding) || subdomain;
      branding = branding.replace(/-/g, ' ');
      $scope.categories = {
        commentaryMycockpit: gettextCatalog.getString('Commentary / question about MyCockpit')
      };

      var bookingsGroupedByBrand = $filter('groupBy')(bookingHelper.getBookings(), 'branding');

      if ($filter('count')(bookingsGroupedByBrand) === 1 || (branding && branding !== 'mycockpit')) {
        $scope.categories.questionTrip = gettextCatalog.getString('Question to {{branding}} about my trip', {'branding': $filter('capitalize')(branding)});
      }

      $scope.categories.technicalProblem = gettextCatalog.getString('Technical problem');

      return $scope.categories;
    };

    $scope.send = function() {
      $scope.feedbackForm.$setSubmitted();
      $scope.feedbackForm.category.$setDirty();

      if ($scope.feedbackForm.$valid) {
        $scope.status = 'loading';

        var content = {
          url: $location.absUrl(),
          category: $scope.feedbackForm.category.$viewValue,
          categoryLabel: $scope.categories[$scope.feedbackForm.category.$viewValue],
          message: $scope.feedbackForm.textArea && $scope.feedbackForm.textArea.$viewValue,
          score: $scope.score,
          email: $scope.feedbackForm.email && $scope.feedbackForm.email.$viewValue,
          travelAgency: $scope.getTravelAgencyData()
        };

        feedback.sendFeedback(content).then(
          function successCallback (response) {
            if (response.meta && response.meta.success) {
              $scope.status = 'success';
            } else {
              $scope.status = 'error';
            }
          },
          function errorCallback () {
            $scope.status = 'error';
          }
        );
      }
    };

    $scope.getTravelAgencyData = function() {
      var travelAgency = null;

      if (subdomain === 'mycockpit') {
        var bookings = bookingHelper.getBookings();
        var booking = $rootScope.booking || (!$filter('isEmpty')(bookings) && bookings[Object.keys(bookings)[0]]);
        travelAgency = booking.travelAgency;
      }

      return travelAgency;
    };
  }
]);
