cockpitApp.controller('profileRegistrationDialogController', [
  '$rootScope', '$scope', 'bookingProvider', '$localStorage', '$mdDialog',
  function ($rootScope, $scope, bookingProvider, $localStorage, $mdDialog)
  {
    $scope.reCaptchaLoginId = 'reCaptchaLoginDialog';
    $scope.reCaptchaRegisterId = 'reCaptchaRegisterDialog';
    $scope.reCaptchaValid = {
      'login': false,
      'register': false
    };

    window.reCaptchaLoginCallback = function() {
      $scope.reCaptchaValid.login = true;
      $scope.$apply();
    };

    window.reCaptchaLoginExpiredCallback = function() {
        $scope.reCaptchaValid.login = false;
        $scope.$apply();
    };

    window.reCaptchaRegisterCallback = function() {
      $scope.reCaptchaValid.register = true;
      $scope.$apply();
    };

    window.reCaptchaRegisterExpiredCallback = function() {
        $scope.reCaptchaValid.register = false;
        $scope.$apply();
    };

    $scope.close = function() {
      $mdDialog.cancel();
    };

    var loginFromBasketListener = $rootScope.$on('loginFromBasket', function(event) {
      $scope.status = 'loginFromBasket';
    });

    var loginToCheckoutListener = $rootScope.$on('loginToCheckout', function(event) {
      $scope.status = 'loginToCheckout';
    });

    var loginFromSelectMealListener = $rootScope.$on('loginFromSelectMeal', function(event) {
      $scope.status = 'loginFromSelectMeal';
    });

    var loginFromBaggageListener = $rootScope.$on('loginFromBaggage', function(event) {
      $scope.status = 'loginFromBaggage';
    });

    var loginFromSeatMapListener = $rootScope.$on('loginFromSeatMap', function(event) {
      $scope.status = 'loginFromSeatMap';
    });

    var loginFromFrequentFlyerNumberListener = $rootScope.$on('loginFromFrequentFlyerNumber', function(event) {
      $scope.status = 'loginFromFrequentFlyerNumber';
    });

    $scope.$on('$destroy',
      loginFromBasketListener,
      loginToCheckoutListener,
      loginFromSelectMealListener,
      loginFromBaggageListener,
      loginFromSeatMapListener,
      loginFromFrequentFlyerNumberListener
    );
  }
]);
