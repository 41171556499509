cockpitApp.filter('distanceDisplay', [
  '$rootScope', 'gettextCatalog',
  function($rootScope, gettextCatalog) {
    return function(distance) {
      if (distance) {
        switch ($rootScope.activeLocale) {
          case 'de_DE':
          case 'fr_FR':
          case 'es_ES':
            return gettextCatalog.getPlural(distance, '{{distance}} kilometer', '{{distance}} kilometers', { distance: parseInt(distance * 1.60934) });
          case 'en_GB':
          case 'en_US':
          default:
            return gettextCatalog.getPlural(distance, '{{distance}} mile', '{{distance}} miles', { distance: distance });
        }
      }
    }
  }
]);
