cockpitApp.factory('validateOauthToken', [
  '$cookies', '$rootScope',
  function ($cookies, $rootScope) {
    var oauthService = {};
  
    oauthService.isTokenValid = function () {
      if ($rootScope.myCockpitIframe) {
        return true;
      } else {
        if ($cookies.get('token')) {
          var token = JSON.parse($cookies.get('token'));
          if (token.expires_in) {
            var currentDateTime = Math.floor(Date.now() / 1000);
    
            if (currentDateTime < token.expires_in) {
              return true;
            } else {
              $cookies.remove('token');
            }
            return false;
          }
    
          $cookies.remove('token');
    
          return false;
        }
      }
  
      return false;
    };
  
    return oauthService;
  }
]);
