cockpitApp.controller('sidenavTransfersController', [
  '$rootScope', '$scope', '$q', '$mdSidenav', '$filter', '$timeout', '$localStorage', '$mdComponentRegistry', '$mdSelect', '$mdMedia', 'gettextCatalog', 'logsProvider', 'markups', 'basketHelper', 'transfers', 'segmentHelper',
  function($rootScope, $scope, $q, $mdSidenav, $filter, $timeout, $localStorage, $mdComponentRegistry, $mdSelect, $mdMedia, gettextCatalog, logsProvider, markups, basketHelper, transfers, segmentHelper)
  {
    $scope.$watch(function() {
      return $mdSidenav('sidenavTransfers').isOpen();
    }, function(newValue) {
      $rootScope.isSideNavOpen = newValue;

      if (!$rootScope.isSideNavOpen) {
        $scope.resetTransfersSidenav();
      }
    });

    $scope.$watch(function() {
      return $rootScope.isLargeScreen;
    }, function(isLargeScreen) {
      $mdComponentRegistry.when('sidenavTransfersMobile').then(function(sidenavTransfersMobile) {
        if (!isLargeScreen && $scope.status === 'transfersConnectionsSuccess') {
          $scope.openTransfersMobileSidenav();
        } else {
          $scope.close('mobile');
        }
      });
    });

    $rootScope.$on('sidenavTransfers:open', function(event, segment, transferPosition, transfer) {
      $scope.status = 'loading';
      $scope.transferPosition = transferPosition;
      $scope.segment = segment;
      $scope.resetTransfersSidenav(transferPosition);
      $scope.getInitPassenger(transferPosition);
      $scope.preFillConnection(transfer);
      $scope.logTransferInteraction('upsell-transfer-2-opened');
      $scope.status = 'success';
    });

    $scope.resetTransfersSidenav = function(transferPosition) {
      $scope.status = undefined;
      $scope.connectionAlreadyInBasket = false;
      $scope.offerList = [];
      $scope.connectionTabSelectedIndex = {};
      $scope.passengerList = {};
      $scope.passengerListTransfers = {};
      $scope.selectedPassengers = {};
      $scope.selectedPassenger = null;
      $scope.selectedConnection = {};
      $scope.selectedDepartureStation = undefined;
      $scope.selectedArrivalStation = undefined;
      $scope.passengersHaveTransfers = false;
      $scope.minDate = new Date(moment().year(), moment().month(), moment().date());
      $scope.transfersForm.$setPristine();

      if ($rootScope.booking && $rootScope.booking.segments) {
        $scope.isFirstTransfer = transferPosition === 'first';
        $scope.isLastTransfer = transferPosition === 'last';
      }

      $scope.transferDate = $scope.isFirstTransfer ? new Date($scope.segment.departureDateTime) : new Date($scope.segment.arrivalDateTime);
    };

    $scope.getInitPassenger = function(transferPosition) {
      $scope.segmentPassengersTransfers = segmentHelper.getSegmentPassengersTransfer($scope.segment.passengers, transferPosition);

      angular.forEach($scope.segment.passengers, function(segmentPassenger) {
        if (segmentPassenger.passengerTypeCode !== 'INF') {
          var passengerPayload = {
            salutation: $filter('getSalutation')(segmentPassenger),
            firstname: segmentPassenger.firstname,
            lastname: segmentPassenger.lastname,
            pnrPosition: segmentPassenger.pnrPosition
          };

          if (!$scope.segmentPassengersTransfers[segmentPassenger.pnrPosition]) {
            $scope.passengerList[segmentPassenger.pnrPosition] = passengerPayload;
          } else {
            $scope.passengersHaveTransfers = true;
            $scope.passengerListTransfers[segmentPassenger.pnrPosition] = passengerPayload;
            $scope.passengerListTransfers[segmentPassenger.pnrPosition]['transfer'] = $scope.segmentPassengersTransfers[segmentPassenger.pnrPosition];
          }
        }

      });
    };

    $scope.preFillConnection = function(transferBasket) {
      // if connection is already in the basket, pre-fill the form, else select all passengers
      if ($localStorage.cockpit.baskets[$rootScope.activeFilekey] && $localStorage.cockpit.baskets[$rootScope.activeFilekey].transfers) {
        var bookingBasketTransfers = $localStorage.cockpit.baskets[$rootScope.activeFilekey].transfers;
        var connectionFilters = {
          'segmentDepartureAirportCode': $scope.segment.departureAirportCode,
          'segmentArrivalAirportCode': $scope.segment.arrivalAirportCode
        };

        if (transferBasket) {
          connectionFilters.departureStationCode = transferBasket.departureStationCode;
          connectionFilters.arrivalStationCode = transferBasket.arrivalStationCode;
        } else {
          if ($scope.isFirstTransfer) {
            connectionFilters.transferDepartureAirportCode = null;
          } else if ($scope.isLastTransfer) {
            connectionFilters.transferArrivalAirportCode = null;
          }
        }

        var connection = $filter('filter')($filter('toArray')(bookingBasketTransfers), connectionFilters)[0];

        var bindFirstTransfer = $scope.isFirstTransfer && connection && connection.segmentDepartureAirportCode === connection.transferArrivalAirportCode;
        var bindLastTransfer = $scope.isLastTransfer && connection && connection.segmentArrivalAirportCode === connection.transferDepartureAirportCode;

        if (connection && connection.id && (bindFirstTransfer || bindLastTransfer)) {
          $scope.connectionAlreadyInBasket = true;
          $scope.selectedConnection = angular.copy($localStorage.cockpit.baskets[$rootScope.activeFilekey].transfers[connection.id]);
          $scope.selectedDepartureStation = {
            stationCode: $scope.selectedConnection.departureStationCode,
            stationName: $scope.selectedConnection.departureStationName,
            stationType: $scope.selectedConnection.departureStationType
          };
          $scope.selectedArrivalStation = {
            stationCode: $scope.selectedConnection.arrivalStationCode,
            stationName: $scope.selectedConnection.arrivalStationName,
            stationType: $scope.selectedConnection.arrivalStationType
          };
          $scope.transferDate = new Date($scope.selectedConnection.departureDateTime);

          angular.forEach(connection.passengers, function(passenger, pnrPosition) {
            $scope.toggle(pnrPosition);
          });

          // need timeout to have the transferDate valid in the form
          $timeout(function() {
            $scope.getConnections();
          }, 50);
        } else {
          $scope.toggleAll();
        }
      } else {
        $scope.toggleAll();
      }
    };

    $scope.setConnectionTab = function(connectionId, connectionIndex, tabIndex) {
      if ($scope.connectionTabSelectedIndex[connectionIndex] === tabIndex) {
        $scope.connectionTabSelectedIndex[connectionIndex] = 0;

        $timeout(function() {
          angular.element('#connection-' + connectionIndex + ' #md-tabs-' + connectionIndex + ' md-tab-item').first().click();
        }, 50);
      } else {
        $scope.connectionTabSelectedIndex[connectionIndex] = tabIndex;
      }
    };

    $scope.selectConnection = function(connectionId, connection, event) {
      var firstCarrierConnection = Object.keys(connection.timetable).sort()[0];

      // If basket has a transfer with same departure station, arrival station and departure date, replace it
      if ($localStorage.cockpit.baskets[$rootScope.activeFilekey] && $localStorage.cockpit.baskets[$rootScope.activeFilekey].transfers) {
        var connectionIdSplit = connectionId.split('-');
        var connectionDate = connectionIdSplit[3] + '-' + connectionIdSplit[4] + '-' + connectionIdSplit[5];

        angular.forEach($localStorage.cockpit.baskets[$rootScope.activeFilekey].transfers, function(transfer, transferId) {
          var transferIdSplit = transferId.split('-');
          var transferDate = connectionIdSplit[3] + '-' + connectionIdSplit[4] + '-' + connectionIdSplit[5];

          if (connectionIdSplit[1] === transferIdSplit[1] && connectionIdSplit[2] === transferIdSplit[2] && connectionDate === transferDate) {
            $scope.removeTransferConnectionsFromBasket(transferId, event);
          }
        });
      }

      $scope.selectedConnection = {
        id: connectionId,
        marketingCarrierCode: connection.carrierCode,
        marketingCarrierName: connection.carrierName,
        marketingCarrierTerms: connection.carrierTerms,
        departureStationCode: connection.departureStationCode,
        departureStationName: connection.departureStationName,
        departureStationType: connection.departureStationType,
        arrivalStationCode: connection.arrivalStationCode,
        arrivalStationName: connection.arrivalStationName,
        arrivalStationType: connection.arrivalStationType,
        segmentNumber: $scope.segment.segmentExtendedNumber,
        segmentDepartureAirportCode: $scope.segment.departureAirportCode,
        segmentArrivalAirportCode: $scope.segment.arrivalAirportCode,
        transferDepartureAirportCode: $scope.isLastTransfer ? $scope.segment.arrivalAirportCode : null,
        transferArrivalAirportCode: $scope.isFirstTransfer ? $scope.segment.departureAirportCode : null,
        departureDateTime: connection.timetable[firstCarrierConnection].departureDateTime,
        arrivalDateTime: connection.timetable[firstCarrierConnection].arrivalDateTime,
        ticketsValidityInHours: connection.ticketsValidityInHours,
        pax: $filter('count')(connection.passengers),
        type: 'PNOS',
        currency: 'EUR',
        passengers: connection.passengers
      };

      $localStorage.cockpit.baskets[$rootScope.activeFilekey] = $localStorage.cockpit.baskets[$rootScope.activeFilekey] || {};
      $localStorage.cockpit.baskets[$rootScope.activeFilekey].transfers = $localStorage.cockpit.baskets[$rootScope.activeFilekey].transfers || {};
      $localStorage.cockpit.baskets[$rootScope.activeFilekey].transfers[connectionId] = angular.copy($scope.selectedConnection);
      $rootScope.$emit('refreshBasket');

      $scope.logTransferInteraction('upsell-transfer-5-selected');
    };

    $scope.removeTransferConnectionsFromBasket = function(connectionId, event) {
      if ($scope.selectedConnection && $scope.selectedConnection.passengers) {
        angular.forEach($scope.selectedConnection.passengers, function(passenger, pnrPosition) {
          basketHelper.removeTransferConnectionFromBasket(connectionId, pnrPosition, event);
        });
      }

      $scope.selectedConnection = {};
    };

    $scope.logTransferInteraction = function(interactionName, details) {
      if (!$scope.connectionAlreadyInBasket) {
        var params = {
          interactionName: interactionName,
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          locale: $rootScope.activeLocale
        };

        switch (interactionName) {
          case 'upsell-transfer-2-opened':
            params.details = {
              segmentDepartureAirportCode: $scope.segment.departureAirportCode,
              segmentArrivalAirportCode: $scope.segment.arrivalAirportCode,
              transferPosition: $scope.transferPosition
            };
            break;
          case 'upsell-transfer-3-search':
          case 'upsell-transfer-4-result':
            params.details = details;
            break;
          case 'upsell-transfer-5-selected':
          case 'upsell-transfer-6-basket':
            params.details = {
              departureDateTime: $scope.selectedConnection.departureDateTime,
              arrivalDateTime: $scope.selectedConnection.arrivalDateTime,
              departureStationCode: $scope.selectedConnection.departureStationCode,
              arrivalStationCode: $scope.selectedConnection.arrivalStationCode,
              marketingCarrierCode: $scope.selectedConnection.marketingCarrierCode,
              pax: $scope.selectedConnection.pax,
              segmentArrivalAirportCode: $scope.selectedConnection.segmentArrivalAirportCode,
              segmentDepartureAirportCode: $scope.selectedConnection.segmentDepartureAirportCode,
              transferArrivalAirportCode: $scope.selectedConnection.transferArrivalAirportCode,
              transferDepartureAirportCode: $scope.selectedConnection.transferDepartureAirportCode
            };
            break;
        }

        logsProvider.logInteraction(params);
      }
    };

    $scope.selectPassenger = function(passenger) {
      if (passenger && $scope.selectedPassenger !== passenger) {
        $scope.selectedPassenger = passenger;
        $scope.openTransfersMobileSidenav();
        $scope.isPassengerOffersOpen = true;
        $scope.status = 'success';
      }
    };

    $scope.openTransfersMobileSidenav = function() {
      if (!$scope.isLargeScreen) {
        if ($mdSidenav('sidenavTransfersMobile').isOpen()) {
          // TODO: remove jQuery
          angular.element('#sidenav-transfers-mobile').animate({
            'marginLeft': '0'
          }, 500);
        } else {
          $scope.openConnectionsContainer();
        }
      } else {
        if (!$scope.isPassengerOffersOpen) {
          $scope.openConnectionsContainer();
        }
      }
    };

    $scope.openConnectionsContainer = function() {
      if (!$scope.isLargeScreen) {
        $mdSidenav('sidenavTransfersMobile').open();
      } else {
        // TODO: remove jQuery
        angular.element('#sidenav-transfers').animate({
          'width': 'auto',
          'max-width': '880px'
        }, 500);

        $mdSidenav('sidenavTransfers').open();
      }
    };

    $scope.getStations = function(searchText) {
      if (searchText && searchText.length >= 3) {
        $scope.status = 'transfersStationsLoading';

        var requestParams = {
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          locale: $rootScope.activeLocale,
          searchText: searchText,
          segmentAirportCode: $scope.isFirstTransfer ? $scope.segment.departureAirportCode : $scope.segment.arrivalAirportCode
        };

        var deferred = $q.defer();

        transfers.getStations(requestParams).then(
          function successCallback(response) {
            if (response.meta && response.meta.success && response.data) {
              $scope.status = 'transfersStationsSuccess';
              deferred.resolve(response.data);
            } else {
              $scope.status = 'transfersStationsFailed';
            }
          },
          function errorCallback() {
            $scope.status = 'transfersStationsFailed';
          }
        );

        return deferred.promise;
      }
    };

    $scope.getConnections = function() {
      $scope.transfersForm.$setSubmitted();
      $scope.transfersForm.$setDirty();
      $scope.validateDropdown('passengerCount');

      if ($scope.transfersForm.$valid) {
        $scope.status = 'transfersConnectionsLoading';

        var requestParams = {
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          locale: $rootScope.activeLocale,
          connectionPayload: {
            'departureStations': $scope.isFirstTransfer && $scope.selectedDepartureStation ? $scope.selectedDepartureStation.stationCode : $scope.segment.arrivalAirportCode,
            'arrivalStations': $scope.isLastTransfer && $scope.selectedArrivalStation ? $scope.selectedArrivalStation.stationCode : $scope.segment.departureAirportCode,
            'departureDate': moment($scope.transferDate).format('YYYY-MM-DD'),
            'pax': $filter('count')($scope.selectedPassengers),
            'currency': 'EUR',
            'locale': $rootScope.activeLocale.split('_')[0]
          },
          passengers: $scope.selectedPassengers
        };

        if ($scope.isFirstTransfer) {
          requestParams.connectionPayload.departureEndTime = moment($scope.segment.departureDateTime).format('HH:mm:ss');
        } else {
          requestParams.connectionPayload.departureStartTime = moment($scope.segment.arrivalDateTime).format('HH:mm:ss');
        }

        $scope.logTransferInteraction('upsell-transfer-3-search', requestParams['connectionPayload']);

        transfers.getConnections(requestParams).then(
          function successCallback(response) {
            if (response.meta && response.meta.success && response.data) {
              if ($filter('count')(response.data) > 0) {
                $scope.status = 'transfersConnectionsSuccess';
                $scope.connections = response.data;

                if (!$rootScope.isLargeScreen) {
                  $scope.openTransfersMobileSidenav();
                }

                $scope.logTransferInteraction('upsell-transfer-4-result', requestParams['connectionPayload']);

                if ($scope.selectedConnection && $scope.connections[$scope.selectedConnection.id]) {
                  // update to newer prices
                  $scope.selectConnection($scope.selectedConnection.id, $scope.connections[$scope.selectedConnection.id]);
                }
              } else {
                $scope.status = 'transfersConnectionsEmpty';
                $scope.connections = {};
              }
            } else {
              $scope.status = 'transfersConnectionsFailed';
              $scope.connections = {};
            }
          },
          function errorCallback() {
            $scope.status = 'transfersConnectionsFailed';
            $scope.connections = {};
          }
        )
      }
    };

    $scope.selectDepartureStation = function(station) {
      $scope.selectedDepartureStation = station;
    };

    $scope.selectArrivalStation = function(station) {
      $scope.selectedArrivalStation = station;
    };

    $scope.validateDropdown = function(model) {
      $scope.transfersForm[model].$setDirty();
    };

    $scope.close = function(sideNav) {
      if (sideNav === 'mobile') {
        $mdSidenav('sidenavTransfersMobile').close();

        if ($scope.isPassengerOffersOpen) {
          $scope.selectedPassenger = null;
          $scope.isPassengerOffersOpen = false;
        }
      } else if (sideNav === 'desktop') {
        $mdSidenav('sidenavTransfers').close();
      }
    };

    $scope.openBasket = function() {
      $scope.close('mobile');
      $scope.close('desktop');
      basketHelper.toggleBasket(true);

      $scope.logTransferInteraction('upsell-transfer-6-basket');
    };

    $scope.toggle = function(pnrPosition) {
      if ($scope.selectedPassengers[pnrPosition]) {
        delete $scope.selectedPassengers[pnrPosition];
      } else {
        $scope.selectedPassengers[pnrPosition] = angular.copy($scope.passengerList[pnrPosition]);
      }
    };

    $scope.exists = function(pnrPosition) {
      return $scope.selectedPassengers[pnrPosition];
    };

    $scope.isIndeterminate = function() {
      return ($filter('count')($scope.selectedPassengers) !== 0 &&
        $filter('count')($scope.selectedPassengers) !== $filter('count')($scope.passengerList));
    };

    $scope.isChecked = function() {
      return $filter('count')($scope.selectedPassengers) === $filter('count')($scope.passengerList);
    };

    $scope.toggleAll = function() {
      if ($filter('count')($scope.selectedPassengers) === $filter('count')($scope.passengerList)) {
        $scope.selectedPassengers = {};
      } else if ($filter('count')($scope.selectedPassengers) === 0 || $filter('count')($scope.selectedPassengers) > 0) {
        $scope.selectedPassengers = angular.copy($scope.passengerList);
      }
    };

    $scope.getPassengerCountLabel = function() {
      return gettextCatalog.getPlural($filter('count')($scope.selectedPassengers), '1 passenger selected', "{{$count}} passengers selected", {});
    };

    $scope.closeSelect = function() {
      $mdSelect.hide();
    };
  }
]);
