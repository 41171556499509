cockpitApp.factory('bookingHelper', [
  '$rootScope', '$localStorage', '$filter', 'sessionHelper', 'profileHelper',
  function($rootScope, $localStorage, $filter, sessionHelper, profileHelper)
  {
    var factory = {};

    factory.getBookings = function() {
      var sessionProfile = JSON.parse(sessionStorage.getItem('session_profile'));

      if (sessionProfile) {
        return sessionProfile.profileBookings
      }

      return $localStorage.cockpit.bookings;
    };

    factory.getBookingsCredentials = function() {
      var bookingsCredentials = [];
      var bookings = this.getBookings();

      if (!$filter('isEmpty')(bookings)) {
        angular.forEach(bookings, function(booking) {
          bookingsCredentials.push({
            pnrIdentifier: booking.pnrIdentifier,
            filekey: booking.filekey,
            lastname: booking.searchName
          });
        });
      }

      return bookingsCredentials;
    };

    factory.setBookingsNames = function(profileBookings) {
      var self = this;

      if (profileBookings && $filter('count')(profileBookings) > 0) {
        angular.forEach(profileBookings, function(profileBooking) {
          profileBooking.names = self.setBookingNames(profileBooking);
        });
      }
    };

    factory.setBookingNames = function(booking) {
      if ($filter('count')(booking.passengers) > 0) {
        return booking.passengers.map(function(passenger) {
          return passenger.displayName;
        }).reduce(function(prev, curr) {
          return prev + ',\xa0' + curr;
        });
      }
    }

    factory.getPassengersToDisplay = function() {
      if ($rootScope.booking) {
        var passengerList = $rootScope.booking.passengers || {};
        var passengers = [];

        angular.forEach(passengerList, function(passenger) {
          if (passenger.passengerTypeCode !== 'INF') {
            passengers.push({
              id: passenger.id,
              salutation: $filter('getSalutation')(passenger),
              name: passenger.firstname + ' ' + passenger.lastname,
              pnrPosition: passenger.pnrPosition
            });
          }
        });

        return passengers;
      }
    };

    factory.getPassengerByPnrPosition = function(booking, pnrPosition, isInfantRestricted) {
      if (booking && booking.passengers.length > 0) {
        if (isInfantRestricted) {
          return $filter('filter')($filter('toArray')(booking.passengers), {pnrPosition: pnrPosition, passengerTypeCode: 'ADT'})[0] || $filter('filter')($filter('toArray')(booking.passengers), {pnrPosition: pnrPosition, passengerTypeCode: 'CHD'})[0];
        }

        return $filter('filter')($filter('toArray')(booking.passengers), {pnrPosition: pnrPosition})[0];
      }
    };

    function generateUrlTravelPlan(endpoint) {
      var timeZone = moment.tz.guess().replace('/','-');

      return cockpitApiUrl + endpoint + '/' + $rootScope.booking['travelPlanHash'] + '/' + $rootScope.activeLocale + '/' + timeZone + '/' + $rootScope.source;
    }

    factory.createPdfTravelPlan = function() {
      var url = generateUrlTravelPlan(cockpitApiPdfBookingEndpoint, $rootScope.activePnrIdentifier);
      window.open(url);
    };

    factory.isBookingCancelled = function() {
      return $rootScope.booking && $rootScope.booking.cancelled;
    };

    factory.isActionDisabled = function() {
      return ($rootScope.activeFilekey && $rootScope.bookingsUpdating[$rootScope.activeFilekey]) || $rootScope.isOffline;
    };

    factory.isBookingOutdated = function(booking) {
      if (booking && !$rootScope.bookingsUpdating[$rootScope.activeFilekey]) {
        var updateDateTimeUtc = moment.utc(booking.updateDateTime);
        var halfAnHourAgo = moment().utc().subtract(30, 'minutes');
        var browserSessionStartedDateTimeUtc = moment.utc(sessionHelper.getBrowserSessionStartedDateTime());

        return browserSessionStartedDateTimeUtc.isAfter(updateDateTimeUtc) || updateDateTimeUtc.isBefore(halfAnHourAgo);
      }
    };

    return factory;
  }
]);
