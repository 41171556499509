cockpitApp.controller('detailsController', [
  '$rootScope', '$scope', '$location', '$routeParams', '$filter', 'baggageHelper', 'bookingProvider', 'gettextCatalog', 'functionalities', 'preferences', 'logsProvider', 'componentHelper', 'dataHelper', 'basketHelper', 'navigationHelper', 'bookingHelper', 'insurance', 'seatMapHelper', 'segmentHelper',
  function($rootScope, $scope, $location, $routeParams, $filter, baggageHelper, bookingProvider, gettextCatalog, functionalities, preferences, logsProvider, componentHelper, dataHelper, basketHelper, navigationHelper, bookingHelper, insurance, seatMapHelper, segmentHelper)
  {
    var logRequestParams = {};
    $scope.basketHelper = basketHelper;

    $scope.$watch(function() {
      var isBookingOutdated = $scope.booking && $filter('dateTimeDiff')(moment.utc($scope.booking.updateDateTime), moment.utc(), 'hours') < -24 && !$scope.bookingsUpdating[$scope.activeFilekey];
      var tryBufferExpired = !$scope.bookingRefreshLastTry || ($scope.bookingRefreshLastTry && $filter('dateTimeDiff')($scope.bookingRefreshLastTry, null, 'minutes') < -30);
      var isPnrIdentifierMissing = $scope.booking && !$scope.booking.pnrIdentifier;

      return (isBookingOutdated || isPnrIdentifierMissing) && tryBufferExpired;
    }, function(refreshBooking) {
      if (refreshBooking) {
        $scope.bookingRefreshLastTry = moment().format();
        $scope.fetchBooking($scope.activeFilekey, $scope.activeLastname, true, 'autoRefresh');
      }
    });

    $rootScope.appTitle = $rootScope.appName + ' - ' + gettextCatalog.getString('Details') + ' - ' + $scope.activeFilekey + ' ' + $scope.activeLastname;
    $scope.buttons = [];

    $rootScope.setoo[$rootScope.activeFilekey] = null;

    $scope.loadBookingDetails = function() {
      if ($rootScope.pingBookingsRefreshDateTimeUtc[$rootScope.activeFilekey]) {
        delete $rootScope.pingBookingsRefreshDateTimeUtc[$rootScope.activeFilekey];
      }

      var update = $location.url().indexOf('update') > -1;

      logRequestParams =  {
        interactionName: 'bookingDetails',
        filekey: $scope.activeFilekey,
        lastname: $scope.activeLastname,
        language: $scope.activeLocale,
        details: {
          origin: $rootScope.origin || 'deepLink'
        }
      };

      if (!$scope.booking && $rootScope.isLocalStorageFull()) {
        $rootScope.error.meta.cockpit_code = 'localStorageFull';
        navigationHelper.redirectWithLocale('/error');
        componentHelper.slideUpSplashScreen(500);
        componentHelper.hideBasketPopoverTimeout($scope.activeFilekey);
      } else if (!$scope.booking || update || ($scope.booking && $scope.booking.profileBooking)) {
        $scope.fetchBooking($scope.activeFilekey, $scope.activeLastname, update, 'deeplink');
      } else {
        // check whether activeLastname is in the booking
        if ($scope.isSearchNameValid()) {
          // check whether activeLastname belongs to one of the passengers in the group booking
          if ($scope.isPassengerInGroupBooking()) {
            logRequestParams.details.source = $rootScope.bookingSource || 'localStorage';
            logsProvider.logInteraction(logRequestParams);
            $scope.getFunctionalities();
            $scope.getPreferences();
            componentHelper.hideBasketPopoverTimeout($scope.activeFilekey);
            $scope.openAncillaryDeepLink();
          } else {
            $scope.fetchBooking($scope.activeFilekey, $scope.activeLastname, update, 'deeplink');
          }
        } else {
          $rootScope.error = {
            filekey: $scope.activeFilekey,
            lastname: $scope.activeLastname
          };
          navigationHelper.redirectWithLocale('/error');
          componentHelper.slideUpSplashScreen(500);
        }
      }
    };

    $scope.isPassengerInGroupBooking = function() {
      var passengersLastnames = $scope.booking.passengers.map(function(passenger) {
        return navigationHelper.normalizeLastname(passenger.lastname);
      });

      return passengersLastnames.indexOf(navigationHelper.normalizeLastname($scope.activeLastname)) > -1;
    };

    $scope.isSearchNameValid = function() {
      var normalizedLastnames = $scope.booking.lastNames.map(function(lastname) {
        return navigationHelper.normalizeLastname(lastname);
      });

      return normalizedLastnames.indexOf(navigationHelper.normalizeLastname($scope.activeLastname)) > -1;
    };

    $scope.openAncillaryDeepLink = function() {
      if ($scope.booking && $scope.booking.segments) {
        if ($rootScope.supportedFeatures.includes($routeParams.ancillary)) {
          eval('$scope.open' + $filter('capitalize')($routeParams.ancillary) + 'DeepLink()');
        }
      }
    };

    $scope.openSeatmapDeepLink = function() {
      if ($routeParams.segment && $routeParams.segment !== 'update') {
        $scope.openSpecificSeatMapDeepLink();
      } else {
        $scope.openGenericSeatMapDeepLink();
      }
    };

    $scope.openBaggageDeepLink = function() {
      if ($scope.booking && $scope.booking.segments) {
        baggageHelper.openBaggageContainer();
      }
    };

    $scope.openSpecificSeatMapDeepLink = function() {
      var requestedSegment = $filter('sanitizeRouteParam')($routeParams.segment);
      var departureAirportCode = requestedSegment.split('-')[0];
      var arrivalAirportCode = requestedSegment.split('-')[1];
      var segment = $scope.booking.segments.find(function(segment) {
        return segment.departureAirportCode === departureAirportCode && segment.arrivalAirportCode === arrivalAirportCode;
      });

      seatMapHelper.openSeatMap(segment);
    };

    $scope.openGenericSeatMapDeepLink = function() {
      var segment = segmentHelper.getFutureFlightSegments()[0];

      seatMapHelper.openSeatMap(segment);
    };

    $scope.fetchBooking = function(filekey, lastname, update, origin) {
      var postAction = !$scope.booking ? 'redirect' : 'notify';

      bookingProvider.updateBooking(filekey, lastname, update, postAction, origin).then(
        function successCallback(responseMeta) {
          if (responseMeta && responseMeta.success) {
            logRequestParams.details.source = responseMeta.source;
            logsProvider.logInteraction(logRequestParams);
            componentHelper.hideBasketPopoverTimeout($scope.activeFilekey);
            $scope.openAncillaryDeepLink();
          } else {
            componentHelper.slideUpSplashScreen(500);
          }

          $scope.notifyBookingCancellation();
        },
        function errorCallback() {
          componentHelper.slideUpSplashScreen(500);
        }
      )
    };

    $scope.getFunctionalities = function() {
      if ($scope.booking) {
        functionalities.getFunctionalities();
      }
    };

    $scope.getPreferences = function() {
      if ($scope.booking) {
        preferences.getPreferences();
      }
    };

    $scope.toggle = function(buttonId) {
      if (typeof $scope.buttons[buttonId] !== 'undefined') {
        $scope.buttons[buttonId] = !$scope.buttons[buttonId];
      } else {
        $scope.buttons[buttonId] = true;
      }
    };

    $scope.visible = function(buttonId) {
      return $scope.buttons[buttonId];
    };

    $scope.hideContactSection = function(booking) {
      if (booking) {
        return booking.isLowCost && subdomain === 'mycockpit';
      }
    };

    $scope.preOpenSidenav = function() {
      if (dataHelper.getValue('action') === 'openBasket') {
        basketHelper.toggleBasket(true);
      }

      dataHelper.setValue(false, 'action');
    };

    $scope.notifyBookingCancellation = function() {
      if ($rootScope.booking && $rootScope.booking.cancelled) {
        componentHelper.showNotification('error', gettextCatalog.getString('This booking was cancelled.'));
      }
    };

    $scope.showSpecialHintBar = function(booking) {
      if (booking) {
        return !booking.cancelled && !$filter('isEmpty')(booking.segments) && (booking.isLowCost || booking.isCancelable || (bookingHelper.isBookingOutdated(booking) && !booking.isLowCost) || booking.externalBookingLink);
      }
    };

    $scope.$on('newReleaseVersionId', function(event, lastReleaseVersionId) {
      if (!$scope.booking.releaseVersionId || $scope.booking.releaseVersionId !== lastReleaseVersionId) {
        $scope.fetchBooking($scope.activeFilekey, $scope.activeLastname, true, 'deeplink');
      }
    });

    var functionalitiesFetchedListener = $scope.$on('functionalitiesFetched', function(event, functionalityList) {
      if (!functionalityList && $rootScope.isFunctionalityEnabled('automaticCheckinEnabled')) {
        var logAutomaticCheckinInteractionParams = {
          interactionName: 'upsell-automatic-checkin-1-offered',
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          locale: $rootScope.activeLocale
        };

        logsProvider.logInteraction(logAutomaticCheckinInteractionParams);
      }

      if (!functionalityList && $rootScope.isFunctionalityEnabled('transferEnabled')) {
        var logTransferInteractionParams = {
          interactionName: 'upsell-transfer-1-offered',
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          locale: $rootScope.activeLocale
        };

        logsProvider.logInteraction(logTransferInteractionParams);
      }

      if ((!functionalityList || (functionalityList && functionalityList.includes('covidTestEnabled'))) && $rootScope.isFunctionalityEnabled('covidTestEnabled')) {
        var logCovidTestInteractionParams = {
          interactionName: 'upsell-covid-test-1-offered',
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          locale: $rootScope.activeLocale
        };

        logsProvider.logInteraction(logCovidTestInteractionParams);
      }

      if ((!functionalityList || (functionalityList && functionalityList.includes('insuranceSetooBannerEnabled'))) && $rootScope.isFunctionalityEnabled('insuranceSetooBannerEnabled')) {
        insurance.getSetooOffer().then(
          function successCallback(response) {
            if (response.data && response.meta && response.meta.success) {
              $rootScope.setoo[$rootScope.activeFilekey] = response.data;

              if ($rootScope.setoo[$rootScope.activeFilekey].type === 'proposal') {
                var logRequestParams = {
                  interactionName: 'bannerSetooOffered',
                  filekey: $rootScope.activeFilekey,
                  lastname: $rootScope.activeLastname,
                  language: $rootScope.activeLocale,
                  details: {
                    link: $rootScope.setoo[$rootScope.activeFilekey].details.url
                  }
                };

                logsProvider.logInteraction(logRequestParams);
              }
            }
          }
        )
      }
    });

    $scope.$on('$destroy', functionalitiesFetchedListener);

    $scope.notifyBookingCancellation();
    $scope.loadBookingDetails();
    $scope.preOpenSidenav();
  }
]);
