cockpitApp.service('domHelper', function() {
  var factory = {};

  factory.displayHorizontalScrollIndicator = function(elementId, direction) {
    var domElement = document.getElementById(elementId);

    switch (direction) {
      case 'left':
        return domElement.scrollLeft !== 0;
      case 'right':
        return domElement.scrollLeft + domElement.clientWidth !== domElement.scrollWidth;
    }
  };

  factory.displayVerticalScrollIndicator = function(elementId, direction) {
    var domElement = document.getElementById(elementId);

    switch (direction) {
      case 'top':
        return domElement.scrollTop !== 0;
      case 'bottom':
        return domElement.scrollTop + domElement.clientHeight !== domElement.scrollHeight;
    }
  };

  factory.modifyElementClassById = function(elementId, className, action, isToggled) {
    var domElement = document.getElementById(elementId);

    if (domElement) {
      switch (action) {
        case 'remove':
          domElement.classList.remove(className);
          break;
        case 'add':
          domElement.classList.add(className);
          break;
        case 'toggle':
          isToggled != null ? domElement.classList.toggle(className, isToggled) : domElement.classList.toggle(className);
          break;
      }
    }
  };

  return factory;
});
