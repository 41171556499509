cockpitApp.provider('markups', [
  function() {
    this.apiMarkupsEndpoint = null;

    this.setApiMarkupsEndpoint = function(newApiMarkupsEndpoint) {
      this.apiMarkupsEndpoint = newApiMarkupsEndpoint;
    };

    this.$get = ['$localStorage', '$rootScope', 'cockpitClient',
      function($localStorage, $rootScope, cockpitClient) {
        var self = this;

        return {
          getMarkups: getMarkups
        };

        function getMarkups(markupList) {
          var requestParams = {
            filekey: $rootScope.activeFilekey,
            lastname: $rootScope.activeLastname,
            locale: $rootScope.activeLocale,
            markupList: markupList
          };

          var response = cockpitClient.remoteCall(self.apiMarkupsEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }
      }
    ];
  }
]);
