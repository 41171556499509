cockpitApp.filter('isTravelAgencyPreferenceValid', function() {
  return function(preference) {
    if (preference) {
      var today = moment().format('YYYY-MM-DD');
      var isNowGreaterThanFrom = preference.from ? today >= preference.from : true;
      var isNowLowerThanTo = preference.to ? today <= preference.to : true;

      return isNowGreaterThanFrom && isNowLowerThanTo;
    }
  }
});
