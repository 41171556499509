/**
 * Cockpit Application Configuration
 */
// region Configuration
cockpitApp.config([
  'cockpitClientProvider', 'iframeProvider', 'feedbackProvider', 'profileProvider', 'automaticCheckinProvider', 'checkoutProvider', 'functionalitiesProvider', 'markupsProvider', 'preferencesProvider', 'selectMealProvider', 'insuranceProvider', 'seatMapProvider', 'transfersProvider', 'extrasProvider',
  function (cockpitClientProvider, iframeProvider, feedbackProvider, profileProvider, automaticCheckinProvider, checkoutProvider, functionalitiesProvider, markupsProvider, preferencesProvider, selectMealProvider, insuranceProvider, seatMapProvider, transfersProvider, extrasProvider)
  {
    automaticCheckinProvider.setApiAutomaticCheckinRequirementsEndpoint(cockpitApiAutomaticCheckinRequirementsUrl);
    checkoutProvider.setApiCheckoutPciTokenEndpoint(cockpitApiCheckoutPciTokenEndpoint);
    checkoutProvider.setApiCheckoutOrderEndpoint(cockpitApiCheckoutOrderEndpoint);
    checkoutProvider.setApiCheckoutAdyenSessionEndpoint(cockpitApiCheckoutAdyenSessionEndpoint);
    checkoutProvider.setApiCheckoutAdyenOrderEndpoint(cockpitApiCheckoutAdyenOrderEndpoint);
    cockpitClientProvider.setApiUrl(cockpitApiUrl);
    cockpitClientProvider.setApiSessionEndpoint(cockpitApiSessionEndpoint);
    cockpitClientProvider.setApiPingEndpoint(cockpitApiPingEndpoint);
    cockpitClientProvider.setApiBookingEndpoint(cockpitApiBookingEndpoint);
    cockpitClientProvider.setApiBookingCancelEndpoint(cockpitApiBookingCancelEndpoint);
    cockpitClientProvider.setApiTravelAgencyEndpoint(cockpitApiTravelAgencyUrl);
    cockpitClientProvider.setApiRequestInvoiceEndpoint(cockpitApiInvoiceEmailUrl);
    cockpitClientProvider.setApiLogsInteractionEndpoint(cockpitApiLogsInteractionEndpoint);
    cockpitClientProvider.setApiLogsPciIframeErrorEndpoint(cockpitApiLogsPciIframeErrorEndpoint);
    cockpitClientProvider.setApiFrequentFlyerAirlinesEndpoint(cockpitApiFrequentFlyerAirlinesEndpoint);
    cockpitClientProvider.setApiFrequentFlyerModifyEndpoint(cockpitApiFrequentFlyerModifyEndpoint);
    cockpitClientProvider.setApiCountriesEndpoint(cockpitApiCountriesEndpoint);
    cockpitClientProvider.setApiAncillaryBaggageEndpoint(cockpitApiAncillaryBaggageEndpoint);
    cockpitClientProvider.setApiCaptchaVerifyEndpoint(cockpitApiCaptchaVerifyEndpoint);
    extrasProvider.setApiExtrasEndpoint(cockpitApiExtrasEndpoint);
    feedbackProvider.setApiFeedbackEndpoint(cockpitApiFeedbackEndpoint);
    functionalitiesProvider.setApiFunctionalitiesEndpoint(cockpitApiFunctionalitiesEndpoint);
    iframeProvider.setApiIframeLoginEndpoint(cockpitApiIframeLoginEndpoint);
    insuranceProvider.setApiInsuranceSetooEndpoint(cockpitApiInsuranceSetooEndpoint);
    markupsProvider.setApiMarkupsEndpoint(cockpitApiMarkupsEndpoint);
    preferencesProvider.setApiPreferencesEndpoint(cockpitApiPreferencesEndpoint);
    profileProvider.setApiProfileRegisterEndpoint(cockpitApiProfileRegisterEndpoint);
    profileProvider.setApiProfileVerifyEndpoint(cockpitApiProfileVerifyEndpoint);
    profileProvider.setApiProfileLoginEndpoint(cockpitApiProfileLoginEndpoint);
    profileProvider.setApiProfileLinkAssistantEndpoint(cockpitApiProfileLinkAssistantEndpoint);
    profileProvider.setApiProfileLogoutEndpoint(cockpitApiProfileLogoutEndpoint);
    profileProvider.setApiProfilePasswordEmailEndpoint(cockpitApiProfilePasswordEmailEndpoint);
    profileProvider.setApiProfilePasswordResetEndpoint(cockpitApiProfilePasswordResetEndpoint);
    profileProvider.setApiProfileEditEndpoint(cockpitApiProfileEditEndpoint);
    profileProvider.setApiProfilePictureEndpoint(cockpitApiProfilePictureEndpoint);
    profileProvider.setApiProfileSocialAuthCallbackEndpoint(cockpitApiProfileSocialAuthCallbackEndpoint);
    profileProvider.setApiProfileDeleteEndpoint(cockpitApiProfileDeleteEndpoint);
    profileProvider.setApiProfileOrdersEndpoint(cockpitApiProfileOrdersEndpoint);
    profileProvider.setApiProfilePaymentsEndpoint(cockpitApiProfilePaymentsEndpoint);
    profileProvider.setApiProfileRemoveBookingEndpoint(cockpitApiProfileRemoveBookingEndpoint);
    seatMapProvider.setApiSeatMapEndpoint(cockpitApiSeatMapUrl);
    selectMealProvider.setApiMealMenuEndpoint(cockpitApiMealMenuUrl);
    selectMealProvider.setApiMealModifyEndpoint(cockpitApiMealModifyUrl);
    transfersProvider.setApiTransferStations(cockpitApiTransferStationsEndpoint);
    transfersProvider.setApiTransferConnections(cockpitApiTransferConnectionsEndpoint);
  }
]);
// endregion

/**
 * Cockpit Application Routes
 */
// region Routes
cockpitApp.config([
  '$httpProvider', '$locationProvider', '$routeProvider', '$sceDelegateProvider', 'OAuthProvider', 'OAuthTokenProvider', 'jwtInterceptorProvider', 'jwtOptionsProvider', '$mdToastProvider',
  function ($httpProvider, $locationProvider, $routeProvider, $sceDelegateProvider, OAuthProvider, OAuthTokenProvider, jwtInterceptorProvider, jwtOptionsProvider, $mdToastProvider)
  {
    jwtOptionsProvider.config({
      authHeader: 'Auth-Profile',
      whiteListedDomains: [
          'mycockpit-api',
          'localhost',
        'api.local.mycockpit',
        'api.mycockpit.local',
        'api.cockpit.local',
        /^.*\.?mycockpit\.com$/i,
        /api.mycockpit.(dev|stg|pre-prod|prod)-cluster\.aerticket-it\.de$/i
      ],
      tokenGetter: ['options', function(options) {
        if (options.url.indexOf('/api/') !== -1 && options.cache === undefined && sessionStorage.getItem("session_profile")) {
          var profile = JSON.parse(sessionStorage.getItem("session_profile"));

          if (profile) {
            return profile.token ? profile.token : null;
          }
        }

        return null;
      }]
    });

    // TODO: put list of allowed sources in env ??
    $sceDelegateProvider.resourceUrlWhitelist([
      'self',
      'http://console*.passnfly.com/**',
      'https://*flightconex.de*'
    ]);

    OAuthProvider.configure({
      baseUrl: cockpitAuthUrl,
      grantPath: '/oauth2/access_token',
      clientId: cockpitAuthClientId,
      clientSecret: cockpitAuthClientSecret
    });

    OAuthTokenProvider.configure({
      name: 'token',
      options: {
        secure: cockpitAuthHttps === 'true'
      }
    });

    $httpProvider.interceptors.push('httpInterceptor');
    $httpProvider.interceptors.push('jwtInterceptor');

    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false
    }).hashPrefix('!');

    $routeProvider
      .when('/:language?/', {
        templateUrl: '/view/welcome',
        controller: 'welcomeController as welcomeCtrl',
        label: 'Start'
      })
      .when('/:language?/overview/past', {
        templateUrl: '/view/overview',
        label: 'Past'
      })
      .when('/:language?/overview/active', {
        templateUrl: '/view/overview',
        label: 'Active'
      })
      .when('/:language?/overview', {
        redirectTo: '/overview/active',
        label: 'Bookings'
      })
      .when('/:language?/details', {
        redirectTo: '/overview',
        label: 'Bookings'
      })
      .when('/:language?/details/:filekey', {
        redirectTo: '/overview',
        label: ''
      })
      .when('/:language?/details/:filekey/:lastname', {
        templateUrl: '/view/detail',
        controller: 'detailsController as detailsCtrl',
        label: '{{filekey}}'
      })
      .when('/:language?/details/:filekey/:lastname/update', {
        templateUrl: '/view/detail',
        controller: 'detailsController as detailsCtrl',
        label: '{{filekey}}'
      })
      .when('/:language?/details/:filekey/:lastname/:ancillary', {
        templateUrl: '/view/detail',
        controller: 'detailsController as detailsCtrl'
      })
      .when('/:language?/details/:filekey/:lastname/:ancillary/update', {
        templateUrl: '/view/detail',
        controller: 'detailsController as detailsCtrl'
      })
      .when('/:language?/details/:filekey/:lastname/:ancillary/:segment', {
        templateUrl: '/view/detail',
        controller: 'detailsController as detailsCtrl'
      })
      .when('/:language?/details/:filekey/:lastname/:ancillary/:segment/update', {
        templateUrl: '/view/detail',
        controller: 'detailsController as detailsCtrl'
      })
      .when('/:language?/settings', {
        templateUrl: '/view/settings',
        label: 'Settings'
      })
      .when('/:language?/imprint', {
        templateUrl: '/view/imprint',
        label: 'Imprint'
      })
      .when('/:language?/error', {
        templateUrl: '/view/error',
        label: 'Error'
      })
      .when('/:language?/unsupported', {
        templateUrl: '/view/unsupported',
        label: 'Unsupported'
      })
      .when('/:language?/contact', {
        templateUrl: '/view/contact',
        label: 'Contact'
      })
      .when('/:language?/about', {
        templateUrl: '/view/about',
        label: 'About us'
      })
      .when('/:language?/help', {
        templateUrl: '/view/help',
        label: 'Help/FAQ'
      })
      .when('/:language?/terms', {
        templateUrl: '/view/terms',
        label: 'TOSs'
      })
      .when('/:language?/features', {
        templateUrl: '/view/features',
        label: 'Features Page'
      })
      .when('/:language?/privacy', {
        templateUrl: '/view/privacy',
        label: 'Privacy'
      })
      .when('/:language?/logout', {
        templateUrl: '/view/logout',
        controller: 'logoutController as logoutCtrl',
        label: 'Logout',
        resolve: {
          precondition: ['navigationHelper', function (navigationHelper) {
            navigationHelper.redirectIfNotLoggedIn();
          }]
        }
      })
      .when('/:language?/profile', {
        templateUrl: '/view/profile/edit',
        label: 'Profile',
        controller: 'profileEditController as profileEditCtrl',
        resolve: {
          precondition: ['navigationHelper', function (navigationHelper) {
            navigationHelper.redirectIfNotLoggedIn();
          }]
        }
      })
      .when('/:language?/profile/verify/:token', {
        templateUrl: '/view/profile/verify',
        controller: 'profileVerifyController as profileVerifyCtrl',
        label: 'Verify'
      })
      .when('/:language?/password', {
        redirectTo: '/:language?/',
        label: 'Start'
      })
      .when('/:language?/password/reset', {
        redirectTo: '/:language?/',
        label: 'Start'
      })
      .when('/:language?/password/reset/:token/:email', {
        templateUrl: '/view/profile/password-reset',
        controller: 'passwordResetController as passwordResetCtrl',
        label: 'Reset password'
      })
      .when('/:language?/register', {
        templateUrl: '/view/profile/register',
        controller: 'profileRegisterPageController as profileRegisterPageCtrl',
        label: 'Register'
      })
      .when('/:language?/checkout/:filekey/:lastname', {
        templateUrl: '/view/checkout',
        controller: 'checkoutController as checkoutCtrl',
        label: '{{filekey}}',
        resolve: {
          precondition: ['navigationHelper', 'basketHelper', function (navigationHelper, basketHelper) {
            navigationHelper.redirectIfNotLoggedIn();
            basketHelper.bookingHasBasketCheck();
          }]
        }
      })
      .when('/:language?/confirmation/:filekey/:lastname', {
        templateUrl: '/view/confirmation',
        controller: 'confirmationController as confirmationCtrl',
        label: 'Order confirmation',
        resolve: {
          precondition: ['navigationHelper', 'basketHelper', function (navigationHelper, basketHelper) {
            navigationHelper.redirectIfNotLoggedIn();
            basketHelper.bookingHasBasketCheck();
            basketHelper.redirectIfShoppingIsDisabled();
          }]
        }
      })
      .when('/:language?/checkout/:filekey', {
        redirectTo: '/overview',
        label: ''
      })
      .when('/:language?/checkout', {
        redirectTo: '/overview',
        label: 'Checkout'
      })
      // .when('/:language?/orders/:paymentIdentifier?', {
      //   templateUrl: '/view/orders',
      //   controller: 'ordersController as ordersCtrl',
      //   label: 'Orders',
      //   resolve: {
      //     precondition: ['navigationHelper', 'basketHelper', function (navigationHelper, basketHelper) {
      //       navigationHelper.redirectIfNotLoggedIn();
      //       basketHelper.redirectIfShoppingIsDisabled();
      //     }]
      //   }
      // })
      .when('/:language?/add', {
        templateUrl: '/view/add',
        controller: 'addBookingPageController as addBookingPageCtrl',
        label: 'Add'
      })
      // Needs to be the last as fallback for ancillary, to avoid collision with other routes
      .when('/:language?/:ancillary', {
        templateUrl: '/view/add',
        controller: 'addBookingPageController as addBookingPageCtrl',
        label: 'Add',
        resolve: {
          precondition: ['navigationHelper', function (navigationHelper) {
            navigationHelper.redirectIfNotSupportedFeature();
          }]
        }
      })
      .otherwise({
        redirectTo: '/:language?/',
        resolve: {
          precondition: ['navigationHelper', function (navigationHelper) {
            navigationHelper.redirectWithLocale('/');
          }]
        }
      });

    // Configure the basic preset for toast notifications
    $mdToastProvider.addPreset('defaultToast', {
      options: function() {
        return {
          parent: angular.element(document.body),
          hideDelay   : 5000,
          position    : 'top right',
          controller  : 'toastCtrl',
          templateUrl: '/element/toasts/default'
        };
      }
    });
  }
]);
// endregion
