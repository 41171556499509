cockpitApp.controller('detailMenuController', [
  '$scope', 'navigationHelper',
  function($scope, navigationHelper)
  {
    $scope.back = function(isPast) {
      if (isPast) {
        navigationHelper.bookingOverviewPreCheck('past');
      } else {
        navigationHelper.bookingOverviewPreCheck('active');
      }
    };
  }
]);
