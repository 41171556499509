cockpitApp.controller('clearCacheDialogController', [
  '$rootScope', '$scope', '$localStorage', '$mdDialog', 'logsProvider', 'bookingProvider',
  function($rootScope, $scope, $localStorage, $mdDialog, logsProvider, bookingProvider)
  {
    $scope.close = function() {
      $mdDialog.cancel();
    };

    $scope.clear = function() {
        var cacheCleared = bookingProvider.clearBookingsFromWebStorage();

        $scope.close();

        if (cacheCleared) {
          var logRequestParams = {
            interactionName: 'settingsCachedBookingsRemoved'
          };

          $rootScope.$emit('cacheCleared', 'success');
          logsProvider.logInteraction(logRequestParams);
        } else {
          $rootScope.$emit('cacheCleared', 'error');
        }

    };
  }
]);
