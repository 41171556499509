cockpitApp.controller('invoiceRequestDialogController', [
  '$rootScope', '$scope', '$mdDialog', '$routeParams', '$localStorage', 'requestInvoice',
  function ($rootScope, $scope, $mdDialog, $routeParams, $localStorage, requestInvoice) {
    $scope.status = 'displayDialog';

    $scope.closeDialog = function () {
      $mdDialog.cancel();
    };

    $scope.clearValue = function() {
      $scope.data.email = undefined;
      $scope.receiver = undefined;
      $scope.form.$setPristine();
    };
    
    $scope.sendInvoice = function () {
      $scope.submitted = true;
      
      if ($scope.form.$valid) {
        $scope.form.$setSubmitted();
        $scope.status = 'sendingRequest';

        var requestParameters = {
          filekey : $rootScope.activeFilekey,
          lastname : $rootScope.activeLastname,
          receiver : $scope.receiver,
          requestDate : moment().format()
        };

        requestInvoice.requestInvoice(requestParameters).then(
          function successCallback(response) {
            if (response.meta && response.meta.success) {
              $scope.status = 'success';
            } else {
              $scope.status = 'error';
            }
          },
          function errorCallback() {
            $scope.status = 'error';
          }
        );
      }
    };
  }
]);
