cockpitApp.controller('languageSwitchController', [
  '$rootScope', '$scope', '$filter', 'localeHelper', 'navigationHelper',
  function ($rootScope, $scope, $filter, localeHelper, navigationHelper)
  {
    $scope.getCountryFlag = function(countryCode) {
      return localeHelper.getCountryFlag(countryCode);
    };

    $scope.isDropdownListOpening = function() {
      $scope.languageDropdownOpen = true;
    };

    $scope.changeLocale = function() {
      $scope.languageDropdownOpen = false;
      localeHelper.changeLocale($scope.language, 'switch');
      navigationHelper.redirectWithLocale();
      $rootScope.$broadcast('changeLocaleCompleted')
    };
  }
]);
