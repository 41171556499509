cockpitApp.provider('automaticCheckin', [
  function() {
    this.apiAutomaticCheckinRequirementsEndpoint = null;

    this.setApiAutomaticCheckinRequirementsEndpoint = function(newApiAutomaticCheckinRequirementsEndpoint) {
      this.apiAutomaticCheckinRequirementsEndpoint = newApiAutomaticCheckinRequirementsEndpoint;
    };

    this.$get = ['cockpitClient',
      function(cockpitClient) {
        var self = this;

        return {
          getAutomaticCheckinRequirements: getAutomaticCheckinRequirements
        };

        function getAutomaticCheckinRequirements(content) {
          var response = cockpitClient.remoteCall(self.apiAutomaticCheckinRequirementsEndpoint, content, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }
      }
    ];
  }
]);
