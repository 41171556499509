cockpitApp.controller('paymentCardAccordionController', [
  '$rootScope', '$scope',
  function ($rootScope, $scope)
  {
    var closeAccordionPaymentCardListener = $rootScope.$on('closeAccordionPaymentCard', function () {
      $scope.accordionPaymentCard.collapseAll();
    });
    
    $scope.$on('$destroy', closeAccordionPaymentCardListener);
  }
]);
