cockpitApp.controller('documentsController', [
  '$rootScope', '$scope', '$filter', '$mdDialog', '$mdMedia', '$window', 'logsProvider', 'bookingHelper',
  function($rootScope, $scope, $filter, $mdDialog, $mdMedia, $window, logsProvider, bookingHelper)
  {
    $scope.bookingHelper = bookingHelper;
    $scope.invoices = $filter('documentTypeOf')($scope.booking.travelDocuments, 'INVOICE');

    $scope.displayInvoice = function() {
      return subdomain && subdomain !== 'mycockpit' && $scope.booking && !$scope.booking.isCharter;
    };

    $scope.invoicesMoved = function() {
      return $scope.invoices[$scope.invoices.length -1].moved;
    };

    $scope.invoicesMultiple = function() {
      return ($scope.booking.numberOfPdfInvoices > $scope.invoices.length && !$filter('isEmpty')($scope.invoices)) && !$scope.invoicesMoved();
    };

    $scope.invoiceInfo = function() {
      if (!$scope.booking.travelAgency) {
        return 'invoice-is-b2b';
      } else if ($filter('isEmpty')($scope.invoices)) {
        return 'invoice-empty';
      } else if ($scope.invoicesMoved()) {
        return 'invoice-moved';
      }

      return 'invoice-default';
    };

    $scope.generateLink = function(document) {
      if (!$rootScope.isOffline) {
        var link = cockpitApiUrl;

        var logRequestParams = {
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          language: $rootScope.activeLocale
        };

        switch(document.type) {
          case 'INVOICE':
            logRequestParams.interactionName = 'documentsInvoice';
            logRequestParams.details = {link: cockpitApiUrl + cockpitApiPdfInvoiceEndpoint + '/' + document.hash};

            link += cockpitApiPdfInvoiceEndpoint + '/' + document.hash;
            break;
          case 'TICKET':
            logRequestParams.interactionName = 'documentsTicket';
            logRequestParams.details = {link: cockpitApiUrl + cockpitApiPdfTicketEndpoint + '/' + document.hash};

            link += cockpitApiPdfTicketEndpoint + '/' + document.hash;
            break;
          default:
            link = '#';
        }

        $scope.goTo(link);
        logsProvider.logInteraction(logRequestParams);
      }
    };

    $scope.goTo = function(link) {
      $window.open(link, '_blank');
    };

    $scope.openInvoiceRequestDialog = function(event) {
      var useFullScreen =  $mdMedia('sm');

      $mdDialog.show({
        scope: $scope.$new(true),
        templateUrl: '/element/dialog/invoice-request',
        parent: angular.element(document.body),
        openFrom: event.target,
        closeTo: event.target,
        targetEvent: event,
        clickOutsideToClose: false,
        escapeToClose: true,
        fullscreen: useFullScreen,
        preserveScope: true
      });
    };
  }
]);
