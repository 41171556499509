cockpitApp.controller('menuMainController', [
  '$rootScope', '$scope', '$location', '$mdSidenav', '$mdDialog', '$mdMedia', '$localStorage', '$filter', 'travelAgency', 'profileHelper', 'navigationHelper', 'componentHelper', 'bookingHelper', 'logsProvider', 'gettextCatalog',
  function($rootScope, $scope, $location, $mdSidenav, $mdDialog, $mdMedia, $localStorage, $filter, travelAgency, profileHelper, navigationHelper, componentHelper, bookingHelper, logsProvider, gettextCatalog)
  {
    $scope.$watch(function() {
      return $mdSidenav('menuMainSidenav').isOpen();
    }, function(newValue) {
      $rootScope.isSideNavOpen = newValue;
    });

    $scope.checkTravelAgencyName = function(travelAgencyName) {
      return travelAgencyName === 'flug.ab-in-den-urlaub.de' ? 'fluege.de' : travelAgencyName;
    };

    $scope.goTo = function(path) {
      if (path === '/overview') {
        componentHelper.closeSidenavs();
        navigationHelper.bookingOverviewPreCheck('active', 'past');
        return;
      }

      if (path === '/help') {
        var logRequestParams = {
          interactionName: 'menuFaqPage',
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          language: gettextCatalog.getCurrentLanguage(),
          details: {
            url: path
          }
        };
        logsProvider.logInteraction(logRequestParams);
      }

      componentHelper.closeSidenavs();
      navigationHelper.redirectWithLocale(path);
    };

    $scope.openLinks = function(link, type) {
      var logRequestParams = {
        interactionName: type,
        filekey: $rootScope.activeFilekey,
        lastname: $rootScope.activeLastname,
        language: gettextCatalog.getCurrentLanguage(),
        details: {
          link: link
        }
      };

      logsProvider.logInteraction(logRequestParams);

      var language = $rootScope.activeLocale.substr(0, 2);

      switch (type) {
        case 'menuFlightSearchLinkout':
          if (link !== null) {
            link = link.replace('sFlightInput[depCity]=[[DEPARTURE-CITY]]&', '');
          }
          break;
        case 'menuHotelLinkout':
        case 'menuCovidTestLinkout':
          language = language === 'de' ? language : 'en';
          link = type === 'menuHotelLinkout' ? link + '?language=' + language : link + '?locale=' + language;
          break;
        case 'menuCo2CompensationLinkout':
          language = ['de', 'fr'].includes(language) ? language : 'en';
          link += '?locale=' + language;
          break;
      }

      navigationHelper.prepareLinkout(link);
    };

    $scope.displaySidenavLinksDivider = function() {
      const travelAgencyData = $rootScope.travelAgency.data;

      if ($filter('isEmpty')(travelAgencyData)) {
        return false;
      }

      const sidenavLinks = $rootScope.travelAgency.data.sidenavLinks;

      return Object.entries(sidenavLinks).some(function (value) {
        const key = value[0];
        const link = value[1];

        if (link === null) {
          return false;
        }

        if (key === 'hotelLink') {
          //check bookings
          return !$filter('isEmpty')(bookingHelper.getBookings());
        }

        if (key === 'covidTestLink') {
          //check functionality
          return $rootScope.isTravelAgencyFunctionalityEnabled('covidTestEnabled');
        }

        if (key === 'co2CompensationLink') {
          //check functionality
          return $rootScope.isTravelAgencyFunctionalityEnabled('co2CompensationEnabled');
        }

        return true;
      });
    };

    $scope.closeMainMenu = function() {
      $mdSidenav('menuMainSidenav').close();
    };

    $scope.openLoginDialog = function(event) {
      componentHelper.openDialog('/element/dialog/profile/main', event);
    };

    $scope.openFeedbackDialog = function(event) {
      componentHelper.openDialog('/element/dialog/feedback', event);
    };

    $scope.logoutProfile = function() {
      $scope.goTo('/logout');
    };

    $scope.getActiveBooking = function() {
      return $filter('count')($filter('getBookings')(null, 'active'));
    };
  }
]);
