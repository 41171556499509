cockpitApp.factory('checkoutHelper', [
    function () {
        const factory = {};

        factory.isInsuranceBaggageOrderValid = function(basket, order) {
            if (order.items && order.items.insuranceBaggage) {
                for (var pnrPosition in order.items.insuranceBaggage) {
                    if (order.items.insuranceBaggage.hasOwnProperty(pnrPosition)) {
                        if (order.items.insuranceBaggage[pnrPosition] !== 'success' && basket.insuranceBaggage && basket.insuranceBaggage[pnrPosition]) {
                            return false;
                        }
                    }
                }
            }

            return true;
        };

        factory.isAutomaticCheckinOrderValid = function(basket, order) {
            if (order.items && order.items.automaticCheckins) {
                for (var pnrPosition in order.items.automaticCheckins) {
                    if (order.items.automaticCheckins.hasOwnProperty(pnrPosition)) {
                        if (order.items.automaticCheckins[pnrPosition] !== 'success' && basket.automaticCheckins && basket.automaticCheckins.passengers[pnrPosition]) {
                            return false;
                        }
                    }
                }
            }

            return true;
        };

        factory.isSeatOrderValid = function(basket, order) {
            if (order.items && order.items.seats) {
                for (var segmentNumber in order.items.seats) {
                    if (order.items.seats.hasOwnProperty(segmentNumber)) {
                        for (var pnrPosition in order.items.seats[segmentNumber]) {
                            if (order.items.seats[segmentNumber].hasOwnProperty(pnrPosition)) {
                                for (var seatNumber in order.items.seats[segmentNumber][pnrPosition]) {
                                    if (order.items.seats[segmentNumber][pnrPosition].hasOwnProperty(seatNumber) && order.items.seats[segmentNumber][pnrPosition][seatNumber] !== 'success') {
                                        if (basket.seats && basket.seats[segmentNumber] && basket.seats[segmentNumber].passengers[pnrPosition] && basket.seats[segmentNumber].passengers[pnrPosition].seatNumber === seatNumber) {
                                            return false;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            return true;
        };

        factory.isBaggageOrderValid = function(basket, order) {
            if (order.items && order.items.baggage) {
                for (var pnrPosition in order.items.baggage) {
                    if (order.items.baggage.hasOwnProperty(pnrPosition)) {
                        for (var baggage in order.items.baggage[pnrPosition]) {
                            if (order.items.baggage[pnrPosition].hasOwnProperty(baggage)) {
                                for (var baggageCode in order.items.baggage[pnrPosition][baggage]) {
                                    if (order.items.baggage[pnrPosition][baggage].hasOwnProperty(baggageCode) && order.items.baggage[pnrPosition][baggage][baggageCode] !== 'success') {
                                        if (basket.baggage && basket.baggage[pnrPosition] && basket.baggage[pnrPosition][baggage] && basket.baggage[pnrPosition][baggage].segmentPriceList[baggageCode]) {
                                            return false;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            return true
        };

        factory.isBasketValid = function(basket, order ) {
            if (basket && order && order.generalStatus !== 'success') {
                // prevent the user to check out again if problematic items are still in the basket
                if (
                    !factory.isSeatOrderValid(basket, order)
                    || !factory.isBaggageOrderValid(basket, order)
                    || !factory.isAutomaticCheckinOrderValid(basket, order)
                    || !factory.isInsuranceBaggageOrderValid(basket, order)) {

                    return false;
                }
            }

            return true;
        };

        return factory;
    }
]);
