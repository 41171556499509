cockpitApp.factory('httpInterceptor', [
  '$q', '$rootScope',
  function ($q, $rootScope) {
    var progressBarBlacklist = [
      'oauth',
      'session',
      'ping',
      'functionalities',
      'travel-agency',
      'token',
      'purchase-surcharge',
      'logs',
      'setoo',
      'logout'
    ];
    
    function isBlackListed(url) {
      return progressBarBlacklist.some(function(currentValue){
        return url.search(currentValue) !== -1;
      });
    }
    
    return {
      request: function (config) {
        if (config.url.search('/api/') !== -1 && !config.cache) {
          if (!isBlackListed(config.url)) {
            $rootScope.$broadcast("startProgress");
          }
        }
        
        return $q.when(config);
      },
      response: function (response) {
        if (response.config.url.indexOf('/api/') !== -1 && !response.config.cache) {
          if (!isBlackListed(response.config.url)) {
            $rootScope.$broadcast("stopProgress");
          }
        }
  
        return $q.when(response);
      },
      responseError: function (response) {
        if (response.config.url.indexOf('/api/') !== -1 && !response.config.cache) {
          if (!isBlackListed(response.config.url)) {
            $rootScope.$broadcast("stopProgress");
          }
        }
  
        return $q.reject(response);
      }
    };
  }
]);
