cockpitApp.provider('frequentFlyer', [
  function () {
    this.$get = ['cockpitClient', '$rootScope',
      function (cockpitClient, $rootScope) {
        return {
          fetchAirlines: fetchAirlines,
          modifyFrequentflyerProgram: modifyFrequentflyerProgram
        };

        function fetchAirlines() {
          var requestParameters = {
            filekey: $rootScope.activeFilekey,
            lastname: $rootScope.activeLastname
          };
          
          return cockpitClient.fetchAirlines(requestParameters);
        }

        function modifyFrequentflyerProgram(filekey, passenger, frequentFlyerNumber, airline) {
          return cockpitClient.modifyFrequentflyerProgram(filekey, passenger, frequentFlyerNumber, airline);
        }
      }
    ];
  }
]);
