cockpitApp.directive('moveNextOnEnter', function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      element.bind("keydown", function (event) {
        if (event.which === 13) {
          var fields = element.parents('form:eq(0)').find('input, textarea, select, checkbox');
          var index = fields.index(this);
  
          if (index > -1 && (index + 1) < fields.length) {
            event.preventDefault();
            fields.eq(index + 1).focus();
          }
        }
      });
    }
  }
});
