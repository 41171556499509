/**
 * Created by bteshome on 16/11/16.
 */
cockpitApp.controller('contactPageController', [
  '$rootScope', '$scope', 'gettextCatalog', 'navigationHelper',
  function ($rootScope, $scope, gettextCatalog, navigationHelper)
  {
    $rootScope.appTitle = $rootScope.appName + ' - ' + gettextCatalog.getString('Contact');
    
    $scope.travelAgency = function () {
      if ($rootScope.travelAgency.data && $rootScope.travelAgency.data.name === 'mycockpit') {
        navigationHelper.redirectWithLocale('/');
      }
      return $rootScope.travelAgency.data;
    };
  }
]);
