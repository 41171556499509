cockpitApp.controller('flightBodyDetailsAdditionalController', [
  '$rootScope', '$scope', '$filter', 'segmentHelper', 'navigationHelper',
  function ($rootScope, $scope, $filter, segmentHelper, navigationHelper)
  {
    $scope.segmentHelper = segmentHelper;
    $scope.translatedSSRLabels = ['baggage-bg'];

    $scope.getPassengerBaggageAllowance = function(passenger) {
      if (passenger && passenger.baggageAllowance) {
        return segmentHelper.normalizeMultiplePieceBaggageAllowance(passenger.baggageAllowance.baggageAllowance);
      }
    };

    $scope.openBoardingPass = function(data, isHash) {
      var link = data;

      if (isHash) {
        link = cockpitApiUrl + cockpitApiAutomaticCheckinBoardingPassEndpoint + '/' + data;
      }

      navigationHelper.openBoardingPass(link, 'documents-boarding-pass');
    };

    $scope.$on('accordion-details-additional:onReady', function() {
      if ($rootScope.booking.segments) {
        var upcomingSegments = $rootScope.booking.segments.filter(function(segment) {
          return !$filter('isPast')(segment.arrivalDateTime, segment.arrivalTimezone);
        });

        if (upcomingSegments.length > 0) {
          $scope.accordionDetailsAdditional.toggle(upcomingSegments[0].id);
        }
      }
    });
  }
]);
