cockpitApp.controller('menuRightController', [
  '$rootScope', '$scope', '$mdSidenav', '$mdMedia', '$localStorage', '$mdComponentRegistry', '$filter', 'componentHelper', 'seatMapHelper', 'baggageHelper', 'basketHelper', 'bookingHelper', 'segmentHelper', 'locationHelper',
  function($rootScope, $scope, $mdSidenav, $mdMedia, $localStorage, $mdComponentRegistry, $filter, componentHelper, seatMapHelper, baggageHelper, basketHelper, bookingHelper, segmentHelper, locationHelper)
  {
    $scope.$watch(function() {
      return $mdSidenav('menuRightSidenav').isOpen();
    }, function(newValue) {
      $rootScope.isSideNavOpen = newValue;
    });

    $scope.$watch(function() {
      return $mdMedia('gt-sm') && $mdSidenav('menuRightSidenav').isOpen();
    }, function(newValue) {
      $rootScope.menuRightSidenavOpen = newValue;
    });

    $scope.closeRightMenu = function() {
      $mdComponentRegistry.when('menuRightSidenav').then(function(menuRightSidenav) {
        menuRightSidenav.close();
      });
    };

    $scope.openSeatMapContainer = function(segmentExtendedNumber) {
      seatMapHelper.openSeatMapContainer(segmentExtendedNumber);
    };

    $scope.openExtrasContainer = function(segmentExtendedNumber, serviceType) {
      var segment = $filter('filter')($rootScope.booking.segments, {'segmentExtendedNumber': segmentExtendedNumber})[0];

      segmentHelper.openExtrasContainer(segment, serviceType);
    };

    $scope.openBaggageContainer = function() {
      baggageHelper.openBaggageContainer();
    };

    $scope.openAutomaticCheckinContainer = function() {
      $mdSidenav('sidenavAutomaticCheckin').open();
      $rootScope.$emit('sidenavAutomaticCheckin:open');
    };

    $scope.openTransfersSidenav = function(transfer) {
      if ($rootScope.booking && $rootScope.booking.segments) {
        var segment = $filter('filter')($filter('toArray')($rootScope.booking.segments), {
          'departureAirportCode': transfer.segmentDepartureAirportCode,
          'arrivalAirportCode': transfer.segmentArrivalAirportCode
        })[0];
      }

      var transferPosition = transfer.transferArrivalAirportCode ? 'first' : 'last';

      locationHelper.openTransfersSidenav(segment, transferPosition, transfer);
    };

    $scope.getPassengerByPnrPosition = function(booking, pnrPosition, isInfantRestricted) {
      return bookingHelper.getPassengerByPnrPosition(booking, pnrPosition, isInfantRestricted);
    };

    $scope.redirectToCheckout = function(event) {
      basketHelper.redirectToCheckout(event);
    };

    $scope.removeSeatFromBasket = function(segmentExtendedNumber, pnrPosition, event) {
      basketHelper.removeSeatFromBasket(segmentExtendedNumber, pnrPosition, event);
    };

    $scope.removeExtraFromBasket = function(serviceType, pnrPosition, segmentExtendedNumber, ssrCode, event) {
      basketHelper.removeExtraFromBasket(serviceType, pnrPosition, segmentExtendedNumber, ssrCode, event);
    };

    $scope.removeBaggageFromBasket = function(pnrPosition, baggageCode, segmentPriceListCode, event) {
      basketHelper.removeBaggageFromBasket(pnrPosition, baggageCode, segmentPriceListCode, event);
    };

    $scope.removeAutomaticCheckinFromBasket = function(pnrPosition, event) {
      basketHelper.removeAutomaticCheckinFromBasket(pnrPosition, event);
    };

    $scope.removeInsuranceBaggageFromBasket = function(pnrPosition, event) {
      basketHelper.removeInsuranceBaggageFromBasket(pnrPosition, event);
    };

    $scope.removeTransferConnectionFromBasket = function(connectionId, pnrPosition, event) {
      basketHelper.removeTransferConnectionFromBasket(connectionId, pnrPosition, event);
    };

    var refreshBasketListener = $rootScope.$on('refreshBasket', function(expandAll) {
      $rootScope.basket = $localStorage.cockpit.baskets[$scope.activeFilekey];

      $scope.totalPriceSeats = basketHelper.getTotalPricePerType(['seats']);
      $scope.totalPriceExtras = basketHelper.getTotalPricePerType(['extras']);
      $scope.totalPriceCashUpgrade = basketHelper.getTotalPricePerType(['cashUpgrade']);
      $scope.totalPriceBaggage = basketHelper.getTotalPricePerType(['baggage']);
      $scope.totalPriceAutomaticCheckins = basketHelper.getTotalPricePerType(['automaticCheckins']);
      $scope.totalPriceInsuranceBaggage = basketHelper.getTotalPricePerType(['insuranceBaggage']);
      $scope.totalPriceTransfers = basketHelper.getTotalPricePerType(['transfers']);
      $scope.totalPrice = $scope.totalPriceSeats + $scope.totalPriceExtras + $scope.totalPriceCashUpgrade + $scope.totalPriceBaggage + $scope.totalPriceAutomaticCheckins + $scope.totalPriceInsuranceBaggage + $scope.totalPriceTransfers;
      $scope.passengerList = bookingHelper.getPassengersToDisplay();

      if (expandAll) {
        $scope.accordion.expandAll();
      }
    });

    $scope.showDeleteBaggageIcon = function(pnrPosition, segmentPriceListCode) {
      if ($rootScope.basket && $rootScope.basket.baggage && $rootScope.basket.baggage[pnrPosition]) {
        return baggageHelper.showDeleteBaggageIcon($rootScope.basket.baggage[pnrPosition], segmentPriceListCode);
      }
    };

    $scope.$on('$destroy', refreshBasketListener);
  }
]);
