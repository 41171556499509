cockpitApp.filter('dateTimeFormatterTimezone', [
  function() {
    return function(dateTime, format) {
      // Needs to be UTC as booking's updateDateTime are UTC on MyCockpit server
      var dateTimeUTC = moment.utc(dateTime);
      var dateTimeTimeZone = dateTimeUTC.tz(moment.tz.guess());

      return dateTimeTimeZone.format(format);
    }
  }
]);
