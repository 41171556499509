cockpitApp.directive("compareTo", function() {
  return {
    require: "ngModel",
    scope: {
      otherModelValue: "=compareTo"
    },
    link: function(scope, element, attributes, ngModel) {
      ngModel.$validators.compareTo = function(modelValue) {
        if (scope.otherModelValue === undefined) {
          return true;
        }
        return modelValue === scope.otherModelValue;
      };
      
      scope.$watch("otherModelValue", function() {
        ngModel.$validate();
      });
    }
  };
});
