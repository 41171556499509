cockpitApp.provider('reCaptcha', [
  function() {
    this.$get = ['cockpitClient', '$timeout',
      function(cockpitClient, $timeout) {
        return {
          verify: verify,
          displayCaptcha: displayCaptcha,
          resetCaptcha: resetCaptcha,
          loadGoogleRecaptcha: loadGoogleRecaptcha
        };

        function verify(captchaResponse) {
          return cockpitClient.verifyCaptcha(captchaResponse);
        }

        function displayCaptcha(id) {
          var widgetId = null;

          if (id) {
            widgetId = grecaptcha.render(id, {
              'sitekey' : cockpitApiRecaptchaSiteKey,
              'data-size': 'compact'
            });
          }
          
          return widgetId;
        }

        function resetCaptcha(widgetId) {
          if (widgetId !== null && widgetId !== undefined) {
            grecaptcha.reset(widgetId);
          }
        }
  
        function loadGoogleRecaptcha(lang) {
          $timeout(function() {
            angular.element('re-captcha-script').remove();
            var url = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=" + lang;
            var head = document.getElementsByTagName('head')[0];
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = 're-captcha-script';
            script.src = url;
  
            head.appendChild(script);
          });
        }
      }
    ];
  }
]);
