cockpitApp.controller('locationSummaryController', [
  '$scope', 'segmentHelper',
  function($scope, segmentHelper)
  {
    $scope.segmentHelper = segmentHelper;
    $scope.locationType = $scope.transferPosition === 'last' ? $scope.segment.arrivalLocationType : $scope.segment.departureLocationType;
    $scope.countryCode = $scope.transferPosition === 'last' ? $scope.segment.arrivalCountryCode : $scope.segment.departureCountryCode;
    $scope.airportCode = $scope.transferPosition === 'last' ? $scope.segment.arrivalAirportCode : $scope.segment.departureAirportCode;
    $scope.airportName = $scope.transferPosition === 'last' ? $scope.segment.arrivalAirportName : $scope.segment.departureAirportName;
    $scope.airportCity = $scope.transferPosition === 'last' ? $scope.segment.arrivalAirportCity : $scope.segment.departureAirportCity;
  }
]);
