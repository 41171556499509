/**
 * Created by bteshome on 27/01/17.
 */
cockpitApp.filter('getMealByPassengerType', function() {
  return function (mealList, passengerTypeCode) {
    var filterMealList = [];
    
    switch (passengerTypeCode) {
      case 'INF':
        $.each(mealList, function (index, meal) {
          if (meal.code === 'BBML') {
            filterMealList.push(meal);
          }
        });
        break;
      case 'CHD':
        $.each(mealList, function (index, meal) {
          if (meal.code !== 'BBML') {
            filterMealList.push(meal);
          } 
        });
        break;
      case 'INS':
        $.each(mealList, function (index, meal) {
          if (meal.code == 'BBML' || meal.code == 'CHML') {
            filterMealList.push(meal);
          }
        });
        break;
      default:
        $.each(mealList, function (index, meal) {
          if (meal.code !== 'BBML' && meal.code !== 'CHML') {
            filterMealList.push(meal);
          }
        });
    }
    
    return filterMealList;
  }
});
