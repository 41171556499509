cockpitApp.provider('extras', [
  function() {
    this.apiExtrasEndpoint = null;
  
    this.setApiExtrasEndpoint = function(newApiExtrasEndpoint) {
      this.apiExtrasEndpoint = newApiExtrasEndpoint;
    };
    
    this.$get = ['cockpitClient',
      function (cockpitClient) {
        var self = this;
        
        return {
          getExtraServices: getExtraServices
        };

        function getExtraServices(requestParameters) {
          var response = cockpitClient.remoteCall(self.apiExtrasEndpoint, requestParameters, 'POST', null, null);
  
          return cockpitClient.resolveRequest(response);
        }
      }
    ];
  }
]);
