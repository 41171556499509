cockpitApp.controller('flightTitleController', [
  '$scope', 'segmentHelper', 'navigationHelper',
  function($scope, segmentHelper, navigationHelper)
  {
    $scope.segmentHelper = segmentHelper;

    $scope.redirectToFAQSection = function(hash) {
      navigationHelper.redirectWithLocale('/help' + '#' + hash);
    }
  }
]);
