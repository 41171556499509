cockpitApp.filter('capitalize', function() {
  return function (value) {
    if (value) {
      value = value.toLowerCase();
      
      return value.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    }
  }
});
