cockpitApp.filter('dateTimeDiff', function() {
  return function (dateTimeOne, dateTimeTwo, unit) {
    if (!moment.isMoment(dateTimeOne)) {
      dateTimeOne = dateTimeOne ? moment(dateTimeOne) : moment();
    }

    if (!moment.isMoment(dateTimeTwo)) {
      dateTimeTwo = dateTimeTwo ? moment(dateTimeTwo) : moment();
    }

    return dateTimeOne.diff(dateTimeTwo, unit, true);
  }
});
