cockpitApp.controller('deleteProfileDialogController', [
  '$rootScope', '$scope', '$mdDialog', '$location', 'profile', 'profileHelper', 'dataHelper', 'navigationHelper',
  function ($rootScope, $scope, $mdDialog, $location, profile, profileHelper, dataHelper, navigationHelper)
  {
    $scope.close = function () {
      $mdDialog.cancel();
    };

    $scope.deleteProfile = function () {
      $scope.status = 'deletingProfile';

      var requestParam = {
        email : $rootScope.profile.data.email
      };

      profile.deleteProfile(requestParam).then(
        function successCallback (response) {
          if (response && response.status !== 500 && response.meta && response.meta.success) {
              dataHelper.setValue(true, 'deleteProfile');
              $mdDialog.cancel();
              navigationHelper.redirectWithLocale('/logout');
          } else {
            $scope.status = 'profileDeletionFailed';
          }
        },
        function errorCallback () {
          $scope.status = 'profileDeletionFailed';
        }
      );
    };
  }
]);
