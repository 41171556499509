cockpitApp.provider('feedback', [
  function() {
    this.apiFeedbackEndpoint = null;

    this.setApiFeedbackEndpoint = function(newApiFeedbackEndpoint) {
      this.apiFeedbackEndpoint = newApiFeedbackEndpoint;
    };

    this.$get = ['$rootScope', 'cockpitClient', 'bookingHelper',
      function($rootScope, cockpitClient, bookingHelper) {
        var self = this;

        return {
          sendFeedback: sendFeedback
        };

        function sendFeedback(requestParams) {
          var feedbackBookings = [];
          var bookings = bookingHelper.getBookings();

          angular.forEach(bookings, function(booking) {
            feedbackBookings.push({
              pnrIdentifier: booking.pnrIdentifier,
              filekey: booking.filekey,
              lastname: booking.searchName,
              branding: booking.branding
            });
          });

          var content = {
            bookings: feedbackBookings,
            userAgent: window.navigator.userAgent,
            activeFilekey: $rootScope.activeFilekey
          };

          angular.merge(requestParams, content);

          var response = cockpitClient.remoteCall(self.apiFeedbackEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }
      }
    ];
  }
]);
