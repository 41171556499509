cockpitApp.controller('bannerBrandController', [
  '$rootScope', '$scope', '$mdMedia', 'logsProvider', 'navigationHelper',
  function($rootScope, $scope, $mdMedia, logsProvider, navigationHelper) {
    $scope.displayBanner = function() {
      if ($rootScope.travelAgency && $rootScope.travelAgency.data) {
        var displayOnDesktop = $mdMedia('gt-xs') && $rootScope.travelAgency.data.bannerImageDesktop;
        var displayOnMobile = $mdMedia('xs') && $rootScope.travelAgency.data.bannerImageMobile;

        return $rootScope.travelAgency.data.bannerLink && (displayOnDesktop || displayOnMobile);
      }
    };

    $scope.goTo = function(link, interactionName) {
      if (link) {
        try {
          navigationHelper.prepareLinkout(link);
          $scope.logInteraction(link, interactionName);
        } catch (e) {
          return false;
        }
      }
    };

    $scope.logInteraction = function(link, interactionName) {
      var logRequestParams = {
        interactionName: interactionName,
        filekey: $rootScope.activeFilekey,
        lastname: $rootScope.activeLastname,
        language: $rootScope.activeLocale,
        details: {
          link: link
        }
      };

      logsProvider.logInteraction(logRequestParams);
    };
  }
]);
