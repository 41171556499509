cockpitApp.factory('checkinHelper', [
  '$q', '$rootScope', '$mdSidenav',
  function($q, $rootScope, $mdSidenav)
  {
    var factory = {};

    factory.openACIContainer = function(segment) {
      $mdSidenav('sidenavAutomaticCheckin').open();
      $rootScope.$emit('sidenavAutomaticCheckin:open', segment);
    };

    return factory;
  }
]);
