cockpitApp.controller('toastCtrl', [
  '$rootScope', '$scope', '$mdDialog', '$mdMedia', '$mdToast', 'data', 'navigationHelper',
  function($rootScope, $scope, $mdDialog, $mdMedia, $mdToast, data, navigationHelper) {
  
    $scope.data = data;
    $scope.dialogIsOpen = false;
    
    $scope.closeToast = function () {
      if (!$scope.dialogIsOpen) {
        $mdToast.hide().then(function() {
          $scope.dialogIsOpen = false;
        });
      }
    };
    
    $scope.toastAction = function (event, origin) {
      if (data.dialogTemplateUrl) {
        if (!$scope.dialogIsOpen) {
          $mdDialog.show({
            templateUrl: data.dialogTemplateUrl,
            parent: angular.element(document.body),
            openFrom: event.target,
            closeTo: event.target,
            targetEvent: event,
            clickOutsideToClose: false,
            escapeToClose: true,
            fullscreen: $mdMedia('sm'),
            preserveScope: true,
            disableParentScroll: true,
            onComplete: function () {
              if (data.eventOnToastAction && data.param) {
                $rootScope.$emit(data.eventOnToastAction, data.param);
              }
              $scope.dialogIsOpen = true;
            }
          }).then(function() {
            $scope.dialogIsOpen = false;
          });
        }
      } else {
        if (origin === 'undoRemoveBooking') {
          $rootScope.isSplashScreenUp = false;
        }
        navigationHelper.redirectWithLocale(data.linkPath);
      }
    };
  }
]);
