cockpitApp.provider('requestInvoice', [
  function () {
    this.$get = ['cockpitClient',
      function (cockpitClient) {
        return {
          requestInvoice: requestInvoice
        };

        function requestInvoice(requestParameters) {
          return cockpitClient.requestInvoice(requestParameters);
        }
      }
    ];
  }
]);
