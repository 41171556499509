cockpitApp.filter('replaceUmlautCharacters', function () {
  return function (input) {
    var translate_re = /[öäüÖÄÜ]/g;
    var translate = {
      "ä": "ae", "ö": "oe", "ü": "ue",
      "Ä": "AE", "Ö": "OE", "Ü": "UE"
    };
    
    if (input) {
      return input.replace(translate_re, function (match) {
        return translate[match];
      })
    }
  }
});
