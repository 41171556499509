cockpitApp.filter('isEmpty', function() {
  return function(listItem) {
    var empty = true;
    angular.forEach(listItem, function(item) {
      empty = false;
    });

    return empty;
  }
});
