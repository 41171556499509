cockpitApp.controller('profileRegisterPageController', [
  '$rootScope', '$scope', '$routeParams', '$localStorage', '$window', 'profile', 'navigationHelper', 'profileHelper', 'reCaptcha', 'logsProvider',
  function($rootScope, $scope, $routeParams, $localStorage, $window, profile, navigationHelper, profileHelper, reCaptcha, logsProvider)
  {
    $scope.reCaptchaLoginId = 'reCaptchaLoginPage';
    $scope.reCaptchaRegisterId = 'reCaptchaRegisterPage';
    $scope.reCaptchaValid = {
      'login': false,
      'register': false
    };

    window.reCaptchaLoginCallback = function() {
      $scope.reCaptchaValid.login = true;
      $scope.$apply();
    };

    window.reCaptchaLoginExpiredCallback = function() {
      $scope.reCaptchaValid.login = false;
      $scope.$apply();
    };

    window.reCaptchaRegisterCallback = function() {
      $scope.reCaptchaValid.register = true;
      $scope.$apply();
    };

    window.reCaptchaRegisterExpiredCallback = function() {
        $scope.reCaptchaValid.register = false;
        $scope.$apply();
    };

    $scope.$on('travelAgencyFetched', function() {
      if (!profileHelper.isProfileEnabled()) {
        navigationHelper.bookingOverviewPreCheck('active', 'past');
      }

      $rootScope.$emit('registerPage');
    });

    $scope.loginProfile = function(loginForm) {
      $scope.status = 'loading';

      if (loginForm.$valid) {
        loginForm.$setSubmitted();

        var requestParams = {
          email: loginForm.email.$viewValue,
          password:  loginForm.password.$viewValue,
          state: $routeParams.state,
          client_id: $routeParams.client_id,
          scope: $routeParams.scope,
          response_type: $routeParams.response_type,
          redirect_uri: $routeParams.redirect_uri
        };

        profile.linkProfileAssistant(requestParams).then(
          function successCallback (response) {
            if (response && response.status !== 500) {
              if (response.meta && response.meta.success && response.data) {
                $window.open(response.data, '_self');

                var logRequestParams = {
                  interactionName: 'profileLoginAlexa',
                  locale: $rootScope.activeLocale
                };

                logsProvider.logInteraction(logRequestParams);
              } else {
                $scope.status = 'loginFailed';
              }
            } else {
              if (response.status === 500 && response.data.data.exception_code === 707) {
                $scope.status = 'profileNotVerified';
              } else if (response.status === 500 && response.data.data.exception_code === 705) {
                $scope.status = 'profileNotExist';
              } else {
                $scope.status = 'loginFailed';
              }
            }
          }, function errorCallback () {
            $scope.status = 'loginFailed';
          }
        );
      }
    };

    $scope.submitLogin = function(loginForm) {
      if ($localStorage.cockpit.error.captcha && $localStorage.cockpit.error.captcha.displayLoginCaptcha) {
        $scope.status = 'loading';

        var requestParams = {
          captchaResponse: grecaptcha.getResponse($scope.widgetId)
        };

        reCaptcha.verify(requestParams).then(
          function successCallback(response) {
            if (response && response.meta) {
              $scope.loginProfile(loginForm);
            }
          }
        );
      } else {
        $scope.loginProfile(loginForm);
      }
    };

    $scope.displayErrorMessages = function() {
      return $scope.errorStatus.indexOf($scope.status) > -1;
    };

    $scope.displaySuccessMessages = function() {
      return $scope.successStatus.indexOf($scope.status) > -1;
    };
  }
]);
