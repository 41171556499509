cockpitApp.provider('cockpitClient', [
  function() {
    this.apiUrl = null;
    this.apiSessionEndpoint = null;
    this.apiPingEndpoint = null;
    this.apiBookingEndpoint = null;
    this.apiBookingCancelEndpoint = null;
    this.apiTravelAgencyEndpoint = null;
    this.apiLogsInteractionEndpoint = null;
    this.apiAirlinesUrl = null;
    this.apiModifyFrequentFlyerUrl = null;
    this.apiCountriesEndpoint = null;
    this.apiAncillaryBaggageEndpoint = null;
    this.apiCaptchaVerifyEndpoint = null;

    this.setApiUrl = function(newApiUrl) {
      this.apiUrl = newApiUrl;
    };

    this.setApiSessionEndpoint = function(newApiSessionEndpoint) {
      this.apiSessionEndpoint = newApiSessionEndpoint;
    };

    this.setApiPingEndpoint = function(newApiPingEndpoint) {
      this.apiPingEndpoint = newApiPingEndpoint;
    };

    this.setApiBookingEndpoint = function(newApiBookingEndpoint) {
      this.apiBookingEndpoint = newApiBookingEndpoint;
    };

    this.setApiBookingCancelEndpoint = function(newApiBookingCancelEndpoint) {
      this.apiBookingCancelEndpoint = newApiBookingCancelEndpoint;
    };

    this.setApiRequestInvoiceEndpoint = function(newApiRequestInvoiceEndpoint) {
      this.apiRequestInvoiceEndpoint = newApiRequestInvoiceEndpoint;
    };

    this.setApiTravelAgencyEndpoint = function(newApiTravelAgencyEndpoint) {
      this.apiTravelAgencyEndpoint = newApiTravelAgencyEndpoint;
    };

    this.setApiLogsInteractionEndpoint = function(newApiLogsInteractionEndpoint) {
      this.apiLogsInteractionEndpoint = newApiLogsInteractionEndpoint;
    };

    this.setApiLogsPciIframeErrorEndpoint = function(newApiLogsPciIframeErrorEndpoint) {
      this.apiLogsPciIframeErrorEndpoint = newApiLogsPciIframeErrorEndpoint;
    };

    this.setApiFrequentFlyerModifyEndpoint = function(newApiModifyFrequentFlyerUrl) {
      this.apiModifyFrequentFlyerUrl = newApiModifyFrequentFlyerUrl;
    };

    this.setApiFrequentFlyerAirlinesEndpoint = function(newApiAirlinesUrl) {
      this.apiAirlinesUrl = newApiAirlinesUrl;
    };

    this.setApiCountriesEndpoint = function(newApiCountriesEndpoint) {
      this.apiCountriesEndpoint = newApiCountriesEndpoint;
    };

    this.setApiAncillaryBaggageEndpoint = function(newApiAncillaryBaggageEndpoint) {
      this.apiAncillaryBaggageEndpoint = newApiAncillaryBaggageEndpoint;
    };

    this.setApiCaptchaVerifyEndpoint = function(newApiCaptchaVerifyEndpoint) {
      this.apiCaptchaVerifyEndpoint = newApiCaptchaVerifyEndpoint;
    };

    this.$get = ['$rootScope', '$http', '$q', '$interval', '$cookies', '$window', '$localStorage', '$location', 'validateOauthToken', 'OAuth', 'sessionHelper', 'profileHelper', 'dataHelper',
      function($rootScope, $http, $q, $interval, $cookies, $window, $localStorage, $location, validateOauthToken, OAuth, sessionHelper, profileHelper, dataHelper) {
        var self = this;

        return {
          remoteCall: remoteCall,
          resolveRequest: resolveRequest,
          getSession: getSession,
          ping: ping,
          getBooking: getBooking,
          cancelBooking: cancelBooking,
          getTravelAgency: getTravelAgency,
          requestInvoice: requestInvoice,
          logInteraction: logInteraction,
          logPciIframeError: logPciIframeError,
          fetchAirlines: fetchAirlines,
          modifyFrequentflyerProgram: modifyFrequentflyerProgram,
          getBaggageList: getBaggageList,
          getCountries: getCountries,
          verifyCaptcha: verifyCaptcha
        };

        function getSession() {
          if (!sessionHelper.getSession()) {
            dataHelper.setValue('start', 'getSession');

            var requestParams = {
              'isLoggedIn': profileHelper.isLoggedIn()
            };

            remoteCall(self.apiSessionEndpoint, requestParams, 'POST', null, null).then(
              function successCallback(response) {
                if (response.meta && response.meta.success && response.data) {
                  sessionHelper.setSession(response.data.sessionId);
                  dataHelper.setValue(null, 'getSession');
                  $rootScope.$emit('getSession:success');
                  // preparePing();
                }
              }
            );
          } else {
            // preparePing();
          }
        }

        function preparePing() {
          angular.element($window).bind('focus', function() {
            setPingInterval($rootScope.isOffline, 2000);
          }).bind('blur', function() {
            $interval.cancel($rootScope.pingInterval);
          });
        }

        function setPingInterval(isOffline, interval) {
          $rootScope.isOffline = isOffline;
          $interval.cancel($rootScope.pingInterval);
          $rootScope.pingInterval = $interval(function() {
            ping();
          }, interval);
        }

        function ping() {
          var requestParams = {
            'isLoggedIn': profileHelper.isLoggedIn()
          };

          remoteCall(self.apiPingEndpoint, requestParams, 'POST', null, 3000).then(
            function successCallback(response) {
              setPingInterval(false, 10000);

              if (response.meta && response.meta.success && response.meta.releaseVersionId) {
                $localStorage.cockpit.releaseVersionId = response.meta.releaseVersionId;
                var isOlderThanOneMinute = true;

                if ($rootScope.pingBookingsRefreshDateTimeUtc[$rootScope.activeFilekey]) {
                  var pingBookingRefreshDateTimeUtc = moment.utc($rootScope.pingBookingsRefreshDateTimeUtc[$rootScope.activeFilekey]);
                  var oneMinuteAgo = moment().utc().subtract(1, 'minutes');

                  isOlderThanOneMinute = pingBookingRefreshDateTimeUtc.isBefore(oneMinuteAgo);
                }

                if ($location.path().indexOf('details') !== -1 && !$rootScope.bookingsUpdating[$rootScope.activeFilekey] && isOlderThanOneMinute) {
                  $rootScope.pingBookingsRefreshDateTimeUtc[$rootScope.activeFilekey] = response.meta.requestDateTime;
                  $rootScope.$broadcast('newReleaseVersionId', response.meta.releaseVersionId);
                }
              }
            },
            function errorCallback() {
              setPingInterval(true, 2000);
            }
          );
        }

        function getBooking(content) {
          var response = remoteCall(self.apiBookingEndpoint, content, 'POST', null, null);

          return resolveRequest(response);
        }

        function cancelBooking(content) {
          var response = remoteCall(self.apiBookingCancelEndpoint, content, 'POST', null, null);

          return resolveRequest(response);
        }

        function getTravelAgency() {
          var response = remoteCall(self.apiTravelAgencyEndpoint, null, 'GET', null, null);

          return resolveRequest(response);
        }

        function requestInvoice(requestParameters) {
          var response = remoteCall(self.apiRequestInvoiceEndpoint, requestParameters, 'POST', null, null);

          return resolveRequest(response);
        }

        function fetchAirlines(requestParams) {
          var response = remoteCall(self.apiAirlinesUrl, requestParams, 'POST', null, null);

          return resolveRequest(response);
        }

        function modifyFrequentflyerProgram(requestParams) {
          var response = remoteCall(self.apiModifyFrequentFlyerUrl, requestParams, 'POST', null, null);

          return resolveRequest(response);
        }

        function logInteraction(requestParams) {
          var response = remoteCall(self.apiLogsInteractionEndpoint, requestParams, 'POST', null, null);

          return resolveRequest(response);
        }

        function logPciIframeError(requestParams) {
          var response = remoteCall(self.apiLogsPciIframeErrorEndpoint, requestParams, 'POST', null, null);

          return resolveRequest(response);
        }

        function getCountries() {
          var response = remoteCall(self.apiCountriesEndpoint, {}, 'POST', null, null);

          return resolveRequest(response);
        }

        function getBaggageList(requestParams) {
          var response = remoteCall(self.apiAncillaryBaggageEndpoint, requestParams, 'POST', null, null);

          return resolveRequest(response);
        }

        function verifyCaptcha(requestParams) {
          var response = remoteCall(self.apiCaptchaVerifyEndpoint, requestParams, 'POST', null, null);

          return resolveRequest(response);
        }

        function remoteCall(requestPath, requestData, requestMethod, contentType, timeout) {
          requestMethod = requestMethod || 'POST';
          contentType = contentType === 'formData' ? undefined : 'application/json';

          if (requestMethod === 'POST') {
            requestData.source = $rootScope.source;
            requestData.requestDateTime = moment().format("Do MMMM YYYY, ") + moment().format('LTS');
            requestData.requestTimeZone = moment.tz.guess();
            requestData.supportedLocales = $rootScope.supportedLocales;
            requestData.utmSource = $rootScope.utmSource;
            requestData.pnrIdentifier = requestData.pnrIdentifier || $rootScope.activePnrIdentifier;
          }

          var request = {
            method: requestMethod,
            url: self.apiUrl + requestPath,
            headers: {
              'Accept': 'application/json',
              'Content-Type': contentType
            },
            timeout: timeout
          };

          switch (requestMethod) {
            case 'GET':
              request.params = requestData;
            break;
            case 'POST':
              request.data = requestData;
              break;
          }

          var deferred = $q.defer();

          if (validateOauthToken.isTokenValid()) {
            remoteCallSessionCheck(request, deferred);
          } else {
            var isOauthRequestProcessing = $http.pendingRequests.some(function(pendingRequest) {
              return pendingRequest.url.indexOf('oauth2/access_token') > -1;
            });

            if (!isOauthRequestProcessing) {
              OAuth.getAccessToken().then(
                function successCallback(response) {
                  remoteCallSessionCheck(request, deferred);
                  $rootScope.$emit('getOauth:success');
                },
                function errorCallback(response) {
                  remoteCall(requestPath, requestData, requestMethod, contentType, timeout);
                }
              );
            } else {
              $rootScope.$on('getOauth:success', function() {
                remoteCallSessionCheck(request, deferred);
              });
            }
          }

          return deferred.promise;
        }

        function remoteCallSessionCheck(request, deferred) {
          if (sessionHelper.getSession()) {
            remoteCallRequest(request, deferred);
          } else {
            var isSessionRequestProcessing = $http.pendingRequests.some(function(pendingRequest) {
              return pendingRequest.url.indexOf('session') > -1;
            });

            if (!isSessionRequestProcessing) {
              if (request.url.indexOf('session') > -1) {
                remoteCallRequest(request, deferred);
              } else {
                if (!dataHelper.getValue('getSession')) {
                  getSession();
                }

                $rootScope.$on('getSession:success', function() {
                  remoteCallRequest(request, deferred);
                });
              }
            } else {
              $rootScope.$on('getSession:success', function() {
                remoteCallRequest(request, deferred);
              });
            }
          }
        }

        function remoteCallRequest(request, deferred) {
          request.headers['Session-Id'] = sessionHelper.getBrowserSessionId();

          $http(request).then(
            function successCallback(response) {
              deferred.resolve(response.data);
            },
            function errorCallback(response) {
              if (response.status === -1) {
                // $rootScope.isOffline = true;
              }
              deferred.reject(response);
            }
          );
        }

        function resolveRequest(response) {
          var deferred = $q.defer();

          response.then(
            function successCallback(response) {
              deferred.resolve(response);
            },
            function errorCallback(response) {
              deferred.resolve(response);
            }
          );

          return deferred.promise;
        }
      }
    ];
  }
]);
