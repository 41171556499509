cockpitApp.factory('profileHelper', [
  '$rootScope', 'jwtHelper',
  function($rootScope, jwtHelper)
  {
    var factory = {};

    factory.getProfile = function() {
      return this.isLoggedIn() ? JSON.parse(sessionStorage.getItem('session_profile')).data : null;
    };

    factory.isLoggedIn = function() {
      var sessionProfile = JSON.parse(sessionStorage.getItem('session_profile'));

      // Iframe does not depend on subdomain's profile functionality
      return !!(($rootScope.myCockpitIframe || this.isProfileEnabled()) && $rootScope.profile && sessionProfile && sessionProfile.data && !jwtHelper.isTokenExpired(sessionProfile.token));
    };

    factory.isProfileEnabled = function() {
      return !!($rootScope.travelAgency.data && $rootScope.travelAgency.data.functionalities && $rootScope.travelAgency.data.functionalities.profileEnabled);
    };

    factory.updateSessionProfile = function() {
      sessionStorage.setItem('session_profile', JSON.stringify($rootScope.profile));
    };

    return factory;
  }
]);
