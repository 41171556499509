cockpitApp.filter('daysDiffHumanize', function() {
  return function(dateTimeOne, dateTimeTwo, allowNegativeValues) {

    if (!moment.isMoment(dateTimeOne)) {
      dateTimeOne = dateTimeOne ? moment(dateTimeOne) : moment();
    }
    if (!moment.isMoment(dateTimeTwo)) {
      dateTimeTwo = dateTimeTwo ? moment(dateTimeTwo) : moment();
    }
    
    var dateOne = moment(dateTimeOne.format('YYYY-MM-DD'));
    var dateTwo = moment(dateTimeTwo.format('YYYY-MM-DD'));
    var diffInDays = dateTwo.startOf('day').diff(dateOne.startOf('day'), 'days');

    if (allowNegativeValues) {
      return diffInDays;
    }

    return Math.abs(diffInDays);
  }
});
