cockpitApp.provider('countries', [
  function() {
    this.$get = ['cockpitClient', '$localStorage', '$filter', '$rootScope',
      function(cockpitClient, $localStorage, $filter, $rootScope) {
        return {
          getCountries: getCountries
        };

        function getCountries() {
          if (!$localStorage.cockpit.countries || $filter('isEmpty')($localStorage.cockpit.countries)
              || !$localStorage.cockpit.translations.countries
              || ($filter('daysDiffHumanize')($localStorage.cockpit.countries.updateDateTime) < -7)
              || !$localStorage.cockpit.translations.countries
              || !$localStorage.cockpit.translations.countries[Object.keys($localStorage.cockpit.translations.countries)[0]][$rootScope.activeLocale]) {
            cockpitClient.getCountries().then(
              function successCallback(response) {
                if (response && response.status !== 500 && response.meta && response.meta.success && response.data) {
                  $localStorage.cockpit.countries = {
                    data: response.data.countries,
                    updateDateTime: moment(response.meta.requestDateTime)
                  };
                  $localStorage.cockpit.translations.countries = response.data.translations;
                  $rootScope.countries = $filter('sortCountries')();
                }
              }
            );
          }
        }
      }
    ];
  }
]);
