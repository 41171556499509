cockpitApp.controller('welcomeController', [
  '$rootScope', '$scope', '$localStorage', '$timeout', '$filter', '$mdMedia', 'gettextCatalog', 'navigationHelper',
  function ($rootScope, $scope, $localStorage, $timeout, $filter, $mdMedia, gettextCatalog, navigationHelper)
  {
    $rootScope.appTitle = $rootScope.appName + ' - ' + gettextCatalog.getString('Welcome');

    $scope.reCaptchaAddBookingId = 'reCaptchaAddBookingWelcomePage';
    $scope.reCaptchaLoginId = 'reCaptchaLoginWelcomePage';
    $scope.reCaptchaRegisterId = 'reCaptchaRegisterWelcomePage';
    $scope.reCaptchaValid = {
      'addBooking': false,
      'login': false,
      'register': false
    };

    window.reCaptchaAddBookingCallback = function() {
      $scope.reCaptchaValid.addBooking = true;
      $scope.$apply();
    };

    window.reCaptchaAddBookingExpiredCallback = function() {
      $scope.reCaptchaValid.addBooking = false;
      $scope.$apply();
    };

    window.reCaptchaLoginCallback = function() {
      $scope.reCaptchaValid.login = true;
      $scope.$apply();
    };

    window.reCaptchaLoginExpiredCallback = function() {
      $scope.reCaptchaValid.login = false;
      $scope.$apply();
    };

    window.reCaptchaRegisterCallback = function() {
      $scope.reCaptchaValid.register = true;
      $scope.$apply();
    };

    window.reCaptchaRegisterExpiredCallback = function() {
      $scope.reCaptchaValid.register = false;
      $scope.$apply();
    };

    $scope.nextFlightBooking = function() {
      var activeBookings = $filter('getBookings')(null, 'active');
      $scope.booking = activeBookings[0];
    };

    $scope.getFeatureText = function(feature) {
      var limit = $mdMedia('gt-xs') ? 60 : null;

      switch (feature) {
        case 'flight-info':
          return $filter('addThreeDots')(gettextCatalog.getString('With MyCockpit you have all information of all your flights from all airlines at a glance.'), limit);
        case 'flight-change':
          return $filter('addThreeDots')(gettextCatalog.getString('Flight schedules change - Be always up-to-date and react if necessary.'), limit);
        case 'seat-reservation':
          return $filter('addThreeDots')(gettextCatalog.getString('Get insight into the seating plans of all airline planes and reserve your seat at will.'), limit);
        case 'meal-selection':
          return $filter('addThreeDots')(gettextCatalog.getString('Whether you prefer fish, meat or vegan food, let your airline know your meal selection.'), limit);
        case 'automatic-checkin':
          return $filter('addThreeDots')(gettextCatalog.getString('Activate your automatic check-in with MyCockpit. Lean back and relax.'), limit);
        case 'baggage':
          return $filter('addThreeDots')(gettextCatalog.getString('Forgot your bags? Simply book an additional suitcase to your liking.'), limit);
      }
    };

    $scope.getBrandStoryBackground = function() {
      var image = new Image();
      image.src = '/img/stories/brands/' + $rootScope.subdomain + '.png';

      return image.width !== 0 ? image.src : null;
    };

    $scope.redirectToFeature = function(hash) {
      navigationHelper.redirectWithLocale('/features' + '#' + hash);
    }

    // When using the path
    $scope.nextFlightBooking();

    // When loading the page
    var travelAgencyFetchedListener = $scope.$on('travelAgencyFetched', function() {
      $scope.nextFlightBooking();
    });

    // When logging in on the welcome page
    var loginSuccessListener = $scope.$on('loginSuccess', function() {
      $scope.nextFlightBooking();
    });

    // When removing a next flight booking
    var bookingDeletedListener = $scope.$on('bookingDeleted', function() {
      $scope.nextFlightBooking();
    });

    $scope.$on('$destroy', travelAgencyFetchedListener, loginSuccessListener, bookingDeletedListener);
  }
]);
