cockpitApp.controller('logoutController', [
  '$rootScope', '$scope', 'gettextCatalog', 'profile', 'dataHelper',
  function ($rootScope, $scope, gettextCatalog, profile, dataHelper)
  {
    $rootScope.appTitle = $rootScope.appName + ' - ' + gettextCatalog.getString('Logout');
    $scope.profileDeleted = dataHelper.getValue('deleteProfile');
    dataHelper.setValue(false, 'deleteProfile');
    
    profile.logout();
  }
]);
