cockpitApp.controller('flightBodyOptionsController', [
  '$rootScope', '$scope', '$filter', '$httpParamSerializer', '$window', 'componentHelper', 'seatMapHelper', 'logsProvider', 'segmentHelper', 'profileHelper', 'checkinHelper', 'navigationHelper', 'baggageHelper', 'bookingHelper', 'functionalities',
  function($rootScope, $scope, $filter, $httpParamSerializer, $window, componentHelper, seatMapHelper, logsProvider, segmentHelper, profileHelper, checkinHelper, navigationHelper, baggageHelper, bookingHelper, functionalities)
  {
    $scope.submitted = false;
    $scope.segmentHelper = segmentHelper;

    $scope.openAutomaticCheckinContainer = function(segment) {
      checkinHelper.openACIContainer(segment);
    };

    $scope.isAutomaticCheckinEnabled = function() {
      return $rootScope.isFunctionalityEnabled('automaticCheckinEnabled');
    };

    $scope.pnrNoTravelAgency = function(functionality) {
      return functionalities.pnrNoTravelAgency(functionality);
    };

    $scope.isActionDisabled = function(functionality, ancillaryType) {
      return segmentHelper.isActionDisabled(functionality, ancillaryType, $scope.segment) || $scope.pnrNoTravelAgency(functionality);
    };

    $scope.displayUpSell = function(functionality, ancillaryType) {
      return seatMapHelper.isSeatMapAvailable($scope.segment, functionality, ancillaryType) || $scope.pnrNoTravelAgency(functionality);
    };

    $scope.redirect = function(link, type) {
      type = type || null;

      if (link) {
        link = decodeURIComponent(link);

        var logRequestParams = {
          interactionName: type,
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          language: $rootScope.activeLocale,
          details: {
            link: link
          }
        };

        logsProvider.logInteraction(logRequestParams);

        var regEx = new RegExp('[^\x00-\x7F]+');

        if (regEx.test(link)) {
          link = encodeURI(link);
        }

        navigationHelper.prepareLinkout(link);
      }
    };

    $scope.baggageAllowance = function(segment) {
      var baggageAllowanceList = [];
      var groupTextList = [];

      if ($scope.hasExtraBaggage(segment)) {
        return 'MIX';
      }

      angular.forEach(segment.passengers, function(passenger) {
        if (passenger.baggageAllowance) {
          if (passenger.baggageAllowance.baggageAllowance) {
            baggageAllowanceList.push(passenger.baggageAllowance.baggageAllowance);
          }

          if (passenger.baggageAllowance.groupText) {
            groupTextList.push(passenger.baggageAllowance.groupText);
          }
        }
      });

      var isBaggageListNull = baggageAllowanceList.every(function(baggageAllowance) {
        return baggageAllowance == null;
      });

      var isGroupTextNull = groupTextList.every(function(groupText) {
        return groupText == null;
      });

      var isNoBaggageAllowed = baggageAllowanceList.every(function(baggageAllowance) {
        return baggageAllowance == '00P';
      });

      if ($filter('isEmpty')(baggageAllowanceList) || $filter('isEmpty')(groupTextList)) {
        return 'NA';
      }

      if (isBaggageListNull || isGroupTextNull) {
        return 'NA';
      }

      if (isNoBaggageAllowed) {
        return 'NB';
      }

      var BaggageElement = baggageAllowanceList[0];
      var counter = baggageAllowanceList.length - 1;
      var baggageCode = null;

      angular.forEach(baggageAllowanceList, function(baggageAllowance, index) {
        if (baggageAllowance !== BaggageElement) {
          baggageCode = 'MIX';
          return false;
        } else if (counter === index) {
          baggageCode = baggageAllowance;
        }
      });

      return segmentHelper.normalizeMultiplePieceBaggageAllowance(baggageCode);
    };

    $scope.getBaggageAllowance = function(segment) {
      if (segment) {
        return segment.passengers[0].baggageAllowance.baggageAllowance;
      }
    };

    $scope.getAmfBaggageAllowance = function(segment) {
      if (segment) {
        return segment.passengers[0].baggageAllowance.amfBaggageAllowance;
      }
    };

    $scope.getGroupText = function(segment) {
      if (segment) {
        return segment.passengers[0].baggageAllowance.groupText;
      }
    };

    $scope.getMixGroupText = function(segment) {
      if (!$scope.hasExtraBaggage(segment)) {
        return segment.passengers[0].baggageAllowance.mixBaggage;
      }
    };

    $scope.hasExtraBaggage = function(segment) {
      var extraBaggage = null;

      if ($rootScope.booking.isLowCost) {
        extraBaggage = segment.passengers.some(function(passenger) {
          return passenger.specialServiceRequests.some(function(specialServiceRequest) {
            return specialServiceRequest.code === 'YNET_SURCHARGE_BAGGAGE' && specialServiceRequest.name;
          });
        });
      }

      return extraBaggage;
    };

    $scope.openSeatMapContainer = function(segment) {
      seatMapHelper.openSeatMapContainer(segment.segmentExtendedNumber);
    };

    $scope.openExtrasContainer = function(segment, serviceType) {
      segmentHelper.openExtrasContainer(segment, serviceType);
    };

    $scope.openBaggageContainer = function() {
      baggageHelper.openBaggageContainer();
    };

    $scope.openSelectMealDialog = function(data, event) {
      var dialogData = {};

      if (!profileHelper.isLoggedIn()) {
          dialogData.onShowing = 'loginFromSelectMeal';
          componentHelper.openDialog('/element/dialog/profile/main', event, dialogData);
      } else {
          segmentHelper.openSelectMealDialog(data, event);
      }
    };

    $scope.openLinkoutCo2Compensation = function(segment) {
      if (segment) {
        var partnerIdAtmosfair = $rootScope.booking.travelAgency.partnerIdAtmosfair;
        var isRoundTrip = $scope.isBookingRoundTrip();
        var destinationAirportCode = $scope.getDestinationAirportCode(isRoundTrip);
        var viaSegments = $scope.getViaSegments(destinationAirportCode);
        var language = $rootScope.activeLocale.substr(0, 2);

        var params = {
          'locale': ['fr', 'de'].includes(language) ? language : 'en',
          'f_r': isRoundTrip ? 'r' : 'o',
          'f_p': $filter('count')(bookingHelper.getPassengersToDisplay()),
          'f_n': 1,
          'f_d': $rootScope.booking.departureAirportCode,
          'f_a': destinationAirportCode,
          'f_cd': $scope.getCabinClassCode(segment.cabinClassId),
          'f_td': 'l',
          'f_md': segment.equipmentCode
        };

        angular.extend(params, viaSegments);

        var link = cockpitLinkCo2Compensation + '?p=' + partnerIdAtmosfair + '#/flight?' + $httpParamSerializer(params);

        var logCo2CompensationInteractionParams = {
          interactionName: 'upsell-co2-compensation-opened',
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          locale: $rootScope.activeLocale,
          details: {
            link: link
          }
        };

        logsProvider.logInteraction(logCo2CompensationInteractionParams);

        $window.open(link, '_blank');
      }
    };

    $scope.isBookingRoundTrip = function() {
      if ($rootScope.booking.segments) {
        var airports = [];
        var equipments = [];
        var cabinClasses = [];

        $rootScope.booking.segments.map(function(segment) {
          airports.push(segment.departureAirportCode);
          airports.push(segment.arrivalAirportCode);
          equipments.push(segment.equipmentCode);
          cabinClasses.push(segment.cabinClassId);
        });

        var isRoundTripAirports = airports.join(',') === airports.reverse().join(',');
        var isRoundTripEquipments = equipments.join(',') === equipments.reverse().join(',');
        var isRoundTripCabinClasses = cabinClasses.join(',') === cabinClasses.reverse().join(',');

        return isRoundTripAirports && isRoundTripEquipments && isRoundTripCabinClasses;
      }
    };

    $scope.getCabinClassCode = function(cabinClassId) {
      switch (cabinClassId) {
        case 'CAB_ECONOMY':
        case 'CAB_ECONOMY_PLUS':
          return 'e';
        case 'CAB_BUSINESS':
          return 'b';
        case 'CAB_FIRST':
          return 'f';
        default:
          return 'e'
      }
    };

    $scope.getDestinationAirportCode = function(isRoundTrip) {
      var segmentGroups = $filter('toArray')($filter('groupBy')($scope.booking.segments, 'segmentGroup'));
      var destinationSegment = isRoundTrip ? segmentGroups[0][segmentGroups[0].length - 1] : $scope.booking.segments[$scope.booking.segments.length - 1];

      return destinationSegment.arrivalAirportCode;
    };

    $scope.getViaSegments = function(destinationAirportCode) {
      var viaSegments = [];
      var index = 0;

      angular.forEach($rootScope.booking.segments, function(segment, key) {
        if (key !== 0 && !Object.values(viaSegments).includes(segment.departureAirportCode) && segment.departureAirportCode !== destinationAirportCode) {
            viaSegments['f_v' + index] = segment.departureAirportCode;
            viaSegments['f_cv' + index] = $scope.getCabinClassCode(segment.cabinClassId);
            viaSegments['f_mv' + index] = segment.equipmentCode;
            viaSegments['f_tv' + index] = 'l';
            index++;
        }
      });

      return viaSegments;
    };

    $scope.openLinkoutCovidTest = function(segment) {
      if (segment) {
        var passengerCount = $filter('count')(segment.passengers.filter(function(passenger) {
          return passenger.passengerTypeCode !== 'INF';
        }));

        var params = {
          'locale': $rootScope.activeLocale === 'de_DE' ? 'de' : 'en',
          'entryDate': moment(segment.departureDateTime).format('YYYY-MM-DDTHH:mm'),
          'airportIataCode': segment.departureAirportCode,
          'testCount': passengerCount <= 9 ? passengerCount : 1,
          'externalReference': $rootScope.booking.travelAgencyNumber
        };

        var link = cockpitLinkCovidTest + '?' + $httpParamSerializer(params);

        var logCovidTestInteractionParams = {
          interactionName: 'upsell-covid-test-2-opened',
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          locale: $rootScope.activeLocale,
          details: {
            link: link
          }
        };

        logsProvider.logInteraction(logCovidTestInteractionParams);
        $window.open(link, '_blank');
      }
    };

    $scope.isStatusBadgeUpsellValid = function(upsellBadge) {
      return segmentHelper.isStatusBadgeUpsellValid(upsellBadge);
    };

    $scope.getNumberOfAutomaticCheckinsPerSegment = function(segment) {
      return segmentHelper.getNumberOfAutomaticCheckinsPerSegment(segment);
    };

    $scope.getNumberOfSeatsPerSegment = function(segment) {
      return segmentHelper.getNumberOfSeatsPerSegment(segment);
    };

    $scope.getNumberOfExtrasPerSegment = function(segment, serviceType) {
      return segmentHelper.getNumberOfExtrasPerSegment(segment, serviceType);
    };

    $scope.getNumberOfBaggagePerSegment = function(segment) {
      return segmentHelper.getNumberOfBaggagePerSegment(segment);
    };

    $scope.getNumberOfMealsPerSegment = function(segment) {
      return segmentHelper.getNumberOfMealsPerSegment(segment);
    };

    $scope.getNumberOfInsuranceBaggagePerSegment = function(segment) {
      return segmentHelper.getNumberOfInsuranceBaggagePerSegment(segment);
    };

    $scope.hasSegmentUnconfirmedAncillary = function(segment, ancillaryType) {
      return segmentHelper.hasSegmentUnconfirmedAncillary(segment, ancillaryType);
    };

    $scope.hasSegmentUnconfirmedSpecialServiceRequest = function(segment, ancillaryType) {
      return segmentHelper.hasSegmentUnconfirmedSpecialServiceRequest(segment, ancillaryType);
    }

    $scope.getBadgeStatus = function(segment, ancillaryType) {
      return segmentHelper.getBadgeStatus(segment, ancillaryType);
    }
  }
]);
