cockpitApp.controller('profileVerifyController', [
  '$rootScope', '$scope', '$routeParams', 'profile', 'profileHelper', 'navigationHelper', 'componentHelper', 'gettextCatalog',
  function ($rootScope, $scope, $routeParams, profile, profileHelper, navigationHelper, componentHelper, gettextCatalog)
  {
    if (profileHelper.isLoggedIn()) {
      navigationHelper.bookingOverviewPreCheck('active', 'past');
    }
    
    $rootScope.appTitle = $rootScope.appName + ' - ' + gettextCatalog.getString('Verify profile');
    $scope.status = 'loading';
    
    $scope.verifyProfile = function() {
      var token = $routeParams.token.substring(1);
      if (token !== undefined) {
        var requestParams = {
          confirmationCode: token
        };
    
        profile.verify(requestParams).then(
          function successCallback (response) {
            if (response.meta && response.meta.success) {
              if (response && response.status !== 500) {
                $scope.status = 'success';
                
                if (response.data !== undefined && response.data.email !== undefined) {
                  $scope.email = response.data.email;
                }
              }
            } else {
              if (response.status === 500 && response.data.data.exception_code === 716) {
                $scope.status = 'codeInvalid';
              } else {
                $scope.status = 'error';
              }
            }
          }
        );
      }
    };
  
    $scope.openLoginDialog = function (event) {
      var dialogData = {
        data: $scope.email,
        onShowing: 'loginFromVerify'
      };
    
      componentHelper.openDialog('/element/dialog/profile/main', event, dialogData);
    };
  
    $scope.verifyProfile();
  }
]);
