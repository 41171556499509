cockpitApp.filter('getSalutation', [
  '$filter',
  function ($filter)
  {
    return function (passenger) {
      var passengerType = $filter('passengerType')(passenger.passengerTypeCode);
    
      if (passengerType === 'INF' || passengerType === 'CHD') {
        return $filter('translateSalutation')(passengerType);
      } else {
        return $filter('translateSalutation')(passenger.salutation);
      }
    }
  }
]);
