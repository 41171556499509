cockpitApp.factory('componentHelper', [
  '$mdMedia', '$mdDialog', '$mdToast', '$rootScope', '$mdComponentRegistry', '$timeout', '$localStorage',
  function($mdMedia, $mdDialog, $mdToast, $rootScope, $mdComponentRegistry, $timeout, $localStorage)
  {
    var factory = {};

    factory.openDialog = function(templateUrl, event, options) {
      options = options || {};

      if (!templateUrl) {
        return;
      }

      var data = options.data || null;

      var onShowing = options.onShowing ? function() { $rootScope.$emit(options.onShowing, data); } : null;
      var onRemoving = options.onRemoving ? function() { $rootScope.$emit(options.onRemoving, data); } : null;
      var onComplete = options.onComplete ? function() { $rootScope.$emit(options.onComplete, data); } : null;

      var dialogParams = {
        templateUrl: templateUrl,
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        escapeToClose: false,
        onShowing: onShowing,
        onRemoving: onRemoving,
        onComplete: onComplete
      };

      if (event) {
        dialogParams['openFrom'] = event.target;
        dialogParams['closeTo'] = event.target;
        dialogParams['targetEvent'] = event;
      }

      $mdDialog.show(dialogParams);
    };

    factory.showNotification = function(type, text, options) {
      var data = {
        type: type,
        text: text
      };

      angular.merge(data, options);

      $mdToast.show(
        $mdToast.defaultToast({
          locals: {
            data : data
          }
        })
      );
    };

    factory.closeSidenavs = function() {
      $mdComponentRegistry.when('menuMainSidenav').then(function(menuMainSidenav) {
        menuMainSidenav.close();
      });

      $mdComponentRegistry.when('menuRightSidenav').then(function(menuRightSidenav) {
        menuRightSidenav.close();
      });

      $mdComponentRegistry.when('seatMapMain').then(function(seatMapMain) {
        seatMapMain.close();
      });

      $mdComponentRegistry.when('sidenavBaggage').then(function(sidenavBaggage) {
        sidenavBaggage.close();
      });

      $mdComponentRegistry.when('sidenavExtras').then(function(sidenavExtras) {
        sidenavExtras.close();
      });

      $mdComponentRegistry.when('sidenavExtrasMobile').then(function(sidenavExtrasMobile) {
        sidenavExtrasMobile.close();
      });

      $mdComponentRegistry.when('sidenavAutomaticCheckinIframe').then(function(sidenavAutomaticCheckinIframe) {
        sidenavAutomaticCheckinIframe.close();
      });

      $mdComponentRegistry.when('sidenavAutomaticCheckin').then(function(sidenavAutomaticCheckin) {
        sidenavAutomaticCheckin.close();
      });

      $mdComponentRegistry.when('sidenavAutomaticCheckinMobile').then(function(sidenavAutomaticCheckinMobile) {
        sidenavAutomaticCheckinMobile.close();
      });

      $mdComponentRegistry.when('insuranceBlueribbonbags').then(function(insuranceBlueribbonbags) {
        insuranceBlueribbonbags.close();
      });

      $mdComponentRegistry.when('insuranceBlueribbonbagsMobile').then(function(insuranceBlueribbonbagsMobile) {
        insuranceBlueribbonbagsMobile.close();
      });

      $mdComponentRegistry.when('sidenavTransfers').then(function(sidenavTransfers) {
        sidenavTransfers.close();
      });

      $mdComponentRegistry.when('sidenavTransfersMobile').then(function(sidenavTransfersMobile) {
        sidenavTransfersMobile.close();
      });
    };

    factory.slideUpSplashScreen = function(time) {
      if (!$rootScope.isSplashScreenUp) {
        $timeout(function() {
          $rootScope.isSplashScreenUp = true;
        }, time);
      }
    };

    factory.hideBasketPopoverTimeout = function(value) {
      $timeout(function() {
        $localStorage.cockpit.hideBasketPopover[value] = true;
      }, 10000);
    };

    return factory;
  }
]);
