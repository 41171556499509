cockpitApp.controller('selectMealDialogController', [
  '$rootScope', '$scope', '$mdDialog', '$routeParams', '$localStorage', '$filter', 'selectMeal', 'gettextCatalog', 'bookingProvider', 'functionalities', 'logsProvider', 'segmentHelper', 'bookingHelper',
  function ($rootScope, $scope, $mdDialog, $routeParams, $localStorage, $filter, selectMeal, gettextCatalog, bookingProvider, functionalities, logsProvider, segmentHelper, bookingHelper)
  {
    $scope.selectedMeals = {};
    $scope.initialSelectedMeals = {};

    $scope.errorStatusList = [
      'mealGetError',
      'mealModifyError',
      'mealModifyWarning',
      'bookingUpdateFailed'
    ];

    $scope.processingTextList = [
      'openingDialog',
      'modifyingMeal',
      'updatingBooking'
    ];

    var selectMealDialogOpeningListener = $rootScope.$on('selectMealDialogOpening', function (event, data) {
      if (data) {
        $scope.status = 'openingDialog';
        $scope.mealList = [];
        $scope.segment = data;

        var param = {
          airlineCode : data.marketingAirlineCode
        };

        $scope.passengerMealList = $scope.getPassengersToDisplay(data.passengers);
        $scope.getSelectMealData(param);
      } else {
        $scope.status = 'mealGetError';
      }
    });

    $scope.$on('$destroy', selectMealDialogOpeningListener);

    $scope.getSelectMealData = function (param) {
      selectMeal.getAirlineMealMenu(param).then(
        function successCallback(response) {
          if (response.meta && response.meta.success && response.data) {
            angular.forEach(response.data.meals, function (meal) {
              $scope.mealList.push({
                name: $rootScope.translations.meals[meal][$rootScope.activeLocale],
                code: meal,
                selected: false
              });
            });

            $scope.mealList.sort(function (prev, curr) {
              return prev.name ? prev.name.localeCompare(curr.name) : curr.name;
            });

            $scope.mealList.unshift({
              name: gettextCatalog.getString('None'),
              code: 'NM'
            });

            $scope.status = 'mealGetSuccess';

            var logRequestParams = {
              interactionName: 'mealSelectOpened',
              filekey: $rootScope.activeFilekey,
              lastname: $rootScope.activeLastname,
              language: $rootScope.activeLocale,
              details: param
            };

            logsProvider.logInteraction(logRequestParams);
          } else {
            $scope.status = 'mealGetError';
          }
        },
        function errorCallback() {
          $scope.status = 'mealGetError';
        }
      );
    };

    $scope.getPassengersToDisplay = function (passengerList) {
      var passengers = [];

      angular.forEach(passengerList, function (passenger) {
        var passengerMeal = $scope.getPassengerMeal(passenger);
        var status = passengerMeal ? passengerMeal.status : 'no-status';

        passengers.push({
          salutation: $filter('getSalutation')(passenger),
          name: passenger.firstname + ' ' + passenger.lastname,
          meal: passengerMeal,
          status: status,
          pnrPosition: passenger.pnrPosition
        });

        $scope.initialSelectedMeals[passenger.pnrPosition] = {
          mealCode: passengerMeal ? passengerMeal.code : null,
          existingMealCode: passengerMeal ? passengerMeal.code : null,
          action: 'none'
        };
      });

      angular.copy($scope.initialSelectedMeals, $scope.selectedMeals);

      return passengers;
    };

    $scope.mealSelectionUnchanged = function () {
      return angular.equals($scope.initialSelectedMeals, $scope.selectedMeals);
    };

    $scope.getPassengerMeal = function (passenger) {
      var passengerMeal = null;

      if (!$filter('isEmpty')(passenger.specialServiceRequests)) {
        angular.forEach(passenger.specialServiceRequests, function (ssr) {
          if (ssr.group === 'special-meal-sm') {
            passengerMeal = ssr;
            passengerMeal.status = ssr.status ? ssr.status : 'confirmed';
          }
        });
      }

      return passengerMeal;
    };

    $scope.updateSelectedMealList = function (passenger, mealCode) {
      var existingMealCode = passenger.meal ? passenger.meal.code : null;

      if (mealCode === existingMealCode) {
        if ($scope.selectedMeals[passenger.pnrPosition]) {
          $scope.selectedMeals[passenger.pnrPosition].action = 'none';
          $scope.selectedMeals[passenger.pnrPosition].mealCode = existingMealCode;
        }
        return;
      }

      var action = null;

      if (existingMealCode) {
        if (mealCode === 'NM') {
          action = 'delete';
        } else {
          action = 'change';
        }
      } else {
        if (mealCode === 'NM') {
          action = 'none';
          mealCode = null;
        } else {
          action = 'add';
        }
      }

      $scope.selectedMeals[passenger.pnrPosition] = {
        mealCode: mealCode,
        existingMealCode: existingMealCode,
        action: action
      };
    };

    $scope.modifyMeal = function () {
      $scope.status = 'modifyingMeal';
      $scope.submitted = true;

      var mealRequestParams = {
        data: $scope.selectedMeals,
        airlineName: $scope.segment.marketingAirlineName,
        airlineCode: $scope.segment.marketingAirlineCode,
        airlineIcao: $scope.segment.marketingAirlineIcao,
        airlineReference: $scope.segment.airlineReference,
        segmentExtendedNumber: $scope.segment.segmentExtendedNumber,
        filekey: $rootScope.activeFilekey,
        lastname: $rootScope.activeLastname
      };

      selectMeal.modifyMealSelection(mealRequestParams).then(
        function successCallback(response) {
          if (response.meta && response.meta.success && response.data) {
              $scope.updateBooking(response.data, mealRequestParams);

              var logRequestParams = {
                interactionName: 'mealSelectDone',
                filekey: $rootScope.activeFilekey,
                lastname: $rootScope.activeLastname,
                language: $rootScope.activeLocale,
                details: mealRequestParams
              };

              logsProvider.logInteraction(logRequestParams);
          } else {
            $scope.status = 'mealModifyError';
          }
        },
        function errorCallback() {
          $scope.mealStatus = 'mealModifyError';
        }
      );
    };

    $scope.updateBooking = function (modifyMealResponseData, mealRequestParams) {
      $scope.status = 'updatingBooking';

      bookingProvider.updateBooking($rootScope.activeFilekey, $rootScope.activeLastname).then(
        function successCallback(responseMeta) {
          if (responseMeta && responseMeta.success) {
            if (modifyMealResponseData.generalStatus === 'success') {
              $scope.status = 'mealModifySuccess';
            } else {
              $scope.status = 'mealModifyWarning';
            }

            $scope.updateSelectedMealStatus(mealRequestParams);
          } else {
            $scope.status = 'bookingUpdateFailed';
          }
        },
        function errorCallback() {
          $scope.status = 'bookingUpdateFailed';
        }
      );
    };

    $scope.updateSelectedMealStatus = function (mealRequestParams) {
      var segment = $filter('filter')($rootScope.booking.segments, {'segmentExtendedNumber': mealRequestParams.segmentExtendedNumber})[0];

      angular.forEach(mealRequestParams.data, function (mealRequestData, pnrPosition) {
        var passenger = $filter('filter')(segment.passengers, {'pnrPosition': pnrPosition})[0];

        if ($filter('isEmpty')(passenger.specialServiceRequests)) {
          switch (mealRequestData.action) {
            case 'add':
            case 'change':
              $scope.selectedMeals[pnrPosition].status = 'error';
              break;
            case 'delete':
              $scope.selectedMeals[pnrPosition].status = 'success';
              break;
          }
        }

        angular.forEach(passenger.specialServiceRequests, function (ssr) {
          switch (mealRequestData.action) {
            case 'add':
            case 'change':
              if (ssr.group === 'special-meal-sm' && mealRequestData.mealCode === ssr.code) {
                $scope.selectedMeals[passenger.pnrPosition].status = 'success';
              } else {
                $scope.selectedMeals[passenger.pnrPosition].status = 'error';
              }
              break;
            case 'delete':
              if (ssr.group === 'special-meal-sm') {
                $scope.selectedMeals[passenger.pnrPosition].status = 'error';
              } else {
                $scope.selectedMeals[passenger.pnrPosition].status = 'success';
              }
              break;
          }
        });
      });
    };

    $scope.isSelectMealAvailable = function (segment) {
      return $rootScope.functionalities[$rootScope.activeFilekey] && $rootScope.functionalities[$rootScope.activeFilekey].mealEnabled && segmentHelper.isAircraft(segment.equipmentType)
        && !bookingHelper.isBookingCancelled() && !bookingHelper.isActionDisabled()
        && $filter('dateTimeDiff')(segment.departureDateTime, null, 'hours') > 48;
    };

    $scope.openSelectMealDialog = function (segment) {
      var updatedSegment = $filter('filter')($rootScope.booking.segments, {'segmentExtendedNumber': segment.segmentExtendedNumber})[0];

      $rootScope.$emit('selectMealDialogOpening', updatedSegment);
    };

    $scope.close = function() {
      $mdDialog.cancel();
    };

    $scope.displayErrorMessages = function () {
      return $scope.errorStatusList.indexOf($scope.status) !== -1;
    };

    $scope.displayProcessingText = function () {
      return $scope.processingTextList.indexOf($scope.status) !== -1;
    };
  }
]);
