cockpitApp.filter('translateEquipmentType', [
  'gettextCatalog',
  function (gettextCatalog) {
    return function (value) {
      switch (value) {
        case 'aircraft':
          return gettextCatalog.getString('aircraft', null, 'Equipment type label detail additional');
        case 'helicopter':
          return gettextCatalog.getString('helicopter', null, 'Equipment type label detail additional');
        case 'watercraft':
          return gettextCatalog.getString('watercraft', null, 'Equipment type label detail additional');
        case 'bus':
          return gettextCatalog.getString('bus', null, 'Equipment type label detail additional');
        case 'car':
          return gettextCatalog.getString('car', null, 'Equipment type label detail additional');
        case 'truck':
          return gettextCatalog.getString('truck', null, 'Equipment type label detail additional');
        case 'train':
          return gettextCatalog.getString('train', null, 'Equipment type label detail additional');
      }
    }
  }
]);
