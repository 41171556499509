cockpitApp.directive('menuDetails', function () {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: '/element/menu/detail',
    controllerAs: 'detailMenuCtrl',
    controller: 'detailMenuController'
  };
});

