cockpitApp.controller('flightOverviewController', [
  '$rootScope', '$scope', '$filter', 'bookingProvider', 'navigationHelper', 'segmentHelper',
  function($rootScope, $scope, $filter, bookingProvider, navigationHelper, segmentHelper)
  {
    $scope.loading = false;
    $scope.segmentHelper = segmentHelper;

    $scope.removeBooking = function(event) {
      event.stopPropagation();
      bookingProvider.removeBooking($scope.booking.filekey, $scope.booking.searchName);
    };

    $scope.bookingStatusContainsNumber = function(booking) {
      return /\d/.test($filter('countdownStatusBooking')(booking));
    };

    $scope.goToDetails = function(isProfileBooking, filekey, lastname) {
      if (isProfileBooking) {
        $scope.fetchBooking(filekey, lastname);
      } else {
        $rootScope.origin = 'overview';
        navigationHelper.redirectWithLocale('/details/:' + filekey + '/:' + lastname);
      }
    };

    $scope.fetchBooking = function(filekey, lastname) {
      $scope.loading = true;
      bookingProvider.updateBooking(filekey, lastname, false, 'redirect', 'overview');
    };
  }
]);
