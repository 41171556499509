cockpitApp.filter('isPast', function() {
  return function(dateTime, timezone) {
    timezone = timezone || moment.tz.guess();

    if (!moment.isMoment(dateTime)) {
      dateTime = moment.tz(dateTime, timezone);
    }

    return !dateTime.isValid() || dateTime < moment.tz(timezone);
  }
});
