cockpitApp.controller('featuresController', [
  '$rootScope', '$scope', 'gettextCatalog', '$location', '$anchorScroll',
  function ($rootScope, $scope, gettextCatalog, $location, $anchorScroll)
  {
    $rootScope.appTitle = $rootScope.appName + ' - ' + gettextCatalog.getString('Feature page');
    $scope.features = [
      {
        section: 'travel-plan',
        functionality: 'pdfTravelplanCreateEnabled',
        title: gettextCatalog.getString('Travel plan'),
        paragraph: gettextCatalog.getString('The MyCockpit travel plan provides an overview of all stops during your trip. Please always update your booking to ensure that the latest information is available. The corresponding flight and airport icons provide you with individual additional services that can be added to your trip as you wish.'),
        subtitles: [
          {
            title: gettextCatalog.getString('Simply easy'),
            paragraph: gettextCatalog.getString('The travel plan presents information in a clear, relevant and simple way - with all data in one place.')
          },
          {
            title: gettextCatalog.getString('Additional service on request'),
            paragraph: gettextCatalog.getString('Add individual ancillaries to your trip to enhance it to your taste &amp; comfort.')
          },
          {
            title: gettextCatalog.getString('All travels in sight'),
            paragraph: gettextCatalog.getString('MyCockpit not only has an individual travel overview, but also offers an overview of all trips, all airlines.')
          },
        ]
      },
      {
        section: 'automatic-check-in',
        functionality: 'automaticCheckinEnabled',
        title: gettextCatalog.getString('Automatic check-in'),
        paragraph: gettextCatalog.getString('Check-in can quickly become stressful, as airlines usually only open their check-ins a few hours before departure. In order to avoid this, our automatic check-in offers you the opportunity to check-in conveniently even months in advance. As soon as the check-in opens, the system recognizes the above information and checks you in automatically, the boarding passes are sent to you.'),
        subtitles: [
          {
            title: gettextCatalog.getString('Always up to date'),
            paragraph: gettextCatalog.getString('Track the status of your automatic check-in at any time and easily on your MyCockpit details page of your trip.')
          },
          {
            title: gettextCatalog.getString('Sit back and relax'),
            paragraph: gettextCatalog.getString('With an automatic check-in, you are able to leave your data for all check-ins once and don\'t have to worry about anything else.')
          },
          {
            title: gettextCatalog.getString('Know how'),
            paragraph: gettextCatalog.getString('You will receive the boarding passes for your flights directly by email 24 hours before the flight.')
          },
        ]
      },
      {
        section: 'seat',
        functionality: 'seatDisplayEnabled',
        title: gettextCatalog.getString('Seat reservation'),
        paragraph: gettextCatalog.getString('Whether at the front or back of the plane, at the window or aisle, the MyCockpit seatmap offers you a quick and easy way to reserve your seat. The seatmap can be accessed via the additional services area for the flight of your choice. Select a passenger and add your seat to the shopping cart - quick and easy.'),
        subtitles: [
          {
            title: gettextCatalog.getString('Quick overview'),
            paragraph: gettextCatalog.getString('You don\'t want to spend a lot of money? Find out where you want to sit in seconds with our pricing slider.')
          },
          {
            title: gettextCatalog.getString('Click &amp; play'),
            paragraph: gettextCatalog.getString('No more confirmation buttons - our new seatmap adds the seat of your choice to the shopping cart with only 1 click.')
          },
          {
            title: gettextCatalog.getString('Well informed'),
            paragraph: gettextCatalog.getString('Click on a seat of your choice and get the information you need - quick and easy.')
          },
        ]
      },
      {
        section: 'baggage',
        functionality: 'baggageEnabled',
        title: gettextCatalog.getString('Extra baggage'),
        paragraph: gettextCatalog.getString('If you want to buy more souvenirs, you can now add additional baggage to your trip on MyCockpit. Any flight, any airline, one offer - depending on your airline, you will receive your individual offers.'),
        subtitles: [
          {
            title: gettextCatalog.getString('Your booking, your offer'),
            paragraph: gettextCatalog.getString('Depending on the flight route and airline, you will receive your individual baggage offer.')
          },
          {
            title: gettextCatalog.getString('Firmly documented'),
            paragraph: gettextCatalog.getString('Each additional service, as well as the additional baggage, can be found at any time in the flight details.')
          },
          {
            title: gettextCatalog.getString('Know how'),
            paragraph: gettextCatalog.getString('In addition to your baggage, you can buy a baggage protection which pays up to 2.000€ in case of lost baggage.')
          },
        ]
      },
      {
        section: 'transfer',
        functionality: 'transferEnabled',
        title: gettextCatalog.getString('Airport transfer'),
        paragraph: gettextCatalog.getString('The airport transfer offers you the possibility to get to the airport safely, quickly and on time. We offer transfers by bus or train, depending on your airport. Find the right transfer for you in a short time. After your purchase, your ticket will be available directly in MyCockpit.'),
        subtitles: [
          {
            title: gettextCatalog.getString('Plan your trip to the airport'),
            paragraph: gettextCatalog.getString('The additional service \'Airport Transfer\' offers you the possibility to ensure a transfer to the airport directly in MyCockpit.')
          },
          {
            title: gettextCatalog.getString('Quickly to your destination'),
            paragraph: gettextCatalog.getString('Add a transfer to your trip even faster with the latest user interface overhaul.')
          },
          {
            title: gettextCatalog.getString('Known where'),
            paragraph: gettextCatalog.getString('Check the transfer information window for schedules and locations of your potential transfers.')
          },
        ]
      },
      {
        section: 'baggage-protection',
        functionality: 'insuranceBlueribbonbagsEnabled',
        title: gettextCatalog.getString('Baggage protection'),
        paragraph: gettextCatalog.getString('Communication gaps, storms, aircraft changes - these factors can cause your luggage to get lost inadvertently. In order to protect yourself, you have the possibility to protect your luggage before you start your journey. If the baggage is lost and cannot be found within 96 hours, up to 2,000€ will be paid directly to the protected passenger.'),
        subtitles: [
          {
            title: gettextCatalog.getString('At the last second'),
            paragraph: gettextCatalog.getString('The baggage protection can be booked up to 5 minutes before the first flight and protects your baggage for the entire journey.')
          },
          {
            title: gettextCatalog.getString('96 hours'),
            paragraph: gettextCatalog.getString('Your lost luggage will be searched for 96 hours. If it is not found, you will receive your payment. No questions asked.')
          },
          {
            title: gettextCatalog.getString('High coverage amount'),
            paragraph: gettextCatalog.getString('If it turns out that your baggage cannot be returned, a cover amount of 1,000€ - 2,000€ will be paid out.')
          },
        ]
      },
      {
        section: 'meal',
        functionality: 'mealEnabled',
        title: gettextCatalog.getString('Special meal'),
        paragraph: gettextCatalog.getString('Whether medically required, religiously desired or lifestyle determined, special meals offer you the opportunity to receive the meal of your choice before your flights. The \'Meals\' icon gives you access to the selection of all meals provided by the airline.'),
        subtitles: [
          {
            title: gettextCatalog.getString('Your special meal'),
            paragraph: gettextCatalog.getString('Whether medically required, religiously desired or determined by lifestyle, choose the special meal of your choice.')
          },
          {
            title: gettextCatalog.getString('Safe meal'),
            paragraph: gettextCatalog.getString('The meal of your choice may not be available on the plane. Make sure it doesn\'t stop there.')
          },
          {
            title: gettextCatalog.getString('Fish, pasta or salad'),
            paragraph: gettextCatalog.getString('Depending on what your airline has to offer, you can choose the meal of your choice from a large selection.')
          },
        ]
      },
      {
        section: 'cash-upgrade',
        functionality: 'cashUpgradeEnabled',
        title: gettextCatalog.getString('Cabin Upgrade'),
        paragraph: gettextCatalog.getString('The popular fixed-price upgrades can now also be booked in MyCockpit. Starting 360 days before departure, travelers can conveniently book an upgrade to Premium Eco or Business Class with just a few clicks on their smartphone or laptop.'),
        subtitles: [
          {
            title: gettextCatalog.getString('More space'),
            paragraph: gettextCatalog.getString('With a seat in Premium Eco or Business Class, you\'ll enjoy more legroom for a more relaxed flight.')
          },
          {
            title: gettextCatalog.getString('More comfort'),
            paragraph: gettextCatalog.getString('More comfort and service await you in Premium Economy, but especially in Business Class.')
          },
          {
            title: gettextCatalog.getString('For LH Group flights'),
            paragraph: gettextCatalog.getString('Fixed fare upgrades are available on all Lufthansa (LH), Austrian Airlines (OS) and SWISS Airlines (LX) flights booked as a Smart Offer fare.')
          },
        ]
      }
    ];

    $scope.jumpToSection = function(elementId) {
      var id = $location.hash();
      $location.hash(elementId);
      $anchorScroll();
      $location.hash(id);
    };
  }
]);
