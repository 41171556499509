cockpitApp.controller('travelersController', [
  '$scope', '$rootScope', 'componentHelper', 'profileHelper', 'bookingHelper',
  function ($scope, $rootScope, componentHelper, profileHelper, bookingHelper)
  {
    $scope.isActionDisabled = function() {
      return bookingHelper.isActionDisabled();
    };

    $scope.openFrequentFlyerDialog = function (passenger, changeFrequentFlyer, frequentFlyerNumber, event) {
      var dialogData = {
        'data': {
          passenger : passenger,
          changeFrequentFlyer: changeFrequentFlyer,
          exisitingFFN: frequentFlyerNumber
        },
        'onShowing': 'frequentFlyerDialogOpened'
      };
        if (!profileHelper.isLoggedIn()) {
            dialogData.onShowing = 'loginFromFrequentFlyerNumber';
            componentHelper.openDialog('/element/dialog/profile/main', event, dialogData);
        } else {
            componentHelper.openDialog('/element/dialog/frequent-flyer', event, dialogData);
        }
    };
  
    
    $scope.displayFrequentFlyerSection = function (booking, FFNs) {
      return booking && FFNs && (FFNs.length > 0 || $rootScope.isFunctionalityEnabled('frequentFlyerNumberEnabled'));
    };
  }
]);
