cockpitApp.controller('addBookingPageController', [
  '$rootScope', '$scope', 'gettextCatalog',
  function($rootScope, $scope, gettextCatalog)
  {
    $rootScope.appTitle = $rootScope.appName + ' - ' + gettextCatalog.getString('Add');

    if (!$rootScope.myCockpitIframe) {
      $scope.reCaptchaAddBookingId = 'reCaptchaAddBookingPage';
      $scope.reCaptchaValid = {
        'addBooking': false
      };

      window.reCaptchaAddBookingCallback = function() {
        $scope.reCaptchaValid.addBooking = true;
        $scope.$apply();
      };

      window.reCaptchaAddBookingExpiredCallback = function() {
        $scope.reCaptchaValid.addBooking = false;
        $scope.$apply();
      };
    }
  }
]);
