cockpitApp.controller('pictureActionButtonsDialogController', [
  '$scope', '$rootScope', '$mdDialog', 'profile', 'componentHelper', 'profileHelper', 'gettextCatalog',
  function($scope, $rootScope, $mdDialog, profile, componentHelper, profileHelper, gettextCatalog)
  {
    $scope.cancelProfilePicture = function() {
      $mdDialog.cancel();
    };
  
    $scope.changeProfilePicture = function(action, file, errFiles) {
      $scope.status = action + '-picture';
      
      if (action === 'change' && !file) {
        $scope.showNotificationHandler();
        return;
      }
      
      var formData = new FormData();
      formData.append('profilePicture', file);
      formData.append('action', action);

      profile.changeProfilePicture(formData).then(
        function successCallback (response) {
          if (response.meta && response.meta.success && response.data !== undefined) {
            $rootScope.profile.data.pictureUrl = response.data;
            profileHelper.updateSessionProfile();
            componentHelper.showNotification('success', gettextCatalog.getString('Your profile data was successfully updated.'));
            $mdDialog.cancel();
          } else {
            $scope.showErrorUpdatePictureNotification(action);
          }
        }, function errorCallback () {
          $scope.showErrorUpdatePictureNotification(action);
        }
      );
    };
    
    $scope.showErrorUpdatePictureNotification = function(action) {
      $scope.status = null;
      
      switch (action) {
        case 'change':
          componentHelper.showNotification('error', gettextCatalog.getString('We could not update your profile picture.'));
          break;
        case 'delete':
          componentHelper.showNotification('error', gettextCatalog.getString('We could not delete your profile picture.'));
          break;
      }
    };
    
    $scope.showNotificationHandler = function() {
      if ($scope.profilePictureForm.$error.pattern) {
        componentHelper.showNotification('error', gettextCatalog.getString('The file must be an image (jpg, jpeg and png).'));
      } else if ($scope.profilePictureForm.$error.maxWidth) {
        componentHelper.showNotification('error', gettextCatalog.getString('The image width is too big (max.: 1000px).'));
      } else if ($scope.profilePictureForm.$error.maxHeight) {
        componentHelper.showNotification('error', gettextCatalog.getString('The image height is too big (max.: 1000px).'));
      } else if ($scope.profilePictureForm.$error.maxSize) {
        componentHelper.showNotification('error', gettextCatalog.getString('The image is too big (max.: 2MB).'));
      } else {
        componentHelper.showNotification('error', gettextCatalog.getString('Something went wrong while retrieving your picture.'));
      }
      
      $scope.status = null;
    }
    
  }
]);
