cockpitApp.controller('helpController', [
  '$rootScope', '$scope', '$location', 'gettextCatalog', 'navigationHelper',
  function($rootScope, $scope, $location, gettextCatalog, navigationHelper)
  {
    $rootScope.appTitle = $rootScope.appName + ' - ' + gettextCatalog.getString('Help/FAQ');

    $scope.travelAgency = function() {
      return $rootScope.travelAgency.data;
    };

    $scope.$on('accordion-faq:onReady', function() {
      navigationHelper.preExpandAccordionSection($scope.accordionFAQ, $location.hash());
    });
  }
]);
