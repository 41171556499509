cockpitApp.controller('sidenavExtrasController', [
  '$rootScope', '$scope', '$mdSidenav', '$localStorage', '$filter', 'logsProvider', '$mdMedia', 'markups', 'basketHelper', 'gettextCatalog', 'extras',
  function($rootScope, $scope, $mdSidenav, $localStorage, $filter, logsProvider, $mdMedia, markups, basketHelper, gettextCatalog, extras)
  {
    $scope.basketHelper = basketHelper;
    $scope.keyFeatures = {
      title: gettextCatalog.getString('Do you already know our baggage loss service?'),
      benefits: [
        gettextCatalog.getString('Bookable until immediately before departure'),
        gettextCatalog.getString('Guaranteed payout of min. 1.000€ after 96h'),
        gettextCatalog.getString('Baggage found? You will be notified automatically!')
      ]
    };

    $scope.resetExtrasSidenav = function() {
      $scope.extras = [];
      $scope.passengerList = [];
      $scope.selectedExtras = {};
      $scope.selectedPassenger = null;
    };

    $scope.$watch(function() {
      return $mdSidenav('sidenavExtras').isOpen();
    }, function(newValue) {
      $rootScope.isSideNavOpen = newValue;

      if (!$rootScope.isSideNavOpen) {
        $scope.resetExtrasSidenav();
      }
    });

    $rootScope.$on('sidenavExtras:open', function(event, segment, serviceType) {
      $scope.resetExtrasSidenav();
      $scope.status = 'loading';
      $scope.serviceType = serviceType;
      $scope.serviceTypeLabel = serviceType.replace( /([A-Z])/g, " $1" ).toUpperCase();

      if (segment) {
        $scope.segment = segment;
        $scope.getInitPassenger(segment);

        if ($localStorage.cockpit.baskets[$rootScope.activeFilekey] && $localStorage.cockpit.baskets[$rootScope.activeFilekey][serviceType] && $localStorage.cockpit.baskets[$rootScope.activeFilekey][serviceType][$scope.segment.segmentExtendedNumber]) {
          $scope.selectedExtras = $localStorage.cockpit.baskets[$rootScope.activeFilekey][serviceType][$scope.segment.segmentExtendedNumber].passengers;
        }

        var logRequestParams = {
          interactionName: serviceType + 'Opened',
          filekey: $rootScope.activeFilekey,
          lastname: $rootScope.activeLastname,
          language: $rootScope.activeLocale
        };

        logsProvider.logInteraction(logRequestParams);
        $scope.getExtraServices(segment);
      }
    });

    $scope.getExtraServices = function(segment) {
      var extrasRequestParams = {
        filekey: $scope.activeFilekey,
        lastname: $scope.activeLastname,
        requestedSegments: [segment.segmentExtendedNumber],
        serviceType: $scope.serviceTypeLabel,
        locale: $rootScope.activeLocale
      };

      extras.getExtraServices(extrasRequestParams).then(
        function successCallback(response) {
          if (response.meta && response.meta.success && !$filter('isEmpty')(response.data)) {
            var extrasResponse = new JsonApiDataStore();
            extrasResponse.sync(response);
            $scope.extras = extrasResponse.findAll('extras');
            $scope.status = 'success';

            if ($scope.serviceType === 'cashUpgrade') {
              $scope.selectPassenger($scope.passengerList[Object.keys($scope.passengerList)[0]]);
            }
          } else {
            $scope.status = 'error';
          }
        },
        function errorCallback() {
          $scope.status = 'error';
        }
      );
    };

    $scope.getInitPassenger = function() {
      angular.forEach($scope.segment.passengers, function(passenger) {
        if (passenger.passengerTypeCode !== 'INF') {
          $scope.passengerList.push({
            salutation: $filter('getSalutation')(passenger),
            firstname: passenger.firstname,
            lastname: passenger.lastname,
            pnrPosition: passenger.pnrPosition
          });
        }
      });
    };

    $scope.selectPassenger = function(passenger) {
      if (passenger && $scope.selectedPassenger !== passenger) {
        $scope.selectedPassenger = passenger;
        $scope.openExtrasMobileSidenav();
        $scope.isPassengerExtrasOpen = true;
      }
    };

    $scope.isPassengerExtraSelected = function(extra) {
      return $scope.selectedExtras[$scope.selectedPassenger.pnrPosition] && $scope.selectedExtras[$scope.selectedPassenger.pnrPosition][extra.service.code + '_' + extra.service.subCode]
        && extra.bookingInstructions.newClass === $scope.selectedExtras[$scope.selectedPassenger.pnrPosition][extra.service.code + '_' + extra.service.subCode].bookingInstructions.newClass;
    };

    $scope.toggleExtraSelection = function(extra, event) {
      if ($scope.isPassengerExtraSelected(extra)) {
        var ssrCode = extra.service.code + '_' + extra.service.subCode;

        if ($scope.serviceType === 'cashUpgrade') {
          angular.forEach($scope.passengerList, function (passenger) {
            $scope.deletePassengerSavedExtra(passenger.pnrPosition, ssrCode);
          });
        } else {
          $scope.deletePassengerSavedExtra($scope.selectedPassenger.pnrPosition, ssrCode);
        }

        basketHelper.removeExtraFromBasket($scope.serviceType, $scope.selectedPassenger.pnrPosition, $scope.segment.segmentExtendedNumber, ssrCode, event);
      } else {
        if ($scope.serviceType === 'cashUpgrade') {
          angular.forEach($scope.passengerList, function(passenger) {
            $scope.selectPassenger(passenger);

            angular.forEach($scope.extras, function(extraItem) {
              if (extraItem.travelerIDRef === passenger.pnrPosition && extraItem.description === extra.description) {
                $scope.selectPassengerExtra(extraItem, event);
              }
            });
          });
        } else {
          $scope.selectPassengerExtra(extra, event);
        }
      }
    };

    $scope.selectPassengerExtra = function(extra) {
      $localStorage.cockpit.baskets[$rootScope.activeFilekey] = $localStorage.cockpit.baskets[$rootScope.activeFilekey] || {};
      $scope.basket = $localStorage.cockpit.baskets[$rootScope.activeFilekey];

      var basketExtras = $scope.basket[$scope.serviceType] || {};

      $scope.selectedExtras[$scope.selectedPassenger.pnrPosition] = $scope.selectedExtras[$scope.selectedPassenger.pnrPosition] || {};

      $scope.selectedExtras[$scope.selectedPassenger.pnrPosition][extra.service.code + '_' + extra.service.subCode] = angular.copy(extra);
      basketExtras[$scope.segment.segmentExtendedNumber] = basketExtras[$scope.segment.segmentExtendedNumber] || {};

      basketExtras[$scope.segment.segmentExtendedNumber]['departureAirportCode'] = $scope.segment.departureAirportCode;
      basketExtras[$scope.segment.segmentExtendedNumber]['departureAirportName'] = $scope.segment.departureAirportName;
      basketExtras[$scope.segment.segmentExtendedNumber]['arrivalAirportCode'] = $scope.segment.arrivalAirportCode;
      basketExtras[$scope.segment.segmentExtendedNumber]['arrivalAirportName'] = $scope.segment.arrivalAirportName;
      basketExtras[$scope.segment.segmentExtendedNumber]['flown'] = $scope.segment.flown;
      basketExtras[$scope.segment.segmentExtendedNumber]['departureDateTime'] = $scope.segment.departureDateTime;
      basketExtras[$scope.segment.segmentExtendedNumber]['marketingAirlineCode'] = $scope.segment.marketingAirlineCode;
      basketExtras[$scope.segment.segmentExtendedNumber]['marketingAirlineName'] = $scope.segment.marketingAirlineName;
      basketExtras[$scope.segment.segmentExtendedNumber]['marketingAirlineIcao'] = $scope.segment.marketingAirlineIcao;
      basketExtras[$scope.segment.segmentExtendedNumber]['crypticSegmentValue'] = $scope.segment.crypticSegmentValue;
      basketExtras[$scope.segment.segmentExtendedNumber]['passengers'] = basketExtras[$scope.segment.segmentExtendedNumber]['passengers'] || {};
      basketExtras[$scope.segment.segmentExtendedNumber]['passengers'] = angular.copy($scope.selectedExtras);

      $localStorage.cockpit.baskets[$rootScope.activeFilekey][$scope.serviceType] = $localStorage.cockpit.baskets[$rootScope.activeFilekey][$scope.serviceType] || {};
      $localStorage.cockpit.baskets[$rootScope.activeFilekey][$scope.serviceType] = basketExtras;
      $rootScope.$emit('refreshBasket');
    };

    $scope.deletePassengerSavedExtra = function(pnrPosition, ssrCode) {
      delete $scope.selectedExtras[pnrPosition][ssrCode];

      if ($filter('isEmpty')($scope.selectedExtras[pnrPosition])) {
        delete $scope.selectedExtras[pnrPosition];
      }
    };

    $scope.passengerHasExtra = function(code, subCode) {
      if ($scope.segment && $scope.selectedPassenger) {
        var passenger = $filter('filter')($filter('toArray')($scope.segment.passengers), {pnrPosition: $scope.selectedPassenger.pnrPosition})[0];

        return !$filter('isEmpty')($filter('filter')($filter('toArray')(passenger.pnrServices), {serviceCode: code, subCode: subCode}));
      }
    };

    $scope.openExtrasMobileSidenav = function() {
      if (!$scope.isLargeScreen) {
        if ($mdSidenav('sidenavExtrasMobile').isOpen()) {
          angular.element('#sidenav-extras-mobile').animate({
            'marginLeft': '0'
          }, 500);
        } else {
          $scope.openPassengerDetails();
        }
      } else {
        if (!$scope.isPassengerExtrasOpen) {
          $scope.openPassengerDetails();
        }
      }
    };

    $scope.openPassengerDetails = function() {
      if ($mdMedia('max-width: 960px')) {
        $mdSidenav('sidenavExtrasMobile').open();
      } else {
        angular.element('#extras').animate({
          'width': 'auto',
          'max-width': '880px'
        }, 500);

        $mdSidenav('sidenavExtras').open();
      }
    };

    $scope.close = function(sideNav) {
      if (sideNav === 'mobile') {
        $mdSidenav('sidenavExtrasMobile').close();

        if ($scope.isPassengerExtrasOpen) {
          $scope.selectedPassenger = null;
          $scope.isPassengerExtrasOpen = false;
        }
      } else if (sideNav === 'desktop') {
        $mdSidenav('sidenavExtras').close();
      }
    };

    $scope.openBasket = function() {
      $scope.close('mobile');
      $scope.close('desktop');
      basketHelper.toggleBasket(true);
    };
  }
]);
