cockpitApp.controller('footerController', [
  '$scope',
  function ($scope)
  {
    var startYear = '2016';
    var currentYear = moment().format('YYYY');

    $scope.appYear = startYear === currentYear ? currentYear : startYear + '-' + currentYear;
  }
]);
