cockpitApp.factory('segmentHelper', [
  '$q', '$rootScope', '$filter', '$mdSidenav', 'componentHelper', 'bookingHelper',
  function($q, $rootScope, $filter, $mdSidenav, componentHelper, bookingHelper) {
    var factory = {};

    factory.isAircraft = function(equipmentType) {
      return equipmentType === 'aircraft' || !equipmentType;
    };

    factory.getSegmentLogo = function(segments) {
      var segmentLogos = [];

      angular.forEach(segments, function(segment) {
        var logoExist = segmentLogos.find(function(segmentLogo) {
          return segmentLogo.operatingAirlineCode === segment.operatingAirlineCode && segmentLogo.equipmentType === segment.equipmentType;
        });

        if (!logoExist) {
          segmentLogos.push({
            'operatingAirlineCode': segment.operatingAirlineCode,
            'equipmentType': segment.equipmentType
          });
        }
      });

      return segmentLogos;
    };

    factory.getAirlineTail = function(airlineCode) {
      return cockpitCdnUrl + '/airlines/tails/' + $filter('lowercase')(airlineCode) + '.png';
    };

    factory.getSegmentTypes = function(segments) {
      var segmentTypes = [];

      angular.forEach(segments, function(segment) {
        if (segmentTypes.indexOf(segment.equipmentType) === -1) {
          segmentTypes.push(segment.equipmentType);
        }
      });

      return segmentTypes;
    };

    factory.normalizeMultiplePieceBaggageAllowance = function(baggageAllowance) {
      if (baggageAllowance) {
        var multiplePieceBaggageAllowancePattern = new RegExp(/^(?:[2-9]|\d\d\d*)PC$/);
        var matches;

        if ((matches = multiplePieceBaggageAllowancePattern.exec(baggageAllowance)) !== null) {
          if (matches.index === multiplePieceBaggageAllowancePattern.lastIndex) {
            multiplePieceBaggageAllowancePattern.lastIndex++;
          }

          return 'MultiplePieces';
        }

        return baggageAllowance;
      }
    };

    factory.isAirlineCheckinOpen = function(segment) {
      if (segment) {
        // Within the airline checkin time opening (24h)
        var checkin = moment() > moment(segment.departureDateTime).subtract(segment.checkinHours, 'hours') && moment() < moment(segment.departureDateTime);

        return segment.status !== 'cancelled' && segment.checkinLink !== null && checkin;
      }
    };

    factory.openSelectMealDialog = function(data, event) {
      var dialogData = {
        'data': data,
        'onShowing': 'selectMealDialogOpening'
      };

      componentHelper.openDialog('/element/dialog/select-meal', event, dialogData);
    };

    factory.validAirlineCode = function(segment) {
      if (segment && segment.marketingAirlineCode) {
        var nonAirlineCodeList = [
          '9B',
          '2A'
        ];

        return nonAirlineCodeList.indexOf(segment.marketingAirlineCode.toUpperCase()) === -1;
      }
    };

    factory.isStatusBadgeUpsellValid = function(ancillaryBadgeType) {
      var preference = $rootScope.brandPreference(ancillaryBadgeType);

      return preference && preference.value ? $filter('isTravelAgencyPreferenceValid')(preference) : false;
    };

    factory.getNumberOfSeatsPerSegment = function(segment) {
      var numberOfSeats = 0;

      angular.forEach(segment.passengers, function(passenger) {
        if (passenger.seatRequest) {
          numberOfSeats++;
        }
      });

      return numberOfSeats;
    };

    factory.getBadgeStatus = function(segment, ancillaryType) {
      var status = "";
      var statusList = [];

      switch(ancillaryType) {
        case 'seat':
          angular.forEach(segment.passengers, function(passenger) {
            if (passenger.seatRequest && passenger.seatRequest.status) {
              status = passenger.seatRequest.status;

              if (statusList.indexOf(passenger.seatRequest.status) == -1) {
                statusList.push(passenger.seatRequest.status);
              }
            }
          });
          break;
        case 'baggage':
          angular.forEach(segment.passengers, function(passenger) {
            if (passenger.specialServiceRequests) {
              angular.forEach(passenger.specialServiceRequests, function(specialServiceRequest) {

                if (specialServiceRequest.group === 'baggage-bg') {
                  status = specialServiceRequest.status;

                  if (statusList.indexOf(specialServiceRequest.status) == -1) {
                    statusList.push(specialServiceRequest.status);
                  }
                }
              });
            }
          });
          break;
        case 'meal':
          angular.forEach(segment.passengers, function(passenger) {

            if (passenger.specialServiceRequests) {
              angular.forEach(passenger.specialServiceRequests, function(specialServiceRequest) {
                if (specialServiceRequest.group === 'special-meal-sm') {
                  status = specialServiceRequest.status;

                  if (statusList.indexOf(specialServiceRequest.status) == -1) {
                    statusList.push(specialServiceRequest.status);
                  }
                }
              });
            }
          });
          break;
        case 'automaticCheckin':
          angular.forEach(segment.passengers, function(passenger) {
            if (passenger.automaticCheckin) {
              status = passenger.automaticCheckin.status;

              if (statusList.indexOf(passenger.automaticCheckin.status) == -1) {
                statusList.push(passenger.automaticCheckin.status);
              }
            }
          });
          break;
        default:
          status = "";
      }

      return statusList.length > 1 ? getPartialStatus(statusList) : status;
    };

    var getPartialStatus = function(statusList) {
      var status = "";

      if (statusList.indexOf('confirmed') !== -1 || statusList.indexOf('success') !== -1) {
        status = 'partiallyConfirmed';
      } else {
        status = 'partiallyNotConfirmed';
      }

      return status;
    }

    var extrasGroup = {
      'upgrades-up': 'cashUpgrade',
      'baggage-bg': 'baggage'
    };

    factory.getNumberOfExtrasPerSegment = function(segment, serviceType) {
      var numberOfExtras = 0;

      angular.forEach(segment.passengers, function(passenger) {
        if (passenger.pnrServices) {
          angular.forEach(passenger.pnrServices, function(pnrService) {
            var pnrServiceGroupMatch = extrasGroup[pnrService.group];
            // Get the pnr services being under the fallback extras sidenav
            var isExtra = serviceType === 'extras' && !['cashUpgrade', 'baggage'].includes(pnrServiceGroupMatch);

            if (isExtra || (pnrServiceGroupMatch === serviceType && pnrService.status === 'confirmed')) {
              numberOfExtras++;
            }
          });
        }
      });

      return numberOfExtras;
    };

    factory.getNumberOfBaggagePerSegment = function(segment) {
      var numberOfBaggage = 0;

      angular.forEach(segment.passengers, function(passenger) {
        if (passenger.specialServiceRequests) {
          angular.forEach(passenger.specialServiceRequests, function(specialServiceRequest) {
            if (specialServiceRequest.group === 'baggage-bg') {
              numberOfBaggage++;
            }
          });
        }
      });

      return numberOfBaggage;
    };

    factory.getNumberOfAutomaticCheckinsPerSegment = function(segment) {
      var numberOfAutomaticCheckins = 0;

      angular.forEach(segment.passengers, function(passenger) {
        if (passenger.automaticCheckin) {
          numberOfAutomaticCheckins++;
        }
      });

      return numberOfAutomaticCheckins;
    };

    factory.getNumberOfMealsPerSegment = function(segment) {
      var numberOfMeals = 0;

      angular.forEach(segment.passengers, function(passenger) {
        if (passenger.specialServiceRequests) {
          angular.forEach(passenger.specialServiceRequests, function(specialServiceRequest) {
            if (specialServiceRequest.group === 'special-meal-sm') {
              numberOfMeals++;
            }
          });
        }
      });

      return numberOfMeals;
    };

    factory.getNumberOfInsuranceBaggagePerSegment = function(segment) {
      var numberOfInsuranceBaggage = 0;

      angular.forEach(segment.passengers, function(passenger) {
        if (passenger.insuranceBaggage) {
          numberOfInsuranceBaggage++;
        }
      });

      return numberOfInsuranceBaggage;
    };

    factory.getNumberOfTransfersPerSegment = function(segment, transferPosition) {
      var numberOfTransfers = 0;

      angular.forEach(segment.passengers, function(segmentPassenger) {
        var segmentPassengerTransfers = $filter('filter')(segmentPassenger.transfers, {'transferPosition': transferPosition});

        if (!$filter('isEmpty')(segmentPassengerTransfers)) {
          numberOfTransfers++;
        }
      });

      return numberOfTransfers;
    };

    factory.hasSegmentUnconfirmedAncillary = function(segment, ancillaryType) {
      if (segment && ancillaryType) {
        return segment.passengers.some(function(passenger) {
          return passenger[ancillaryType] && passenger[ancillaryType]['status'] !== 'confirmed';
        });
      }
    };

    factory.hasSegmentUnconfirmedSpecialServiceRequest = function(segment, ancillaryType) {
      if (segment && ancillaryType) {
        return segment.passengers.some(function(passenger) {
          return passenger.specialServiceRequests.some(function(specialServiceRequest) {
            return specialServiceRequest.group && specialServiceRequest.group === ancillaryType && specialServiceRequest.status !== 'confirmed';
          });
        });
      }
    };

    factory.getNumberOfAncillaryPerSegment = function(segment, ancillaryType, transferPosition) {
      if (segment && ancillaryType) {
        switch (ancillaryType) {
          case 'seat':
            return this.getNumberOfSeatsPerSegment(segment);
          case 'extras':
          case 'cashUpgrade':
            return this.getNumberOfExtrasPerSegment(segment, ancillaryType);
          case 'baggage':
            return this.getNumberOfBaggagePerSegment(segment);
          case 'meal':
            return this.getNumberOfMealsPerSegment(segment);
          case 'automaticCheckin':
            return this.getNumberOfAutomaticCheckinsPerSegment(segment);
          case 'insuranceBaggage':
            return this.getNumberOfInsuranceBaggagePerSegment(segment);
          case 'transfer':
            return this.getNumberOfTransfersPerSegment(segment, transferPosition);
        }
      }
    };

    factory.allPassengersHaveAncillaryInSegment = function(segment, ancillaryType, transferPosition) {
      return this.getNumberOfAncillaryPerSegment(segment, ancillaryType, transferPosition) === $filter('count')(segment.passengers)
    };

    factory.isActionDisabled = function(functionality, ancillaryType, segment, transferPosition) {
      if (segment && functionality && ancillaryType) {
        var ancillarieTypesToExclude = ['seat', 'baggage', 'extras'];
        var isActionDisabled = bookingHelper.isActionDisabled() || (segment.status === 'pending' && segment.statusCode !== 'TK');
        isActionDisabled = ancillarieTypesToExclude.includes(ancillaryType) ? isActionDisabled : isActionDisabled || !$rootScope.isFunctionalityEnabled(functionality) || (ancillaryType !== 'baggage' && this.allPassengersHaveAncillaryInSegment(segment, ancillaryType, transferPosition));

        return isActionDisabled;
      }
    };

    factory.getSegmentPassengersTransfer = function(segmentPassengers, transferPosition) {
      var segmentPassengersTransfers = {};

      angular.forEach(segmentPassengers, function(segmentPassenger) {
        var segmentPassengerTransfers = $filter('filter')(segmentPassenger.transfers, {'transferPosition': transferPosition});

        if (!$filter('isEmpty')(segmentPassengerTransfers)) {
          segmentPassengersTransfers[segmentPassenger.pnrPosition] = segmentPassengerTransfers[0];
        }
      });

      return segmentPassengersTransfers;
    };

    factory.openExtrasContainer = function (segment, serviceType) {
      $mdSidenav('sidenavExtras').open();
      $rootScope.$emit('sidenavExtras:open', segment, serviceType);
    };

    factory.getSegmentByExtenderNumber = function(segmentExtendedNumber) {
      if ($rootScope.booking && $rootScope.booking.segments.length > 0) {
        return $filter('filter')($rootScope.booking.segments, {segmentExtendedNumber:segmentExtendedNumber})[0];
      }
    };

    factory.getFutureFlightSegments = function() {
      var self = this;

      if ($rootScope.booking) {
        return $rootScope.booking.segments.filter(function(segment) {
          return segment.status !== 'cancelled' && !$filter('isPast')(segment.departureDateTime, segment.departureTimezone) && self.isAircraft(segment.equipmentType);
        });
      }
    };

    factory.isTrainVoucherSegment = function(segment) {
      if (segment) {
        return [segment.arrivalAirportCode, segment.departureAirportCode].includes('QYG');
      }
    };

    return factory;
  }
]);
