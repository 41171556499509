cockpitApp.filter('translateStatusBadges', ['gettextCatalog', function(gettextCatalog) {
  return function(value) {
    if (value) {
      switch (value) {
        case 'RENEWED':
          return gettextCatalog.getString('RENEWED', null, 'Upsell Status badge');
        case 'NEW':
          return gettextCatalog.getString('NEW', null, 'Upsell Status badge');
        case 'BETA':
          return gettextCatalog.getString('BETA', null, 'Upsell Status badge');
      }
    }
  }
}]);
