cockpitApp.filter('translateData', [
  '$rootScope', 'gettextCatalog', '$mdMedia',
  function($rootScope, gettextCatalog, $mdMedia)
  {
    return function (code, type, nonResponsive) {
      if (code && type && $rootScope.translations) {
        if ($rootScope.translations[type] && $rootScope.translations[type][code] && (nonResponsive || $mdMedia('gt-sm'))) {
          var currentLang = gettextCatalog.getCurrentLanguage();
        
          return $rootScope.translations[type][code][currentLang] || $rootScope.translations[type][code]['en_GB'];
        }
      
        return code;
      }
    }
  }
]);
