cockpitApp.filter('translateSalutation', [
  'gettextCatalog',
  function(gettextCatalog) {
    return function(value, context) {
      if (value) {
        value = value.toUpperCase();
        
        switch (value) {
          case 'BARON':
            return gettextCatalog.getString('Baron');
          case 'FREIHERR':
            return gettextCatalog.getString('Freiherr');
          case 'BARONESS':
            return gettextCatalog.getString('Baroness');
          case 'FREIFRAU':
            return gettextCatalog.getString('Freifrau');
          case 'CHD':
            return gettextCatalog.getString('Child');
          case 'DR':
            return gettextCatalog.getString('Dr.');
          case 'DR_MR':
            return gettextCatalog.getString('Mr Dr.');
          case 'DR_MRS':
            return gettextCatalog.getString('Mrs Dr.');
          case 'EARL':
            return gettextCatalog.getString('Earl');
          case 'REICHSGRAF':
            return gettextCatalog.getString('Reichsgraf');
          case 'REICHSGRAEFIN':
            return gettextCatalog.getString('Reichsgraefin');
          case 'COUNTESS':
            return gettextCatalog.getString('Countess');
          case 'HRH':
            return gettextCatalog.getString('His/Her Royal Highness');
          case 'HSH':
            return gettextCatalog.getString('His/Her Serene Highness');
          case 'INF':
          case 'INS':
            return gettextCatalog.getString('Infant');
          case 'MAG':
            return gettextCatalog.getString('Magister');
          case 'MD':
            return gettextCatalog.getString('MD');
          case 'MISS':
            return gettextCatalog.getString('Miss');
          case 'MR':
            return context ? gettextCatalog.getString('Mr', null, 'accusative') : gettextCatalog.getString('Mr');
          case 'MRS':
            return gettextCatalog.getString('Mrs');
          case 'MS':
            return gettextCatalog.getString('Ms');
          case 'PHD':
            return gettextCatalog.getString('PhD');
          case 'PRIEST':
            return gettextCatalog.getString('Priest');
          case 'PRINCE':
            return gettextCatalog.getString('Prince');
          case 'PRINCESS':
            return gettextCatalog.getString('Princess');
          case 'PRINCE_HRH':
            return gettextCatalog.getString('His Royal Highness Prince');
          case 'PRINCE_HSH':
            return gettextCatalog.getString('His Serene Highness Prince');
          case 'PRINCESS_HRH':
            return gettextCatalog.getString('Her Royal Highness Princess');
          case 'PRINCESS_HSH':
            return gettextCatalog.getString('Her Serene Highness Princess');
          case 'PROF':
            return gettextCatalog.getString('Prof.');
          case 'PROF_DR_MR':
            return gettextCatalog.getString('Prof. Dr. Mr');
          case 'PROF_DR_MRS':
            return gettextCatalog.getString('Prof. Dr. Mrs');
          case 'PROF_MR':
            return gettextCatalog.getString('Prof. Mr');
          case 'PROF_MRS':
            return gettextCatalog.getString('Prof. Mrs');
          case 'FATHER':
            return gettextCatalog.getString('Father');
          default:
            return value;
        }
      }
    }
  }
]);
