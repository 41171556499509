cockpitApp.controller('menuTopController', [
  '$rootScope', '$scope', '$localStorage', '$location', '$filter', '$mdComponentRegistry', 'gettextCatalog', 'basketHelper', 'bookingHelper', 'componentHelper', 'profileHelper', 'navigationHelper',
  function ($rootScope, $scope, $localStorage, $location, $filter, $mdComponentRegistry, gettextCatalog, basketHelper, bookingHelper, componentHelper, profileHelper, navigationHelper)
  {
    $scope.toggleMenuMain = function() {
      $mdComponentRegistry.when('menuMainSidenav').then(function(menuMainSidenav) {
        menuMainSidenav.toggle();
      });
    };

    $scope.topNavBarProfileAction = function(event) {
      if ($rootScope.isAuthenticated) {
        navigationHelper.redirectWithLocale('/profile');
      } else {
        componentHelper.openDialog('/element/dialog/profile/main', event);
      }
    };

    $scope.toggleMenuRight = function(event) {
      var dialogData = {};

      if (!profileHelper.isLoggedIn()) {
        dialogData.onShowing = 'loginFromBasket';
        componentHelper.openDialog('/element/dialog/profile/main', event, dialogData);
      } else {
        if ($location.path().indexOf('/details') === -1) {
          var bookingsWithBasket = $scope.getBookingsWithBasket();
          var numberOfBookingsWithBasket = $filter('count')(bookingsWithBasket);

          dialogData = {
            data: {
              type: 'basketDialog',
              title: gettextCatalog.getString('Select a shopping cart'),
              bookingsWithBasket: bookingsWithBasket
            },
            onShowing: 'displayNotificationDialog'
          };

          if (numberOfBookingsWithBasket === 0) {
            dialogData.data.title = gettextCatalog.getString('Select a booking');
            dialogData.data.text = gettextCatalog.getString('Select a booking first to use the shopping cart.');
          }

          componentHelper.openDialog('/element/dialog/notification', event, dialogData);
        } else {
          basketHelper.toggleBasket();
        }
      }
    };

    $scope.getBookingsWithBasket = function() {
      var bookingsWithBasket = {};
      var bookings = bookingHelper.getBookings();

      angular.forEach($localStorage.cockpit.baskets, function (basket, filekey) {

        if (!bookings[filekey] || basketHelper.isBasketObsolete(bookings[filekey])) {
          delete $localStorage.cockpit.baskets[filekey];
        } else if (!$filter('isEmpty')(basket) && $rootScope.profile.profileBookings[filekey]) {
          bookingsWithBasket[filekey] = bookings[filekey];
        }
      });

      return bookingsWithBasket;
    };

    $scope.getNumberOfElementsInBasket = function() {
      return basketHelper.getNumberOfElementsInBasket();
    };

    $scope.hideBasketPopover = function() {
      $localStorage.cockpit.hideBasketPopover[$scope.activeFilekey] = true;
    };
  }
]);
