cockpitApp.controller('locationOptionsController', [
  '$rootScope', '$scope', 'bookingHelper', 'locationHelper', 'segmentHelper',
  function ($rootScope, $scope, bookingHelper, locationHelper, segmentHelper)
  {
    $scope.submitted = false;
  
    $scope.isActionDisabled = function(functionality, ancillaryType, transferPosition) {
      return segmentHelper.isActionDisabled(functionality, ancillaryType, $scope.segment, transferPosition);
    };
  
    $scope.displayUpSell = function(functionality, ancillaryType) {
      return !bookingHelper.isBookingCancelled() && !(!$rootScope.isFunctionalityEnabled(functionality) && (segmentHelper.getNumberOfAncillaryPerSegment($scope.segment, ancillaryType) === 0));
    };
    
    $scope.openTransfersSidenav = function(segment, transferPosition) {
      locationHelper.openTransfersSidenav(segment, transferPosition);
    };
  
    $scope.getNumberOfTransfersPerSegment = function(segment, transferPosition) {
      return segmentHelper.getNumberOfTransfersPerSegment(segment, transferPosition);
    };

    $scope.isStatusBadgeUpsellValid = function(badgeType) {
      return segmentHelper.isStatusBadgeUpsellValid(badgeType);
    }
  }
]);
