cockpitApp.directive('location', function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      'isLastSegment': '=',
      'index': '=',
      'segment': '=',
      'transferPosition': '='
    },
    templateUrl: '/element/location/main',
    controllerAs: 'detailLocationCtrl',
    controller: 'detailLocationController'
  };
});
