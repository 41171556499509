cockpitApp.controller('addBookingIframeController', [
  '$rootScope', '$scope', '$location', '$filter', '$routeParams', 'bookingProvider',
  function($rootScope, $scope, $location, $filter, $routeParams, bookingProvider)
  {
    $scope.addBooking = function(origin) {
      if ($scope.add.$valid) {
        var postAction = 'redirect';

        if ($rootScope.supportedFeatures.includes($routeParams.ancillary)) {
          postAction += $filter('capitalize')($routeParams.ancillary)
        }

        $scope.status = 'loading';

        bookingProvider.updateBooking($scope.filekey, $scope.lastname, true, postAction, origin);
      }
    }
  }
]);
