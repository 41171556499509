cockpitApp.filter('dateTimeFormatter', [
  function() {
    return function(dateTime, format) {
      if (!moment.isMoment(dateTime)) {
        dateTime = moment(dateTime);
      }

      return format ? dateTime.format(format) : dateTime.fromNow();
    }
  }
]);
