cockpitApp.filter('excludeSeatCharacteristicsList', function() {
  return function (seatCharacteristics) {
    var seatCharacteristicsExcludeList = [
      'CHARGEABLE_SEAT',
      'LEFT_SIDE_OF_AIRCRAFT',
      'EXIT_ROW_SEAT',
      'RESTRICTED_SEAT',
      'RIGHT_SIDE_OF_AIRCRAFT',
      'OVERWING_SEAT',
      'UPPER_DECK',
      'DEPORTEE',
      'WINDOW_AND_AISLE_SEAT',
      'REAR_FACING_SEAT',
      'MEDICALLY_OK_TO_TRAVEL',
      'PROTECTED_SEAT',
      'FRONT_OF_TOILET_SEAT',
      'CENTER_SEAT',
      'CENTER_SECTION_SEAT'
    ];
    
    var filteredSeatCharacteritics = [];
    
    angular.forEach(seatCharacteristics, function (seatCharacteristic) {
      if (seatCharacteristicsExcludeList.indexOf(seatCharacteristic) === -1) {
        filteredSeatCharacteritics.push(seatCharacteristic);
      }
    });

    return filteredSeatCharacteritics;
  }
});
