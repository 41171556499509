cockpitApp.controller('addBookingController', [
  '$rootScope', '$scope', '$filter', '$location', '$routeParams', 'bookingProvider', '$localStorage', '$mdDialog', '$timeout', 'reCaptcha', 'navigationHelper', 'bookingHelper',
  function($rootScope, $scope, $filter, $location, $routeParams, bookingProvider, $localStorage, $mdDialog, $timeout, reCaptcha, navigationHelper, bookingHelper)
  {
    $scope.captchaIdAddBooking = null;
    var reCaptchaLoadedAddBookingListener = null;

    $scope.displayReCaptchaAddBooking = function(origin) {
      $timeout(function() {
        if ($localStorage.cockpit.error.captcha.displayAddCaptcha) {
          reCaptcha.resetCaptcha($scope.captchaIdAddBooking);

          if ($rootScope.reCaptchaLoaded) {
            $scope.captchaIdAddBooking = reCaptcha.displayCaptcha(origin);
          } else {
            reCaptchaLoadedAddBookingListener = $rootScope.$on('reCaptchaLoaded', function() {
              $scope.captchaIdAddBooking = reCaptcha.displayCaptcha(origin);
            });
          }
        }
      }, 400);
    };

    $scope.$on('$destroy', reCaptchaLoadedAddBookingListener);

    $scope.termsAccepted = function() {
      return $scope.terms || $localStorage.cockpit.terms.accepted;
    };

    $scope.displayTermsAndConditions = function() {
      return !$localStorage.cockpit.terms.accepted;
    };

    $scope.addBooking = function(origin) {
      $scope.status = 'loading';

      if ($localStorage.cockpit.error.captcha && $localStorage.cockpit.error.captcha.displayAddCaptcha) {
        var requestParams = {
          captchaResponse: grecaptcha.getResponse($scope.captchaIdAddBooking),
          filekey: $scope.filekey,
          lastname: $scope.lastname
        };

        reCaptcha.verify(requestParams).then(
          function successCallback(response) {
            if (response && response.meta) {
              $scope.getBooking(origin);
            }
          }
        );
      } else {
        $scope.getBooking(origin);
      }
    };

    $scope.getBooking = function(origin) {
      if ($scope.add.$valid) {
        var bookings = bookingHelper.getBookings();
        var postAction = 'redirect';
        var redirectRoute = '';

        if ($rootScope.supportedFeatures.includes($routeParams.ancillary)) {
          postAction += $filter('capitalize')($routeParams.ancillary);
          redirectRoute = '/' + $routeParams.ancillary;
        }

        if (bookings[$scope.filekey] && !bookings[$scope.filekey].profileBooking) {
          navigationHelper.redirectWithLocale('/details/:'+ $scope.filekey + '/:' + $scope.lastname + redirectRoute);
        }

        bookingProvider.updateBooking($scope.filekey, $scope.lastname, true, postAction, origin).then(
          function successCallback(responseMeta) {
            $scope.status = 'none';

            if (origin === 'addBookingDialog') {
              $scope.close();
            }

            if (responseMeta && responseMeta.success) {
              $localStorage.cockpit.terms.accepted = true;
              $localStorage.cockpit.error.captcha.displayAddCaptcha = false;
              reCaptcha.resetCaptcha($scope.captchaIdAddBooking);
              $scope.reCaptchaValid.addBooking = false;
            }
          }
        )
      } else {
        $localStorage.cockpit.terms.accepted = false;
      }
    };
  }
]);
