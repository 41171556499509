cockpitApp.provider('iframe', [
  function() {
    this.apiIframeLoginEndpoint = null;
    
    this.setApiIframeLoginEndpoint = function(newApiIframeLoginEndpoint) {
      this.apiIframeLoginEndpoint = newApiIframeLoginEndpoint;
    };
    
    this.$get = ['cockpitClient', '$http', '$q',
      function(cockpitClient, $http, $q) {
        var self = this;

        return {
          login: login
        };
        
        function login(jwtPayload) {
          var requestPayload = {
            email: jwtPayload.profileEmail,
            profileIdentifier: jwtPayload.profileIdentifier,
            source: jwtPayload.source
          };

          var request = {
            method: 'POST',
            url: cockpitApiUrl + self.apiIframeLoginEndpoint,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            data: requestPayload
          };

          var deferred = $q.defer();

          $http(request).then(
            function successCallback(response) {
              deferred.resolve(response.data);
            },
            function errorCallback(response) {
              deferred.reject(response);
            }
          );

          return cockpitClient.resolveRequest(deferred.promise);
        }
      }
    ];
  }
]);
