cockpitApp.provider('ancillary', [
  function () {
    this.$get = ['cockpitClient',
      function (cockpitClient) {
        return {
          getBaggageList: getBaggageList
        };

        function getBaggageList(requestParameters) {
          return cockpitClient.getBaggageList(requestParameters);
        }
      }
    ];
  }
]);
