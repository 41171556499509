cockpitApp.factory('baggageHelper', [
  '$q', '$rootScope', '$mdSidenav',
  function($q, $rootScope, $mdSidenav)
  {
    var factory = {};

    factory.openBaggageContainer = function() {
      $mdSidenav('sidenavBaggage').open();
      $rootScope.$emit('sidenavBaggage:open', $rootScope.booking);
    };

    factory.getBaggageCodeLabel = function(baggages) {
      var baggageCarouselLabels = {};

      angular.forEach(baggages, function(baggage, baggageLabel) {
        var baggageSsrCode = baggageLabel.split(':')[0];

        switch (true) {
          case ['ABAG', 'BBAG', 'CBAG', 'FBAG', 'SBAG'].includes(baggageSsrCode):
            baggageCarouselLabels['additionalBaggage'] = baggageCarouselLabels['additionalBaggage'] || {};
            baggageCarouselLabels['additionalBaggage'][baggageLabel] = Object.assign({}, baggage);
            break;
          case ['PDBG', 'MBAG', 'XBAG'].includes(baggageSsrCode):
            baggageCarouselLabels['prepaidBaggage'] = baggageCarouselLabels['prepaidBaggage'] || {};
            baggageCarouselLabels['prepaidBaggage'][baggageLabel] = Object.assign({}, baggage);
        }
      });

      return baggageCarouselLabels;
    };

    factory.showDeleteBaggageIcon = function(pnrBaggages, segmentPriceListCode) {
      var baggageLabels = this.getBaggageCodeLabel(pnrBaggages);
      var currentSegmentIndex = segmentPriceListCode.slice(-1);
      var segmentCodeLabels = [];
      var showIcon = false;

      angular.forEach(baggageLabels, function(baggages) {
        angular.forEach(baggages, function(baggage, baggageCodes) {
          angular.forEach(baggage.segmentPriceList, function(priceList, baggageCodeIndex) {
            if (baggageCodeIndex.slice(-1) === currentSegmentIndex) {
              segmentCodeLabels.push(baggageCodeIndex);

              return segmentCodeLabels;
            }
          })
        });

        if (segmentCodeLabels[segmentCodeLabels.length - 1] === segmentPriceListCode) {
          showIcon = true;
        }
      });

      return showIcon;
    };

    return factory;
  }
]);
