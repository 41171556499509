cockpitApp.run([
  '$rootScope', '$localStorage', 'crumble', 'gettextCatalog', '$location', '$filter', '$mdMedia', '$anchorScroll', 'travelAgency', 'profileHelper', 'componentHelper', 'bookingHelper', 'cockpitClient', 'profile', 'localeHelper', 'navigationHelper', 'jwtHelper', 'functionalities', 'countries',
  function($rootScope, $localStorage, crumble, gettextCatalog, $location, $filter, $mdMedia, $anchorScroll, travelAgency, profileHelper, componentHelper, bookingHelper, cockpitClient, profile, localeHelper, navigationHelper, jwtHelper, functionalities, countries)
  {
    // Linkout logic should be done before anything else
    if ($location.search().referer) {
      navigationHelper.linkout($location.search().referer);
    }

    $rootScope.isBrowserUnsupported = navigationHelper.isBrowserUnsupported();
    $rootScope.myCockpitIframe = $location.url().indexOf('embedded') !== -1;
    $rootScope.bookingsUpdating = {};
    $rootScope.pingBookingsRefreshDateTimeUtc = {};
    $rootScope.iframePayload = {};
    $rootScope.utmSource = $location.search().utm_source;

    if ($rootScope.myCockpitIframe) {
      $rootScope.iframePayload = JSON.parse(jwtPayload.replace(/&quot;/g,'"')).data;

      document.oncontextmenu = function() {
        return false;
      }
    }

    $localStorage.cockpit = $localStorage.cockpit || {};
    $rootScope.activeLocale = localeHelper.getLanguage();
    localeHelper.setLocaleDependencies();
    $anchorScroll.yOffset = 50;

    try {
      $rootScope.isWebApp = $location.search().webAppToken ? jwtHelper.decodeToken($location.search().webAppToken).isWebApp : false;
    } catch (e) {
      $rootScope.isWebApp = false;
    }

    if (!$rootScope.myCockpitIframe && $location.path().split('/')[1].length !== 2) {
      navigationHelper.redirectWithLocale();
    }

    if ($rootScope.isWebApp) {
      $rootScope.source = 'web-app';
    } else if ($rootScope.myCockpitIframe) {
      $rootScope.source = 'embedded-invia-app';
    } else {
      $rootScope.source = 'web-client';
    }

    $rootScope.travelAgency = {
      data: null
    };

    travelAgency.getTravelAgency();
    profile.socialAuthCallback();

    var cockpit = $localStorage.cockpit;
    cockpit.bookings = cockpit.bookings || {};
    cockpit.baskets = cockpit.baskets || {};
    cockpit.translations = cockpit.translations || {};
    cockpit.weblinks = cockpit.weblinks || {};
    cockpit.countries = cockpit.countries || {};
    cockpit.hideBasketPopover = cockpit.hideBasketPopover || {};

    countries.getCountries();

    gettextCatalog.debug = cockpitTranslationDebug === 'true';

    if (!cockpit.error) {
      cockpit.error = {
        message: "",
        code: null,
        status_code: null,
        meta: null
      };
    }

    if (!cockpit.error.captcha) {
      cockpit.error.captcha = {
        displayAddCaptcha: false,
        displayLoginCaptcha: false
      };
    }

    if (!cockpit.cookies) {
      cockpit.cookies = {
        read: false
      };
    }

    if (!cockpit.terms) {
      cockpit.terms = {
        accepted: false
      };
    }

    $rootScope.$watch('appTitle', function() {
      $rootScope.title = $rootScope.appTitle;
    });

    $rootScope.segment = {
      data: null
    };

    window.onloadCallback = function() {
      $rootScope.reCaptchaLoaded = true;
      $rootScope.$emit('reCaptchaLoaded');
    };

    $rootScope.subdomain = subdomain;
    $rootScope.environment = environment;
    $rootScope.error = $localStorage.cockpit.error;
    $rootScope.appName = 'My Cockpit';
    $rootScope.crumble = crumble;
    // TODO: COPIT-3502 why does it have to be empty when $rootScope.myCockpitIframe
    $rootScope.profile = $rootScope.myCockpitIframe ? {} : JSON.parse(sessionStorage.getItem('session_profile'));
    $rootScope.isOffline = false;
    $rootScope.countries = $filter('sortCountries')();
    $rootScope.supportedFeatures = ['seatmap', 'baggage'];
    $rootScope.translations = $localStorage.cockpit.translations;
    $rootScope.functionalities = [];
    $rootScope.markups = [];
    $rootScope.preferences = [];
    $rootScope.hideBasketPopover = $localStorage.cockpit.hideBasketPopover;
    $rootScope.setoo = [];
    $rootScope.textField = /^([a-zA-ZÀ-ÖØ-öø-ÿ ]*)$/;
    $rootScope.numberField = /^([0-9 ]+)$/;
    $rootScope.patternAlphaNumeric = /^([a-zA-ZÀ-ÖØ-öø-ÿ0-9 ]*)$/;
    $rootScope.patternAlphaNumericWithExtra = /^([a-zA-ZÀ-ÖØ-öø-ÿ0-9-_. ]*)$/;
    $rootScope.emailValidationPattern = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    $rootScope.passwordValidationPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*\(\)\+,-\.\/:;<=>\[\]\^\{\}\|]).{3,}$/;

    $rootScope.$watch('isOffline', function(newValue, oldValue) {
      if (!newValue && oldValue) {
        cockpitClient.getSession();
        travelAgency.getTravelAgency();
        functionalities.getFunctionalities();
      }
    });

    $rootScope.$watch(function() { return $mdMedia('(min-width: 940px)'); }, function(isLargeScreen) {
      $rootScope.isLargeScreen = isLargeScreen;
    });

    $rootScope.$watch(function() { return $mdMedia('(max-width: 575px)'); }, function(isExtraSmallScreen) {
      $rootScope.isExtraSmallScreen = isExtraSmallScreen;
    });

    $rootScope.$on('travelAgencyFetched', function() {
      $rootScope.isAuthenticated = profileHelper.isLoggedIn();
    });

    $rootScope.$watch(function() {
      return $rootScope.isLargeScreen && $rootScope.booking && $rootScope.booking.branding == null;
    }, function(isSeatWidgetEnabled) {
      $rootScope.isSeatWidgetEnabled = isSeatWidgetEnabled;

      if ($rootScope.isSeatWidgetEnabled) {
        const script = document.createElement('script');
        script.src = '/js/seatmaps.js';
        script.type = 'module';
        script.crossOrigin = 'anonymous';
        document.head.appendChild(script);

        const link = document.createElement('link');
        link.href = '/css/seatmaps.css';
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.crossOrigin = 'anonymous';
        document.head.appendChild(link);
      }
    });

    $rootScope.$on('$routeChangeSuccess', function(event, current, previous) {
      $rootScope.activeFilekey = current.params.filekey ? navigationHelper.normalizeFilekey(current.params.filekey) : null;
      $rootScope.activeLastname = current.params.lastname ? current.params.lastname.replace(':', '') : null;

      if ($rootScope.activeLastname && $rootScope.activeFilekey) {
        $rootScope.booking = bookingHelper.getBookings()[$rootScope.activeFilekey];
        $localStorage.cockpit.baskets[$rootScope.activeFilekey] = $localStorage.cockpit.baskets[$rootScope.activeFilekey] || {};
        $rootScope.basket = $localStorage.cockpit.baskets[$rootScope.activeFilekey];
        $rootScope.activePnrIdentifier = $rootScope.booking && $rootScope.booking.pnrIdentifier;
      } else {
        $rootScope.booking = undefined;
        $rootScope.basket = undefined;
        $rootScope.activePnrIdentifier = null;
        componentHelper.hideBasketPopoverTimeout(null);
      }

      if ($location.path().indexOf('details') === -1) {
        componentHelper.closeSidenavs();
      }

      if ($rootScope.travelAgency && $rootScope.travelAgency.data && !profileHelper.isLoggedIn() && $rootScope.profile) {
        profile.logout();
      }
    });

    $rootScope.$on('unauthenticated', function() {
      if (!$rootScope.myCockpitIframe) {
        profile.logout();
        navigationHelper.profileLoggedOutPostAction(true);
      }
    });

    if ($location.path().indexOf("/iframe") === -1 && $location.path().indexOf("/details") === -1) {
      componentHelper.slideUpSplashScreen(2000);
    }
  }
]);
