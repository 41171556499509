cockpitApp.controller('profileEditController', [
  '$timeout', '$rootScope', '$scope', '$location', '$routeParams', '$mdDialog', '$mdMedia', '$filter', 'profile', 'profileHelper', 'navigationHelper', 'gettextCatalog', 'componentHelper',
  function($timeout, $rootScope, $scope, $location, $routeParams, $mdDialog, $mdMedia, $filter, profile, profileHelper, navigationHelper, gettextCatalog, componentHelper)
  {
    $rootScope.appTitle = $rootScope.appName + ' - ' + gettextCatalog.getString('My profile');
    $scope.language = gettextCatalog.getCurrentLanguage();
    $scope.contentLoaded = false;
    $scope.countriesScope = {};
    var profileData = $scope.profile && $scope.profile.data;
    var profileDataMobileCountryCode = profileData &&  profileData.mobileCountryCode;
    var profileDataCountryCode = profileData && profileData.countryCode;

    $scope.profileCountryCode = $filter('filter')($rootScope.countries, {'code': profileDataCountryCode}, true)[0];
    $scope.profileMobileCountryCode = $filter('filter')($rootScope.countries, {'callingCode': profileDataMobileCountryCode}, true)[0];

    $scope.setCountries = function($type) {
      return $timeout(function() {
        $scope.countriesScope = $rootScope.countries;

        if ($type === 'phoneCountryCodeChanged') {
          $scope.phoneCountryCodeChanged = true;
        } else {
          $scope.countryCodeChanged = true
        }
      }, 400);
    };

    $scope.setProfileValues = function() {
      var profileData = $scope.profile && $scope.profile.data;

      if (profileData) {
        $scope.firstName = profileData.firstName;
        $scope.lastName = profileData.lastName;
        $scope.email = profileData.email;
        $scope.mobileNumber = profileData.mobileNumber;
        $scope.street = profileData.street;
        $scope.streetNumber = profileData.streetNumber;
        $scope.postcode = profileData.postcode;
        $scope.city = profileData.city;
        $scope.countryCode = profileData.countryCode;
        $scope.pictureUrl = profileData.pictureUrl;
        $scope.company = profileData.company;
        $scope.vatNumber = profileData.vatNumber;
        $scope.addressAdditional = profileData.addressAdditional;
      }
    };

    $scope.editProfile = function() {
      $scope.edit.$setSubmitted();

       if ($scope.edit.$valid) {
        $scope.status = 'loading';
        var mobileCountryCode = $scope.edit.mobileCountryCode.$viewValue;
        var requestParams = {
          firstName: $scope.edit.firstName.$viewValue,
          lastName: $scope.edit.lastName.$viewValue,
          birthday: $scope.formatBirthday(),
          street: $scope.edit.street.$viewValue,
          streetNumber: $scope.edit.streetNumber.$viewValue,
          addressAdditional: $scope.edit.addressAdditional.$viewValue,
          postcode: $scope.edit.postcode.$viewValue,
          city: $scope.edit.city.$viewValue,
          countryCode: $scope.edit.countryCode.$viewValue,
          company: $scope.edit.company.$viewValue,
          vatNumber: $scope.edit.vatNumber.$viewValue,
          mobileCountryCode: mobileCountryCode ? mobileCountryCode.split('_')[1] : null,
          mobileNumber: $scope.edit.mobileNumber.$viewValue
        };

        profile.edit(requestParams).then(
          function successCallback (response) {
            if (response.meta && response.meta.success && response.data) {
              $scope.status = 'editSuccess';
              $rootScope.profile.data = response.data;
              profileHelper.updateSessionProfile();
              $scope.setProfileValues();
            } else {
              $scope.status = 'editFailed';
            }
          }, function errorCallback () {
            $scope.status = 'editFailed';
          }
        );
      }
    };

    $scope.openProfilePictureDialog = function(event) {
      componentHelper.openDialog('/element/dialog/profile/picture-mobile-actions', event);
    };

    $scope.formatBirthday = function() {
      var isBirthdayValid = $scope.edit.birthdayYear.$viewValue !== undefined && $scope.edit.birthdayMonth.$viewValue !== undefined && $scope.edit.birthdayDay.$viewValue !== undefined;

      if (isBirthdayValid) {
        return $scope.edit.birthdayYear.$viewValue + '-' + $scope.edit.birthdayMonth.$viewValue + '-' + $scope.edit.birthdayDay.$viewValue;
      }

      return null;
    };

    $scope.prepareYearsDropdown = function() {
      var start = 1900;
      var currentYear = moment().year();

      return $scope.getRange(start, currentYear);
    };

    $scope.getRange = function(start, end) {
      var range = [];
      for (var i = start; i <= end; i++) {
        var nb = i < 10 ? "0" + i : i;
        range.push(nb);
      }

      return range;
    };

    $scope.daysInMonth = function(year, month) {
      return year === moment.utc().format('YYYY') && month === moment.utc().format('MM') ? moment.utc().format('D') : moment(year + '-' + month, 'YYYY-MM').daysInMonth();
    };

    $scope.monthInYear = function(year) {
      if (year) {
        return year === moment.utc().format('YYYY') ? moment.utc().format('M') : 12;
      }
    };

    $scope.getBirthdayValue = function(target) {
      if ($scope.profile && $scope.profile.data && $scope.profile.data.birthday) {
        return moment.utc($scope.profile.data.birthday, 'YYYY-MM-DD').format(target);
      }
    };

    $scope.openConfirmationDialog = function(event) {
      componentHelper.openDialog('/element/dialog/delete-profile', event);
    };

    $scope.setProfileValues();
  }
]);
