cockpitApp.filter('translateStatus', ['gettextCatalog', function(gettextCatalog) {
  return function(value) {
    if (value) {
      switch (value) {
        case 'processing':
          return gettextCatalog.getString('Processing');
        case 'pending':
          return gettextCatalog.getString('Pending');
        case 'confirmed':
          return gettextCatalog.getString('Confirmed');
        case 'cancelled':
          return gettextCatalog.getString('Cancelled');
        case 'success':
          return gettextCatalog.getString('Success');
        case 'error':
          return gettextCatalog.getString('Error');
        case 'complete':
          return gettextCatalog.getString('Complete');
        default:
          return value;
      }
    }
  }
}]);
