/**
 * Created by bteshome on 13/12/16.
 */
cockpitApp.directive('invoiceRequest', function () {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: '/element/dialog/invoice-request',
        controller: 'invoiceRequestDialogController'
    };
});
