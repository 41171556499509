cockpitApp.controller('ordersController', [
  '$rootScope', '$scope', 'gettextCatalog', 'profileHelper', 'profile', '$routeParams', 'navigationHelper',
  function($rootScope, $scope, gettextCatalog, profileHelper, profile, $routeParams, navigationHelper)
  {
    $rootScope.appTitle = $rootScope.appName + ' - ' + gettextCatalog.getString('My orders');
    $scope.paymentIdentifier = null;

    if ($routeParams && $routeParams.paymentIdentifier) {
      $scope.paymentIdentifier = $routeParams.paymentIdentifier.substring(1);
    }

    $scope.getProfileOrders = function() {
      var profileData = profileHelper.getProfile() || $rootScope.profile.data;
      if (profileData !== null) {
        var requestParams = {
          'email' : profileData.email
        };

        profile.getProfileOrders(requestParams).then(
          function successCallback(response) {
            if (response && response.data && response.meta && response.meta.success && response.data.length) {
              $scope.orders = response.data;
              $scope.status = 'success';
              $scope.$emit('ordersReady');
            } else {
              $scope.status = 'success';
            }
          },
          function errorCallback(response) {
            $scope.status = 'error';
          }
        )
      } else {
        $scope.getProfileOrders();
      }
    };

    $scope.$on('accordion-orders:onReady', function() {
      $scope.$on('ordersReady', function() {
        navigationHelper.preExpandAccordionSection($scope.accordionOrders, $scope.paymentIdentifier);
      });
    });

    $scope.getCountry = function(countryCode) {
      var country = countryCode;
      $rootScope.countries.some(function(countryData) {
        countryData.code === countryCode ? country = countryData.name : null;
      });
      return country
    }
  }
]);
