cockpitApp.filter('sortCountries', [
  '$rootScope', '$localStorage',
  function($rootScope, $localStorage) {
    return function() {
      if ($rootScope.activeLocale && $localStorage.cockpit.translations && $localStorage.cockpit.countries) {
        var relatedCountries = [];
        var sortedCountries = [];
        var translations = $localStorage.cockpit.translations.countries;
        var countries = $localStorage.cockpit.countries.data;
        var activeRelatedCountries = $rootScope.relatedCountries[$rootScope.activeLocale];

        if (translations && countries) {
          angular.forEach(countries, function(country) {
            if (country && country.callingCode && translations[country.code]) {
              if (activeRelatedCountries.indexOf(country.code) > -1) {
                relatedCountries = prepareCountries(relatedCountries, translations, country);
              } else {
                sortedCountries = prepareCountries(sortedCountries, translations, country);
              }
            }
          });
        }

        return relatedCountries.concat(sortedCountries);
      }
    };

    function sortCountries(sortedCountries) {
      return sortedCountries.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
    }

    function prepareCountries(countries, translations, country) {
      countries.unshift({
        'flagUrl': country.flagUrl,
        'code': country.code,
        'name': translations[country.code][$rootScope.activeLocale] || translations[country.code]['en_GB'],
        'callingCode': country.callingCode
      });

      return sortCountries(countries);
    }
  }
]);
