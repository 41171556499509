cockpitApp.controller('contactController', [
  '$scope', '$rootScope',
  function($scope, $rootScope)
  {
    $scope.getTravelAgencyData = function() {
      if ($rootScope.travelAgency && $rootScope.travelAgency.data) {
        $scope.setTravelAgencyData();
      } else {
        $scope.$on('travelAgencyFetched', function() {
          $scope.setTravelAgencyData();
        });
      }
    };

    $scope.setTravelAgencyData = function() {
      if ($scope.booking && ((subdomain === 'mycockpit' && !$scope.booking.isLowCost) || ($rootScope.travelAgency.data.functionalities && $rootScope.travelAgency.data.functionalities.useDataFromKim))) {
          $scope.travelAgency = $scope.booking.travelAgency;
      } else {
          $scope.travelAgency = $rootScope.travelAgency.data;
      }
    };

    $scope.$on('bookingFetchingComplete', function() {
      $scope.getTravelAgencyData();
    });

    $scope.isTravelAgencyEmpty = function() {
      if (subdomain === 'mycockpit' && $scope.booking && !$scope.booking.travelAgencyNumber) {
        return true;
      } else {
        var travelAgencyArr = [];

        angular.forEach($scope.travelAgency, function(value) {
          travelAgencyArr.push(value)
        });

        return travelAgencyArr.every(function(data) {
          return data;
        });
      }
    };

    $scope.toll = function(number) {
      number = number || '';
      var pre = number.substring(0, 8);
      var matches = pre.match(/1805/);
      return matches != null;
    };

    $scope.getTravelAgencyData();
  }
]);
