cockpitApp.filter('translateSSRLabel', [
  'gettextCatalog',
  function (gettextCatalog) {
    return function (value) {
      switch (value) {
        case 'baggage-bg':
          return gettextCatalog.getString('Baggage', null, 'SSR group label detail additional');
        case 'disability-related-dr':
          return gettextCatalog.getString('Disability related', null, 'SSR group label detail additional');
        case 'special-meal-sm':
          return gettextCatalog.getString('Special meal', null, 'SSR group label detail additional');
        case 'special-seating-requirements-sr':
          return gettextCatalog.getString('Special seating requirements', null, 'SSR group label detail additional');
        case 'seat-characteristics-sc':
          return gettextCatalog.getString('Seat characteristics', null, 'SSR group label detail additional');
        case 'extra-baggage':
          return gettextCatalog.getString('Extra baggage', null, 'SSR group label detail additional');
        default:
          return gettextCatalog.getString('Information', null, 'SSR group label detail additional');
      }
    }
  }
]);
