cockpitApp.provider('seatMap', [
  function() {
    this.apiSeatMapEndpoint = null;
  
    this.setApiSeatMapEndpoint = function(newApiSeatMapEndpoint) {
      this.apiSeatMapEndpoint = newApiSeatMapEndpoint;
    };
    
    this.$get = ['cockpitClient',
      function(cockpitClient) {
        var self = this;
        
        return {
          requestSeatMap: requestSeatMap
        };
        
        function requestSeatMap(requestParams) {
          var response = cockpitClient.remoteCall(self.apiSeatMapEndpoint, requestParams, 'POST', null, null);
  
          return cockpitClient.resolveRequest(response);
        }
      }
    ];
  }
]);
