cockpitApp.factory('locationHelper', [
  '$q', '$rootScope', '$mdSidenav', '$filter', 'functionalities', 'bookingHelper', 'segmentHelper',
  function($q, $rootScope, $mdSidenav, $filter, functionalities, bookingHelper, segmentHelper)
  {
    var location = {};

    location.openTransfersSidenav = function(segment, transferPosition, transfer) {
      $mdSidenav('sidenavTransfers').open();
      $rootScope.$emit('sidenavTransfers:open', segment, transferPosition, transfer);
    };
  
    location.isAncillaryOffered = function(ancillary, segment, transferPosition) {
      return segmentHelper.validAirlineCode(segment) && !bookingHelper.isBookingCancelled() && this.isSegmentLocationAvailableForTransfer(segment, transferPosition);
    };

    location.isSegmentLocationAvailableForTransfer = function(segment, transferPosition) {
      if ($rootScope.functionalities && $rootScope.activeFilekey && $rootScope.functionalities[$rootScope.activeFilekey] &&
          $rootScope.functionalities[$rootScope.activeFilekey]['transferEnabled'] &&
          $rootScope.functionalities[$rootScope.activeFilekey]['transferEnabled'][segment.segmentExtendedNumber]) {
          return (transferPosition === 'first' && $rootScope.functionalities[$rootScope.activeFilekey]['transferEnabled'][segment.segmentExtendedNumber]['departure']) ||
            (transferPosition === 'last' && $rootScope.functionalities[$rootScope.activeFilekey]['transferEnabled'][segment.segmentExtendedNumber]['arrival']);
      }
    };
  
    location.displayLocationSection = function(segment, transferPosition) {
      if ($rootScope.booking && $rootScope.booking.segments) {
        var bookingFirstSegment = $rootScope.booking.segments[0];
        var bookingLastSegment = $rootScope.booking.segments[$rootScope.booking.segments.length - 1];
        var locationSegment = bookingFirstSegment.id === segment.id ? bookingFirstSegment : bookingLastSegment.id === segment.id ? bookingLastSegment : null;
        var passengersHaveTransfers = false;

        if (locationSegment) {
          // some passengers do not have transfers in that segment
          passengersHaveTransfers = locationSegment.passengers.some(function(passenger) {
            return !$filter('isEmpty')(passenger.transfer);
          });
        }

        return $filter('dateTimeDiff')(segment.departureDateTime, null, 'months') < 6
          && locationSegment && (this.isAncillaryOffered('transferEnabled', segment, transferPosition) || passengersHaveTransfers);
      }
    };

    return location;
  }
]);
