cockpitApp.controller('addBookingDialogController', [
  '$scope', '$mdDialog',
  function ($scope, $mdDialog)
  {
    $scope.reCaptchaAddBookingId = 'reCaptchaAddBookingDialog';
    $scope.reCaptchaValid = {
      'addBooking': false
    };
  
    window.reCaptchaAddBookingCallback = function() {
      $scope.reCaptchaValid.addBooking = true;
      $scope.$apply();
    };

    window.reCaptchaAddBookingExpiredCallback = function() {
        $scope.reCaptchaValid.addBooking = false;
        $scope.$apply();
    };

    $scope.close = function() {
      $mdDialog.cancel();
    };
  }
]);
