cockpitApp.controller('specialHintBarController', [
  '$scope', '$filter', 'sessionHelper', 'componentHelper',
  function ($scope, $filter, sessionHelper, componentHelper)
  {
    $scope.isBookingOutdated = function (booking) {
      if (booking) {
        var updateDateTimeUtc = moment.utc(booking.updateDateTime);
        var halfAnHourAgo = moment().utc().subtract(30, 'minutes');
        var browserSessionStartedDateTimeUtc = moment.utc(sessionHelper.getBrowserSessionStartedDateTime());
      
        return browserSessionStartedDateTimeUtc.isAfter(updateDateTimeUtc) || updateDateTimeUtc.isBefore(halfAnHourAgo);
      }
    };
    
    $scope.openCancelBookingConfirmationDialog = function (event) {
      componentHelper.openDialog('/element/dialog/cancel-booking', event);
    };
  }
]);
