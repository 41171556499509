cockpitApp.controller('journeyController', [
  '$rootScope', '$scope', '$filter', 'segmentHelper', 'bookingHelper',
  function ($rootScope, $scope, $filter, segmentHelper, bookingHelper)
  {
    $scope.segmentHelper = segmentHelper;
    $scope.bookingHelper = bookingHelper;
  
    $scope.$on('accordion-journey:onReady', function() {
      if ($rootScope.booking && $filter('isPast')($rootScope.booking.arrivalDateTime, $rootScope.booking.arrivalTimezone)) {
        $scope.accordionJourney.toggle('pane-journey');
      }
    })
  }
]);
