cockpitApp.controller('detailAutomaticCheckinController', [
  '$rootScope', '$scope', '$filter', 'checkinHelper',
  function($rootScope, $scope, $filter, checkinHelper)
  {
    $scope.openAutomaticCheckinContainer = function() {
      checkinHelper.openACIContainer();
    };

    $scope.displayAutomaticCheckinSection = function() {
      return $rootScope.isFunctionalityEnabled('automaticCheckinEnabled') && $rootScope.booking && !$rootScope.booking.cancelled && !$filter('isEmpty')($rootScope.booking.segments);
    };
  }
]);
