/**
 * Created by bteshome on 16/11/16.
 */
cockpitApp.directive('contactPage', function () {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: '/view/contact',
        controllerAs: 'contactPageCtrl',
        controller: 'contactPageController'
    };
});
