cockpitApp.filter('count', function () {
  return function (arr) {
    
    if (typeof arr === 'object') {
      return Object.keys(arr).length;
    }
    
    return arr && arr.length;
  }
});
