cockpitApp.provider('transfers', [
  function() {
    this.apiTransferStationsEndpoint = null;
    this.apiTransferConnectionsEndpoint = null;
    
    this.setApiTransferStations = function(newapiTransferStationsEndpoint) {
      this.apiTransferStationsEndpoint = newapiTransferStationsEndpoint;
    };
    
    this.setApiTransferConnections = function(newapiTransferConnectionsEndpoint) {
      this.apiTransferConnectionsEndpoint = newapiTransferConnectionsEndpoint;
    };
    
    this.$get = ['cockpitClient',
      function(cockpitClient) {
        var self = this;
        
        return {
          getStations: getStations,
          getConnections: getConnections
        };
        
        function getStations(content) {
          var response = cockpitClient.remoteCall(self.apiTransferStationsEndpoint, content, 'POST', null, null);
          
          return cockpitClient.resolveRequest(response);
        }
        
        function getConnections(content) {
          var response = cockpitClient.remoteCall(self.apiTransferConnectionsEndpoint, content, 'POST', null, null);
          
          return cockpitClient.resolveRequest(response);
        }
      }
    ];
  }
]);
