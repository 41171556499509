cockpitApp.controller('errorController', [
  '$rootScope', '$scope', '$localStorage', 'gettextCatalog',
  function ($rootScope, $scope, $localStorage, gettextCatalog)
  {
    $rootScope.appTitle = $rootScope.appName + ' - ' + gettextCatalog.getString('Error');

    $rootScope.$watch('error', function () {
      $scope.error = $rootScope.error;
      angular.extend($localStorage.cockpit.error, $rootScope.error);
      }
    );
  }
]);
