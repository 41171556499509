cockpitApp.controller('iframeBreadcrumbsController', [
  '$rootScope', '$scope', 'gettextCatalog', '$mdMedia', 'basketHelper',
  function ($rootScope, $scope, gettextCatalog, $mdMedia, basketHelper)
  {
    $scope.goTo = function (link) {
      if (link) {
        $scope.redirectWithLocale(link);
      }
    };
    
    $scope.$watch(function() {return $mdMedia('xs')}, function(isSmallScreen) {
      $scope.isSmallScreen = isSmallScreen;
    });
    
    $rootScope.$watch('booking', function () {
      $scope.crumbleUpdate();
    });
    
    $rootScope.$on('$routeChangeSuccess', function() {
      $scope.crumbleUpdate();
    });
  
    $scope.toggleMenuRight = function() {
      basketHelper.toggleBasket();
    };
    
    $scope.crumbleUpdate = function() {
      if ($scope.isPage('overview')) {
        $scope.routes = [{
          link: null,
          name: gettextCatalog.getString('Bookings')
        }];
      } else if ($scope.isPage('details')) {
        $scope.routes = [
          {
            link: '/overview/active',
            name: gettextCatalog.getString('Bookings')
          }, {
            link: null,
            name: $scope.booking.departureAirportCity && $scope.booking.arrivalAirportCity ? $scope.booking.departureAirportCity + ' - ' + $scope.booking.arrivalAirportCity : $rootScope.activeFilekey
          }
        ];
      } else if ($scope.isPage('add')) {
        $scope.routes = [
          {
            link: '/overview/active',
            name: gettextCatalog.getString('Bookings')
          }, {
            link: null,
            name: gettextCatalog.getString('Add new booking')
          }
        ];
      } else if ($scope.isPage('error')) {
        $scope.routes = [
          {
            link: '/overview/active',
            name: gettextCatalog.getString('Bookings')
          }, {
            link: null,
            name: gettextCatalog.getString('Error')
          }
        ];
      }
    };
  }
]);
