cockpitApp.provider('checkout', [
  function () {
    this.apiCheckoutOrderEndpoint = null;
    
    this.setApiCheckoutPciTokenEndpoint = function (newApiCheckoutPciTokenEndpoint) {
      this.apiCheckoutPciTokenEndpoint = newApiCheckoutPciTokenEndpoint;
    };
    
    this.setApiCheckoutOrderEndpoint = function (newApiCheckoutOrderEndpoint) {
      this.apiCheckoutOrderEndpoint = newApiCheckoutOrderEndpoint;
    };

    this.setApiCheckoutAdyenSessionEndpoint = function (endpoint) {
      this.apiCheckoutAdyenSessionEndpoint = endpoint;
    };

    this.setApiCheckoutAdyenOrderEndpoint = function (endpoint) {
      this.apiCheckoutAdyenOrderEndpoint = endpoint;
    };
    
    this.$get = ['cockpitClient',
      function (cockpitClient) {
        var self = this;

        return {
          getPciToken: getPciToken,
          order: order,
          getSession: getAdyenSession,
          adyenOrder: adyenOrder
        };
  
        function getPciToken(requestParams) {
          var response = cockpitClient.remoteCall(self.apiCheckoutPciTokenEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }

        function order(requestParams) {
          var response = cockpitClient.remoteCall(self.apiCheckoutOrderEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }

        function getAdyenSession(requestParams) {
          var response = cockpitClient.remoteCall(self.apiCheckoutAdyenSessionEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }

        function adyenOrder(requestParams) {
          var response = cockpitClient.remoteCall(self.apiCheckoutAdyenOrderEndpoint, requestParams, 'POST', null, null);

          return cockpitClient.resolveRequest(response);
        }
      }
    ];
  }
]);
