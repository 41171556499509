cockpitApp.controller('settingsController', [
  '$rootScope', '$scope', '$filter', 'gettextCatalog', 'componentHelper', '$timeout', 'localeHelper', 'navigationHelper',
  function($rootScope, $scope, $filter, gettextCatalog, componentHelper, $timeout, localeHelper, navigationHelper)
  {
    $rootScope.appTitle = $rootScope.appName + ' - ' + gettextCatalog.getString('Settings');

    var cacheClearedListener = $rootScope.$on('cacheCleared', function(event, value) {
      $scope.cacheCleared = value;

      $timeout(function() {
        $scope.cacheCleared = 'none';
      }, 5000);
    });

    $scope.$on('$destroy', cacheClearedListener);

    $scope.getCountryFlag = function(countryCode) {
      return localeHelper.getCountryFlag(countryCode);
    };

    $scope.getLanguageName = function(locale) {
      return localeHelper.getLanguageName(locale);
    };

    $scope.updateSettings = function() {
      localeHelper.changeLocale($scope.language, 'settings');
      navigationHelper.redirectWithLocale();
    };

    $scope.openClearCacheDialog = function(event) {
      componentHelper.openDialog('/element/dialog/clear-cache', event);
    };
  }
]);
