cockpitApp.controller('locationDetailsAdditionalController', [
  '$rootScope', '$scope', '$filter', 'segmentHelper', 'navigationHelper',
  function($rootScope, $scope, $filter, segmentHelper, navigationHelper)
  {
    $rootScope.$on('bookingFetchingComplete', function() {
      $scope.getSegmentPassengersTransfer();
    });
  
    $scope.$on('accordion-location-details-additional:onReady', function(index) {
      $scope.getSegmentPassengersTransfer();
  
      if ($rootScope.booking.segments) {
        var upcomingSegments = $rootScope.booking.segments.filter(function(segment) {
          return moment.utc(segment.departureDateTime).isAfter(moment());
        });
    
        if (upcomingSegments.length > 0 && !$filter('isEmpty')($scope.segmentPassengersTransfers)) {
          $scope.accordionLocationDetailsAdditional.toggle('location-' + $scope.transferPosition + '-' + upcomingSegments[0].id);
        }
      }
    });
    
    $scope.getSegmentPassengersTransfer = function() {
      $scope.segmentPassengersTransfers = segmentHelper.getSegmentPassengersTransfer($scope.segment.passengers, $scope.transferPosition);
    };
    
    $scope.openTransferBoardingPass = function(segmentPassenger) {
      var segmentPassengerTransfer = $scope.segmentPassengersTransfers[segmentPassenger.pnrPosition];
      var link = cockpitApiUrl + cockpitApiTransferBoardingPassEndpoint + '/' + segmentPassengerTransfer.hash;
      navigationHelper.openBoardingPass(link, 'documents-transfer-voucher');
    };
  
    $scope.getDurationDisplay = function(pnrPosition) {
      return $filter('durationDisplay')($scope.segmentPassengersTransfers[pnrPosition].ticketsValidityInHours * 60);
    };
  }
]);
