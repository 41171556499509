cockpitApp.filter('travelAgencyFunctionalitiesEnabled', [
  '$rootScope',
  function($rootScope) {
    return function(travelAgencyFunctionalities) {
      return travelAgencyFunctionalities && travelAgencyFunctionalities.filter(function(travelAgencyFunctionality) {
        return $rootScope.isTravelAgencyFunctionalityEnabled(travelAgencyFunctionality.functionality);
      });
    }
  }
]);
