cockpitApp.provider('preferences', [
  function() {
    this.apiPreferencesEndpoint = null;

    this.setApiPreferencesEndpoint = function(newApiPreferencesEndpoint) {
      this.apiPreferencesEndpoint = newApiPreferencesEndpoint;
    };

    this.$get = ['$rootScope', 'cockpitClient',
      function($rootScope, cockpitClient) {
        var self = this;

        return {
          getPreferences: getPreferences
        };

        function getPreferences() {
          var requestParams = {
            filekey: $rootScope.activeFilekey,
            lastname: $rootScope.activeLastname,
            locale: $rootScope.activeLocale,
          };

          var response = cockpitClient.remoteCall(self.apiPreferencesEndpoint, requestParams, 'POST');
          var resolvedResponse = cockpitClient.resolveRequest(response);

          resolvedResponse.then(
            function successCallback(response) {
              if (response.meta && response.meta.success && response.data) {
                $rootScope.preferences[$rootScope.activeFilekey] = response.data;
                $rootScope.$broadcast('preferencesFetched');
              }
            }
          );

          return resolvedResponse;
        }
      }
    ];
  }
]);
